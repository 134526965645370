import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-tipo-cuerpo',
  templateUrl: './tipo-cuerpo.component.html',
  styleUrls: ['./tipo-cuerpo.component.scss']
})
export class TipoCuerpoComponent implements OnInit {
  //Constantes globales
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  tipoCuerpo: IAvatar[] = GlobalConstants.TIPO_CUERPO;

  //Variables de apoyo
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';

  personaje: string; //Personaje elegido
  cinturon: string; //cinturon del personaje basado en el poder
  tipoCuerpoElegido: IAvatar;//Tipo de cuerpo o gema

  //variables para el store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  //temps
  refTemp: any = [];

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(this.router.url);
  }

  ngOnInit(): void {
    this.store.select("user").pipe(take(3)).subscribe(res => {
      this.userInfo = res.info;
      this.heroeInfo = res.heroe;

      if (this.heroeInfo) {
        const genero = this.userInfo.genero.toLocaleLowerCase();
        this.personaje = this.etapaService.obtenerPersonaje('poderes', this.heroeInfo.poder[0][genero]);
        this.cinturon = this.heroeInfo?.poder[0].auxFoto;
        this.tipoCuerpoElegido = res.heroe?.tipoCuerpo[0];
      }
    });
    this.store.select('ui').pipe(take(3)).subscribe(res => {
      this.isLoading = res.isLoading;
    });


    //la primera vez el modal aparecerá de manera directa
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  iconClick(poder: HTMLElement, pos: string, tipoCuerpo: IAvatar) {
    this.tipoCuerpoElegido = tipoCuerpo;
    this.effectConDom(poder, pos);
    //agregamos como referencia el que se hizo click
    this.refTemp.push(poder);
  }


  async siguiente() {
    if (!this.tipoCuerpoElegido) { //Si no es null
      this.alerts.modalInfo(null, 'Debe elegir un tipo para continuar');
    } else {
      this.store.dispatch(isLoading());

      //actualizamos el poder elegido
      const dataHeroe = {
        tipoCuerpo: [this.tipoCuerpoElegido]
      };
      await this.etapaService.actualizarTest(this.userInfo.uuid, this.userInfo.flagTrackingTest.uuidTest, dataHeroe);

      //actualizamos el último path visitado
      const dataUser: IUser = {
        flagTrackingTest: {
          ...this.userInfo.flagTrackingTest,
          lastPath: this.router.url
        },
        fechFinTest: '',
        misCarreras: [],
        miSuperHeroe: {
          CDO: '',
          calzado: '',
          casco: '',
          don: '',
          habilidades: '',
          herramientas: '',
          nick: '',
          poder: this.heroeInfo?.poder[0].titulo + ' - ' + this.heroeInfo?.poder[0].descripcion,
          proposito: '',
          tipoCuerpo: this.tipoCuerpoElegido.titulo + ' - ' + this.tipoCuerpoElegido.descripcion,
          vestimenta: '',
        }
      };


      this.usuario.updUsuarioFireStore(this.userInfo.uuid, dataUser).then(res => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(setUser({ info: { ...this.userInfo, ...dataUser } }));
        this.store.dispatch(setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } }));
        this.router.navigate(['/alumno/vistete']);
      }).catch(err => {
        this.store.dispatch(stopLoading());
        this.alerts.modalError(null, 'Sucedio un error:' + err);
      });
    }
  }

  atras() {
    const etapaInfo = this.etapaService.obtenerCodigoEtapa(this.router.url);
    this.router.navigate(['/alumno/etapa', etapaInfo.codigo]);
  }

  //Modal
  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }

  //Efecto dom
  effectConDom(poder: HTMLElement, pos: string) {
    const itemDetalle = poder.querySelector('.casco-item');
    const contenido = poder.querySelector('.item-content-detail');
    contenido.classList.remove('d-none');
    itemDetalle.classList.remove('hide-effect');
    contenido.classList.add('animate__fadeIn', 'animate__slower');
    if (pos === 'IZQ') {
      this.validarCierreCondicionado(poder);
      if (itemDetalle.className.indexOf('show-left') > -1) {
        contenido.classList.add('d-none');
        itemDetalle.classList.remove('show-left');
        itemDetalle.classList.add('hide-effect');
        return;
      }
      itemDetalle.classList.add('show-left');
    } else {
      this.validarCierreCondicionado(poder);
      if (itemDetalle.className.indexOf('show-right') > -1) {
        contenido.classList.add('d-none');
        itemDetalle.classList.remove('show-right');
        itemDetalle.classList.add('hide-effect');
        return;
      }
      itemDetalle.classList.add('show-right');
    }
  }


  validarCierreCondicionado(poder: HTMLElement) {
    if (this.refTemp.length > 0 && poder !== this.refTemp[0]) {
      const refContent = this.refTemp[0].querySelector('.item-content-detail');
      const refItem = this.refTemp[0].querySelector('.casco-item');

      refContent.classList.add('d-none');

      if (refContent.classList.value.indexOf('contenido-left') > -1) {
        refItem.classList.remove('show-left');
      } else {
        refItem.classList.remove('show-right');
      }
      refItem.classList.add('hide-effect');
      this.refTemp = [];
    }
  }
}
