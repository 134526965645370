<div class="home">
  <div class="circulo-lineas"></div>
  <div class="circulo"></div>

  <!--MENU LATERAL HOME-->
  <div
    class="bmd-layout-container bmd-drawer-f-l bmd-drawer-overlay full-vh position-absolute"
    #menuLateral
  >
    <header class="bmd-layout-header">
      <div class="navbar-light bg-faded menu-drawer">
        <button
          class="navbar-toggler"
          type="button"
          (click)="menuLateralComportamiento(menuLateral, 'OPEN')"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
    </header>
    <div id="dw-p1" class="bmd-layout-drawer bg-faded">
      <header>
        <div class="text-center circular-img">
          <img [src]="!archivoEmitido ? fotoPerfil : archivoEmitido.url" />
        </div>
      </header>
      <div class="d-inline-block text-center opciones-menu">
        <button
          class="w-75 opciones-menu my-2 btn btn-outline text-white"
          (click)="verMisDatos()"
        >
          Mis datos
        </button>
        <button
          class="w-75 opciones-menu my-2 btn btn-outline text-white"
          (click)="resultados()"
        >
          Ver resultados
        </button>
        <button
          type="button"
          class="btn-primary-toulouse"
          (click)="cerrarSesion()"
        >
          Cerrar Sesión
        </button>
      </div>
    </div>
    <div
      class="bmd-layout-backdrop"
      (click)="menuLateralComportamiento(menuLateral, 'CLOSE')"
    ></div>
  </div>
  <!--FIN MENÚ LATERAL HOME-->
  <div class="container-fluid full-vh">
    <div class="row align-items-center div-superior position-relative">
      <img src="assets/images/logos/{{ logo }}" class="rounded logo" />
    </div>

    <div class="row mx-0 align-items-center div-inferior">
      <div
        class="col-md-4 col-sm-6 col-12 text-center perfil-home animate__animated animate__fadeInLeft"
      >
        <div class="text-center circular-img mb-3">
          <img
            appNgDropFilesAux
            (mouseSobreAux)="estaSobrePerfil = $event"
            [class.borde-drag]="estaSobrePerfil"
            [src]="!archivoEmitido ? fotoPerfil : archivoEmitido.url"
          />
          <div
            class="div-drag-hover"
            *ngIf="estaSobrePerfil"
            appNgDropFiles
            (mouseSobre)="estaSobrePerfil = $event"
            (archivoObtenido)="validarArchivo($event)"
          >
            Suelta aquí tu fotografía
          </div>

          <div
            class="div-drag-uploading"
            *ngIf="archivoEmitido && archivoEmitido?.progreso < 100"
          >
            <span>Espere...</span><br />
            <span>{{ archivoEmitido?.progreso }}%</span>
          </div>
        </div>

        <div class="align-items-center d-flex flex-column botonera-home">
          <button
            class="my-2 btn btn-outline text-white w-75 opciones-menu"
            (click)="verMisDatos()"
          >
            Mis datos
          </button>
          <button
            class="my-2 btn btn-outline text-white w-75 opciones-menu"
            (click)="resultados()"
          >
            Ver resultados
          </button>
          <button
            (click)="cerrarSesion()"
            class="my-2 mt-5 btn-primary-toulouse text-white w-75 opciones-menu"
          >
            Cerrar Sesión
          </button>
        </div>
      </div>
      <div class="col-md-8 col-sm-6 col-12 mi-form position-relative">
        <div
          class="align-items-start d-flex container-entrenadora animate__fadeIn animate__animated animate__delay-1s"
          *ngIf="contenidoCargado"
        >
          <div class="bubble-message buble-ab buble-div">
            <span class="title-buble">¡Bienvenido a #ModoSuperhéroe!</span>
            <p class="mt-2" *ngIf="!palabraHeroeBD" [innerHTML]="mensaje"></p>
            <p class="mt-2" *ngIf="palabraHeroeBD">{{ mensajeContinuacion }}</p>
          </div>
          <img
            *ngIf="!palabraHeroeBD"
            class="entrenadora"
            src="assets/images/iconos/entrenadora-grande.svg"
          />
          <img
            *ngIf="palabraHeroeBD"
            class="entrenadora"
            src="assets/images/iconos/entrenadora-grande-2.svg"
          />
        </div>

        <div
          class="declaracion-home animate__animated animate__fadeIn animate__delay-1s"
          *ngIf="contenidoCargado"
        >
          <div>
            <!--                         <p *ngIf="!palabraHeroeBD" class="text-center">Confirmo mi participación en el taller virtual de Descubrir tu pasión lo cambia TODO y me comprometo a llegar a la sesión con las tareas previas realizadas. Al inscribirme acepto que el Instituto Toulouse Lautrec se ponga en contacto conmigo al final del proceso. *</p><br/> -->
            <div *ngIf="!palabraHeroeBD" class="checkbox text-center">
              <label style="margin-left: -0.8rem; line-height: 1.2">
                <input type="checkbox" [(ngModel)]="palabraHeroeCheck" />
                <span
                  class="checkbox-decorator"
                  style="margin: -0.62rem 0rem 0rem 0rem"
                  ><span class="check"></span
                ></span>
                Acepto que Toulouse Lautrec procese mis datos y me envíe
                información personalizada, de acuerdo con las
                <a
                  href="https://www.toulouselautrec.edu.pe/politicas-privacidad"
                  target="_blank"
                  class="text-white"
                  style="text-decoration: underline"
                  >Políticas de Privacidad.</a
                >
              </label>
            </div>
            <br />
            <div class="d-flex flex-row">
              <button
                type="button"
                class="btn-primary-toulouse"
                (click)="iniciarTest()"
                [disabled]="isLoading"
                *ngIf="!testTerminado"
              >
                <div *ngIf="!isLoading">
                  {{ !palabraHeroeBD ? "Iniciar Test" : "Continuar Test" }}
                </div>
                <div *ngIf="isLoading">
                  <i class="fas fa-spinner fa-spin mr-1"></i>Espere...
                </div>
              </button>

              <button
                type="button"
                class="btn-primary-toulouse"
                (click)="validarEdicionTest()"
                [disabled]="isLoading"
                *ngIf="testTerminado"
              >
                <div *ngIf="!isLoading">Editar Test</div>
                <div *ngIf="isLoading">
                  <i class="fas fa-spinner fa-spin mr-1"></i>Espere...
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
