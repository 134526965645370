import { createReducer, on } from '@ngrx/store';
import { clearToken, setToken } from '../actions/user.action';

export interface State {
    tokenData: any;
}

export const initialState: State = {
    tokenData: ''
};

const _tokenReducer = createReducer(initialState,
    on(setToken, (state, { tokenProps }) => {
        return {
            ...state,
            tokenData: tokenProps
        };
    }),
    on(clearToken, (state) => {
        return {
            ...state,
            tokenData: null
        };
    })
);


export function tokenReducer(state, action) {
    return _tokenReducer(state, action);
}
