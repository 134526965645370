<div class="auth_form login">
  <div class="circulo-lineas"></div>
  <div class="circulo"></div>

  <div
    class="container full-vh animate__animated animate__faster animate__zoomIn"
  >
    <div class="row align-items-center div-superior position-relative">
      <img src="assets/images/logos/{{ logo }}" class="rounded logo" />
      <button
        [disabled]="isLoading"
        type="button"
        [routerLink]="['/registro']"
        class="btn btn-outline btn-registrarse"
      >
        Registrarse
      </button>
    </div>

    <div class="row mx-0 align-items-center div-inferior">
      <div class="col-md-6 col-sm-6 col-12">
        <div class="texto-login title-text-white-p text-center">
          <span class="text-login-inicio">
            Al igual que en las películas, el viaje de un superhéroe empieza
            aceptando quién es y hacia dónde quiere ir, con ello encontrará eso
            relevante en su vida que lo hace diferente.
          </span>

          <span class="text-login-centro">Ponte en</span>
          <!-- <img src="assets/images/iconos/text_login.svg" /> -->

          <div class="text-login-hashtag">
            <span>#</span>
            <span>modosuperhéroe</span>
          </div>

          <span class="text-login-final title-text-primary-p"
            >y descubre la carrera que va con tu propósito de vida.</span
          >
        </div>
      </div>

      <div class="col-md-6 col-sm-6 col-12 mi-form px-lg-5">
        <form [formGroup]="loginForm" class="rounded px-lg-5">
          <div class="text-center">
            <img
              src="assets/images/iconos/heroe_login.svg"
              class="foto-perfil"
            />
          </div>
          <input
            type="text"
            placeholder="Email "
            name="correo "
            autoComplete="off "
            class="form-control-retro mt-3 mb-4"
            formControlName="correo"
          />
          <input
            type="password"
            placeholder="Password "
            name="clave "
            class="form-control-retro mb-2"
            formControlName="clave"
          />
          <div class="w-100 text-right">
            <a
              (click)="mostrarModalCambioPassword = true"
              class="p-2 text-muted pointer"
              >¿Olvidaste tu contraseña?</a
            >
          </div>

          <div class="py-3 text-center">
            <button
              [disabled]="isLoading"
              type="button"
              (click)="loginEmail()"
              class="btn-login-grad btn-login"
            >
              <div *ngIf="!isLoading">Iniciar Sesión</div>
              <div *ngIf="isLoading">
                <i class="fas fa-spinner fa-spin"></i> Espere...
              </div>
            </button>
          </div>

          <div class="w-100 text-center my-2">
            <label class="text-muted">O inicia sesión con</label>
          </div>

          <div class="row justify-content-between mx-0 social-login">
            <button
              [disabled]="isLoading"
              type="button"
              class="col-4 btn btn-primary fb"
              (click)="loginConFacebook()"
            >
              <i class="fab fa-facebook-f mr-lg-2 mr-0"></i>
              <span>Facebook</span>
            </button>
            <button
              [disabled]="isLoading"
              type="button"
              class="col-4 btn btn-primary google"
              (click)="loginConGoogle()"
            >
              <i class="fab fa-google mr-lg-2 mr-0"></i>
              <span>Google</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-modal-password
  (cambiarPassword)="cambiarPassword($event)"
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModalCambioPassword"
></app-modal-password>
