import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-casco',
  templateUrl: './casco.component.html',
  styleUrls: ['./casco.component.scss'],
})
export class CascoComponent implements OnInit {
  //Variables globales
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;

  //Variables de apoyo
  @ViewChild('avatarElegido') avatarElegido: ElementRef; //Template reference hacia la imagen que muestra los cascos
  cascos = GlobalConstants.CASCOS; //constante global con los cascos
  cascoElegido: IAvatar;
  /* posCascoElegidoActual = 0; */
  posCascoElegidoAnterior = 0;
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  cabeza: string;
  //Variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe((res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;

        if (this.heroeInfo) {
          const genero = this.userInfo.genero.toLocaleLowerCase();
          this.cabeza = this.etapaService.obtenerPersonaje(
            'cascos',
            `${genero}_cabeza`
          );
          //Si el casco no tiene algun valor en la bd, le pongo el ? porque puede que sea null asi que queiro omitor la validacion
          this.cascoElegido =
            res.heroe?.casco.length > 0 ? res.heroe?.casco[0] : this.cascos[0];
        }
      });
    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });

    //la primera vez el modal aparecerá
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  async siguiente() {
    if (!this.cascoElegido) {
      this.alerts.modalInfo(null, 'Debe elegir un item para continuar');
      return;
    }

    this.store.dispatch(isLoading());

    //actualizamos el poder elegido
    const dataHeroe = {
      casco: [this.cascoElegido],
    };
    await this.etapaService.actualizarTest(
      this.userInfo.uuid,
      this.userInfo.flagTrackingTest.uuidTest,
      dataHeroe
    );

    //actualizamos el último path visitado
    const dataUser: IUser = {
      flagTrackingTest: {
        ...this.userInfo.flagTrackingTest,
        lastPath: this.router.url,
        etapasPath: this.router.url,
      },
      fechFinTest: '',
      misCarreras: [],
      miSuperHeroe: {
        CDO: '',
        calzado:
          this.heroeInfo?.calzado[0].titulo +
          ' - ' +
          this.heroeInfo?.calzado[0].descripcion,
        casco: this.cascoElegido.titulo + ' - ' + this.cascoElegido.descripcion,
        don: '',
        habilidades: '',
        herramientas: '',
        nick: '',
        poder:
          this.heroeInfo?.poder[0].titulo +
          ' - ' +
          this.heroeInfo?.poder[0].descripcion,
        proposito: '',
        tipoCuerpo:
          this.heroeInfo?.tipoCuerpo[0].titulo +
          ' - ' +
          this.heroeInfo?.tipoCuerpo[0].descripcion,
        vestimenta:
          this.heroeInfo?.vestimenta[0].titulo +
          ' - ' +
          this.heroeInfo?.vestimenta[0].descripcion,
      },
    };

    this.usuario
      .updUsuarioFireStore(this.userInfo.uuid, dataUser)
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(
          setUser({ info: { ...this.userInfo, ...dataUser } })
        );
        this.store.dispatch(
          setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } })
        );
        this.router.navigate(['/alumno/etapa']);
      })
      .catch((err) => {
        this.store.dispatch(stopLoading());
        this.alerts.modalError(null, 'Sucedio un error:' + err);
      });
  }

  elegirCasco(pos: number, cascoElegido: IAvatar) {
    const avatarRef = this.avatarElegido.nativeElement;
    this.cascoElegido = cascoElegido;

    switch (this.cascoElegido) {
      case this.cascos[0]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      case this.cascos[1]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      case this.cascos[2]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      case this.cascos[3]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      case this.cascos[4]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      case this.cascos[5]:
        avatarRef.classList.replace(
          `${this.cascos[this.posCascoElegidoAnterior].codigo}-puesto`,
          `${this.cascoElegido.codigo}-puesto`
        );
        break;
      default:
        break;
    }
    this.posCascoElegidoAnterior = pos;
  }

  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }
  atras() {
    this.router.navigate(['/alumno/calzate']);
  }
}
