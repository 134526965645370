import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'control-messages',
  template: `<small *ngIf="mensajeError !== null" style="color: #f1e903;">{{mensajeError}}</small>`,
  styles: [
  ]
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  constructor() { }

  get mensajeError() {
    for (const tipoError in this.control?.errors) {

      if (
        this.control.errors.hasOwnProperty(tipoError) &&
        this.control.touched
      ) {
        return ValidationService.getValidatorErrorMessage(
          tipoError,
          this.control.errors[tipoError]
        );
      }
    }
    return null;
  }

}
