import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand
import { IAuth } from '../interfaces/IAuth';
import { IUser } from '../interfaces/IUser';

dayjs.locale('es'); // use Spanish locale globally

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private fireStore: AngularFirestore) {}

  //consultar datos del usuario que inicio sesión
  misDatos(userUUID: string) {
    return this.fireStore.doc(`usuarios/${userUUID}`).get().toPromise();
  }
  //consultar datos del usuario que inicio sesión
  miHeroe(userUUID: string, uuidTest: string) {
    return this.fireStore
      .doc(`usuarios/${userUUID}/misTests/${uuidTest}`)
      .get()
      .toPromise();
  }

  //Metodo para registrar al usaurio en firestore con las propiedades de IAuth
  regUsuarioFireStore(userUUID: string, usuarioAuth: IAuth) {
    //Creamos una constante para inicializar a un alumno nuevo con todas estas propiedades
    const _fotoPerfil = !usuarioAuth?.urlFotoPerfilRedes
      ? ''
      : usuarioAuth?.urlFotoPerfilRedes;
    const userInfo: IUser = {
      uuid: userUUID,
      nombres: usuarioAuth?.nombres,
      apellidoPaterno: usuarioAuth?.apellidoPaterno,
      apellidoMaterno: usuarioAuth?.apellidoMaterno,
      correo: usuarioAuth?.correo,
      fotoPerfil: _fotoPerfil,
      celular: usuarioAuth?.celular || '',
      telefono: '',
      colegio: '',
      gradoACursar: '',
      distritoColegio: '',
      queEstudiare: '',
      razonDeEstudiar: '',
      detalleDecision: '',
      genero: '',
      cargo: 'ALUMNO',
      registroDatos: false,
      fechaCreacion: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      anioCreacion: dayjs().format('YYYY'),
      mesCreacion: String(parseInt(dayjs().format('MM'))),
      //fechFinTest: new Date().toISOString(),
      palabraHeroe: false,
      flagTrackingTest: {},
      testTerminado: false,
      miSuperHeroe: [],
      misCarreras: [],
      ...(usuarioAuth?.analyticsParams && {
        analyticsParams: { ...usuarioAuth?.analyticsParams },
      }),
    };

    return this.fireStore.doc(`usuarios/${userUUID}`).set(userInfo);
  }

  updUsuarioFireStore(userUUID: string, userInfo: IUser) {
    //existe el update que solo edita ciertos campos
    //existe el set que reemplazara todo el document ocon lo que se le pase
    return this.fireStore.doc(`usuarios/${userUUID}`).update({ ...userInfo });
  }

  regCarreras(data) {}
}
