export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAYRK2LQjtPPArojEmoR_YvxyI-UTCf7G0',
    authDomain: 'ms-tls-prd.firebaseapp.com',
    databaseURL: 'https://ms-tls-prd.firebaseio.com',
    projectId: 'ms-tls-prd',
    storageBucket: 'ms-tls-prd.appspot.com',
    messagingSenderId: '465546612185',
    appId: '1:465546612185:web:b3ac25c9be6f64f0a5ddc8',
    measurementId: 'G-93W3C4WH13',
  },
  emblue: 'Zjc5OGNiMzUzYTc1NGNlZjlkMjNkNGY4ODI1NTU4MmE=',
};
