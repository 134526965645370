<div class="stages-header-web row justify-content-between px-4 mx-0">
  <div
    class="align-items-center col-7 d-flex stages-header-web__title-container justify-content-end"
  >
    <label class="text-white">{{ tituloEtapa }}</label>
  </div>
  <div
    class="align-items-center col-4 d-flex justify-content-end stages-header-web__logo-container"
  >
    <img src="assets/images/logos/logo-toulouse-white.png" />
  </div>
</div>

<div class="stages-header-mobile row justify-content-between mx-0">
  <div (click)="handleGoBack($event)" class="btn-back-yellow flex-column">
    <img src="assets/images/iconos/flecha-atras.png" />
    <span>Atrás</span>
  </div>
  <div
    class="align-items-center d-flex stages-header-mobile__title-container justify-content-end"
  >
    <label class="text-white">{{ tituloEtapa }}</label>
  </div>
  <div class="stages-header-mobile__help">
    <app-boton-ayuda *ngIf="showHelper" (abrirAyuda)="handleOpenHelper($event)"></app-boton-ayuda>
  </div>
</div>

<app-entrenadora
  [mensaje]="entrenadora.mensaje"
  [foto]="entrenadora.foto"
  class="col-12 lautrec-message px-0"
>
</app-entrenadora>
