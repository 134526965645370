import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { auth } from 'firebase/app';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { clearHeroe, clearToken, setHeroe, setToken, setUser, unSetUser } from '../store/actions/user.action';
import { AppState } from '../store/app.store';
import { IAuth } from './../interfaces/IAuth';
import { UserService } from './user.service';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubs: Subscription;
  userUID: string = '';
  miToken: string = '';



  constructor(
    private fireAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private usuario: UserService,
    private store: Store<AppState>,
    private http: HttpClient
  ) {
  }


  initAuthListener() {
    this.fireAuth.authState.subscribe(fuser => {
      if (fuser) {
        fuser.getIdToken().then(token => {
          this.store.dispatch(setToken({ tokenProps: token }));
        });

        this.fireStore.doc(`usuarios/${fuser.uid}`).get().toPromise().then(
          (res) => {
            //setea los valores del usuario
            this.store.dispatch(setUser({ info: res.data() }));
            this.userUID = fuser.uid;

            //Valida si el uuidtest tiene un valor
            if (res.data()?.flagTrackingTest.uuidTest !== '') { //si es diferente de vacio
              //obtenemos todos los valores registrados hasta el momento del hereo
              this.usuario.miHeroe(this.userUID, res.data()?.flagTrackingTest?.uuidTest).then((res) => {
                this.store.dispatch(setHeroe({
                  heroe: res.data()
                }));
              });
            }
          }
        );
      } else {
        this.store.dispatch(unSetUser());
      }
    });
  }

  async obtenerToken() {
    const res = await this.fireAuth.authState.toPromise();
    return res.getIdToken();
  }

  //Metodo para autenticarnos
  async loginUsuario(correo: string, clave: string) {
    const resLogin = await this.fireAuth.signInWithEmailAndPassword(correo, clave);
    const datosUsuario = await this.usuario.misDatos(resLogin.user.uid);

    return datosUsuario;
  }

  async loginConFacebook() {
    const resFacebook = await this.fireAuth.signInWithPopup(new auth.FacebookAuthProvider());

    //validamos si el usuario ya existe en firestore
    const validarDatos = await this.usuario.misDatos(resFacebook.user.uid);

    if (!validarDatos.exists) { //Si no existe que cree
      await this.usuario.regUsuarioFireStore(resFacebook.user.uid, {
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        correo: resFacebook.user.email,
        urlFotoPerfilRedes: resFacebook.user.photoURL + '?height=500'
      } as IAuth);
      //A la foto de fb le agrego el querystring height para que sea una foto más grande de lo contrario
      //sale una foto pequeña y pixeleada

      //Seteamos el rol base del alumno
      await this.http.put(GlobalConstants.URL_BASE + '/agregarRolAlumno', {}, {
        params: {
          uuidUsuario: resFacebook.user.uid
        }
      }).toPromise();
      return await this.usuario.misDatos(resFacebook.user.uid);
    }
    return validarDatos;
  }

  async loginConGoogle() {
    const resGoogle = await this.fireAuth.signInWithPopup(new auth.GoogleAuthProvider());
    //validamos si el usuario ya existe en firestore
    const validarDatos = await this.usuario.misDatos(resGoogle.user.uid);

    if (!validarDatos.exists) { //Si no existe que cree
      await this.usuario.regUsuarioFireStore(resGoogle.user.uid, {
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        correo: resGoogle.user.email,
        urlFotoPerfilRedes: resGoogle.user.photoURL
      } as IAuth);
      //la foto según google : si tiene traera la url sino traerá la letra como foto

      //Seteamos el rol base del alumno
      await this.http.put(GlobalConstants.URL_BASE + '/agregarRolAlumno', {}, {
        params: {
          uuidUsuario: resGoogle.user.uid
        }
      }).toPromise();
      return await this.usuario.misDatos(resGoogle.user.uid);
    }

    return validarDatos;
  }

  cerrarSesion() {
    this.userSubs?.unsubscribe(); //nos desuscribimos
    this.store.dispatch(unSetUser()); //eliminamos la info del store
    this.store.dispatch(clearToken());
    this.store.dispatch(clearHeroe());
    return this.fireAuth.signOut();
  }

  //Creamos el usuario y le seteamos el valor de nombre
  async crearUsuario(usuarioAuth: IAuth) {
    const resCreateUser = await this.fireAuth.createUserWithEmailAndPassword(usuarioAuth.correo, usuarioAuth.clave);
    const regUsuarioFireStore = await this.usuario.regUsuarioFireStore(resCreateUser.user.uid, usuarioAuth);
    await this.http.put(GlobalConstants.URL_BASE + '/agregarRolAlumno', {}, {
      params: {
        uuidUsuario: resCreateUser.user.uid
      }
    }).toPromise();

    return regUsuarioFireStore;
  }
  resetPassword(email: string) {
    return this.fireAuth.sendPasswordResetEmail(email);
  }

  isAuth() {
    return this.fireAuth.authState;
  }
}
