import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { EventType, FlowStage, FlowState } from 'src/app/interfaces/Emblue';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { FileItem } from 'src/app/models/FileItem';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
import { CargarImagenService } from 'src/app/services/cargarImagen.service';
import { EmblueService } from 'src/app/services/emblue.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setIsTheLastStep, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  //Referencias a las constantes globales
  logo = GlobalConstants.LOGO_TOULOUSE_ROJO_BLANCO; //logo
  mensaje = GlobalConstants.HOME_MENSAJE; //Mensaje de la entreneadora inicial
  mensajeContinuacion = GlobalConstants.HOME_MENSAJE_CONTINUACION; //Mensaje de la entrenadora cuando ya dio la palabra

  //Variables de apoyo
  susbscription: Subscription = new Subscription(); //Almacena las suscripciones para luego desuscribirse
  contenidoCargado: boolean;
  testTerminado: boolean;

  //foto de perfil
  estaSobrePerfil = false;
  archivoEmitido: FileItem;
  fotoPerfil: string;

  //Se crearon 2 para evitar el problema del ngmodel
  palabraHeroeCheck: boolean; //Facilita obtener el valor del check
  palabraHeroeBD: boolean; //obtiene el valor registrado en la base de datos

  //Variables del store
  userInfo: IUser; //referencia al usuario del store
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean; //referencia del loading del store

  constructor(
    private router: Router,
    private user: UserService,
    private alerts: AlertsService,
    private auth: AuthService,
    private store: Store<AppState>,
    private cargarImg: CargarImagenService,
    private http: HttpClient,
    private emblue: EmblueService
  ) {}

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(2))
      .subscribe((res) => {
        if (res.info) {
          //res.info inicialmente devuelve null y hasta que no devuelva un true no entrará a la petición
          this.userInfo = res.info; //Seteamos los datos del usuario del store
          this.heroeInfo = res.heroe;
          this.palabraHeroeCheck = this.userInfo.palabraHeroe; //seteamos el valor que tiene en la bd el check
          this.palabraHeroeBD = this.userInfo.palabraHeroe; //seteamos el valor que tiene en la bd el check
          this.testTerminado = this.userInfo.testTerminado;

          if (this.userInfo) {
            this.contenidoCargado = true;
            this.fotoPerfil =
              this.userInfo.fotoPerfil === '' || !this.userInfo.fotoPerfil
                ? 'assets/images/iconos/sin-fotoa.jpg'
                : this.userInfo.fotoPerfil;
          }
        }
      });

    this.store
      .select('ui')
      .pipe(take(2))
      .subscribe((res) => {
        //me suscribo al store del ui
        this.isLoading = res.isLoading; //almaceno el valor que tiene en el store en isLoading
      });
  }

  verMisDatos() {
    this.store.dispatch(setIsTheLastStep({ isTheLastStep: false }));
    this.router.navigate(['/alumno/mis-datos']);
  }

  cerrarSesion() {
    //Llamo a lmetodo cerrar sesión que redirige al login
    this.auth
      .cerrarSesion()
      .then((res) => {
        this.router.navigate(['/login']);
      })
      .catch((err) => {
        this.alerts.modalError(null, 'Sucedio un error:' + err);
      });
  }

  iniciarTest() {
    //Si la persona no ha marcado el check o esta en valor false
    if (!this.palabraHeroeCheck) {
      this.alerts.modalInfo(
        null,
        'Para poder iniciar el test es necesario que marques la casilla de aceptación :).'
      );
    } else {
      //En caso ya lo marque
      /*
        Este botón iniciar test servirá para actualziar el valor del check de palabra pero a la vez redirige a la siguiente pantalla,
        en otros casos cuando el alumno ya haya iniciado el texto de iniciar cambia a continuar, por lo que se debe validar
        sobre una propiedad llamada flagTrackingTest que almacena la última ruta del test y también el id del test
      */
      //primer validaremos si en la bd palabraHeroe esta en false, si es así significa que es la primera vez que ingresa
      if (this.palabraHeroeBD === false) {
        this.store.dispatch(isLoading());
        //Establecemos los valores que se setearan en el iniciar test
        const data = {
          ...this.userInfo, //Copia del estado actual y solo modificaremos lo que esta debajo
          palabraHeroe: this.palabraHeroeCheck,
          flagTrackingTest: {
            uuidTest: '',
            uuidTestFlag: '',
            lastPath: this.router.url,
            etapasPath: this.router.url,
          },
        };

        this.user
          .updUsuarioFireStore(this.userInfo.uuid, data)
          .then(async (res) => {
            this.store.dispatch(stopLoading());
            this.store.dispatch(setUser({ info: data }));
            const isTheFirstTime = !this.userInfo.hizoTest;
            if (isTheFirstTime) {
              await this.emblue.registerEvent(EventType.test, {
                Estado: FlowState.test,
                Etapa: FlowStage.init,
                Email: this.userInfo.correo,
              });
            }
            this.router.navigate(['/alumno/etapa']); //pasa a etapa al ser la primera vez
          })
          .catch((err) => {
            this.store.dispatch(stopLoading());
            this.alerts.modalInfo(null, `Sucedio un error:${err}`);
          });
      } else {
        /*ahora si el usuario estando en etapa retrocede volverá al home pero el lastPath no tendrá valor nuevo
          solo tendrá el valor del home pues el lastPath cambia solo cuando se presiona sobre el botón continuar,
          entonces hagamos una validación adicional
        */
        if (this.userInfo.flagTrackingTest.uuidTestFlag === '') {
          //Si el uuidtest sigue vacio entonces sigamos enviandolo a etapa pues aún no inicia el test
          this.router.navigate(['/alumno/etapa']);
        } else {
          //Sino ya ha ingresado y debemos redirigirlo a la última ruta

          if (this.userInfo.flagTrackingTest.lastPath === '/alumno/home') {
            //Si dice home significa que no ha pasado de la pantala de etapa luego de resetear
            this.router.navigate(['/alumno/etapa']);
          } else {
            this.router.navigate([this.userInfo.flagTrackingTest.lastPath]);
          }
        }
      }
    }
  }

  validarEdicionTest() {
    Swal.fire({
      title: 'Editar el test',
      text: '¿Estás seguro que deseas repetir tu entrenamiento?. Volverás a iniciar desde la primera etapa.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, editar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.editarTest();
      }
    });
  }

  editarTest() {
    const data: IUser = {
      ...this.userInfo, //Copia del estado actual y solo modificaremos lo que esta debajo
      testTerminado: false,
      flagTrackingTest: {
        uuidTestFlag: this.userInfo.flagTrackingTest.uuidTest,
        uuidTest: this.userInfo.flagTrackingTest.uuidTest,
        lastPath: this.router.url,
        etapasPath: this.router.url,
      },
    };

    this.store.dispatch(isLoading());
    this.user
      .updUsuarioFireStore(this.userInfo.uuid, data)
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(setUser({ info: data }));
        this.router.navigate(['/alumno/etapa']); //pasa a etapa al ser la primera vez
      })
      .catch((err) => {
        this.store.dispatch(stopLoading());
        this.alerts.modalInfo(null, `Sucedio un error:${err}`);
      });
  }

  resultados() {
    if (this.userInfo?.testTerminado) {
      this.router.navigate(['/alumno/consultar-resultado']);
    } else {
      this.alerts.modalInfo(null, 'Aún no ha realizado el test');
    }
  }

  async validarArchivo(event) {
    this.archivoEmitido = event;
    if (!this.archivoEmitido) {
      //Si lo emitido es null
      this.alerts.modalError(
        'Un problema con el archivo',
        'El archivo que intenta cargar debe tener un formato png o jpg  y ser menor a 1 mega'
      );
    } else {
      const res = await this.cargarImg.mostrarImagenPrevia(this.archivoEmitido);
      this.archivoEmitido.url = res['data'];
      this.cargarImg.cargarImagenAFirebase(this.archivoEmitido, this.userInfo);
    }
  }

  menuLateralComportamiento(menuLateral: HTMLElement, accion: string) {
    if (accion === 'OPEN') {
      menuLateral.classList.add('bmd-drawer-in');
      menuLateral.querySelector('.bmd-layout-backdrop').classList.add('in');
    } else {
      menuLateral.classList.remove('bmd-drawer-in');
      menuLateral.querySelector('.bmd-layout-backdrop').classList.remove('in');
    }
  }
}
