import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { AlertsService } from 'src/app/services/alerts.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { Storage, StorageKey } from 'src/app/utils/storage';
import { AuthService } from '../../services/auth.service';
import { setUser } from './../../store/actions/user.action';
import { AppState } from './../../store/app.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  mostrarModalCambioPassword = false;
  $uiSubscription: Subscription;
  isLoading: boolean;

  logo = GlobalConstants.LOGO_TOULOUSE_ROJO_BLANCO;
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private alerts: AlertsService,
    private route: ActivatedRoute
  ) {
    this.loginForm = formBuilder.group({
      correo: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      clave: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.$uiSubscription = this.store.select('ui').subscribe((state) => {
      this.isLoading = state.isLoading;
    });

    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        Storage.save(StorageKey.analyticsParams, params);
      }
    });
  }

  ngOnDestroy(): void {
    this.$uiSubscription.unsubscribe();
  }

  loginEmail() {
    const { correo, clave } = this.loginForm.value;

    this.store.dispatch(isLoading());
    this.loginForm.disable();
    this.auth
      .loginUsuario(correo, clave)
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(setUser({ info: { ...res.data() } }));
        this.router.navigate(['alumno/home']);
      })
      .catch((err) => {
        let mensajeError = '';
        if (
          err.code === 'auth/user-not-found' ||
          err.code === 'auth/wrong-password' ||
          err.code === 'auth/invalid-email'
        ) {
          mensajeError =
            'El correo ingresado es incorrecto o la contraseña es errónea';
        }
        this.store.dispatch(stopLoading());
        this.loginForm.enable();
        this.alerts.modalError(null, `${mensajeError}`);
      });
  }
  loginConGoogle() {
    this.store.dispatch(isLoading());
    this.auth
      .loginConGoogle()
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(setUser({ info: { ...res.data() } }));
        this.router.navigate(['alumno/home']);
      })
      .catch((err) => {
        let mensajeError = '';
        if (err.code === 'auth/popup-closed-by-user') {
          mensajeError =
            'Sucedió un error: el usuario ha cerrado la ventana de inicio de sesión por Google';
        } else {
          mensajeError = err.message;
        }
        this.store.dispatch(stopLoading());
        this.alerts.modalError(null, `${mensajeError}`);
      });
  }
  loginConFacebook() {
    this.store.dispatch(isLoading());
    this.auth
      .loginConFacebook()
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(setUser({ info: { ...res.data() } }));
        this.router.navigate(['alumno/home']);
      })
      .catch((err) => {
        let mensajeError = '';
        if (err.code === 'auth/popup-closed-by-user') {
          mensajeError =
            'Sucedió un error: el usuario ha cerrado la ventana de inicio de sesión por Facebook';
        } else {
          mensajeError = err.message;
        }
        this.store.dispatch(stopLoading());
        this.alerts.modalError(null, `${mensajeError}`);
      });
  }

  cerrarModalPadre(valorEmitido: boolean) {
    this.mostrarModalCambioPassword = valorEmitido;
  }

  cambiarPassword(valorEmitido: string) {
    this.mostrarModalCambioPassword = false;

    this.auth
      .resetPassword(valorEmitido)
      .then((res) => {
        this.alerts.modalInfo(
          null,
          'Se le ha enviado un correo con los pasos a seguir para cambiar su contraseña.'
        );
      })
      .catch((err) => {
        this.alerts.modalError(
          null,
          `El correo ingresado no existe o es un correo registrado usando redes sociales el cual no soporta esta opción.`
        );
      });
  }
}
