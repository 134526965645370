import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-barra-progreso-etapas',
  templateUrl: './barra-progreso-etapas.component.html',
  styleUrls: ['./barra-progreso-etapas.component.scss'],
})
export class BarraProgresoEtapasComponent implements OnInit {
  etapas: number[] = Array.from(Array(10).keys());
  @Input('etapaActual') etapaActual: number;
  @Input('totalBars') totalBars: number;
  @Input('percentage') percentage: number;
  constructor() {}

  ngOnInit(): void {}
}
