<div class="tipocuerpo">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end avatar-container position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="helper-web mt-md-2"
      ></app-boton-ayuda>

      <div class="col-12 col-md-12 d-flex flex-wrap justify-content-end px-3">
 
          <div
            class="avatar-container__left align-items-center col-12 col-md-5 d-flex justify-content-center"
          >
            <h1 class="title-text-white pl-lg-5">¡Elige tu esqueleto!</h1>
          </div>

          <div class="col-12 px-0 m-0 row body-detail-mobile d-none">
            <div>
              <h4>{{ tipoCuerpoElegido ? tipoCuerpoElegido.titulo : "" }}</h4>
            </div>
          </div>
          <div
            class="body-description-mobile d-none col-12 text-white text-center"
          >
            <p>{{ tipoCuerpoElegido?.descripcion }}</p>
          </div>

          <div class="avatar-container__right col-md-7 col-12">
            <div class="row">
              <div
                class="align-items-end col-4 d-flex flex-column justify-content-center p-0"
              >
                <div
                  class="align-items-center d-flex casco-item justify-content-center py-2 width-40"
                  #tipo_0
                >
                  <div
                    class="casco-container z-index-2"
                    (click)="iconClick(tipo_0, 'IZQ', tipoCuerpo[0])"
                  >
                    <img src="assets/images/tipos/{{ tipoCuerpo[0].foto }}" />
                  </div>
                  <div
                    class="z-index-1 d-flex align-items-center casco-item casco-item-left"
                  >
                    <div
                      class="col-1 d-flex justify-content-center pointer"
                      (click)="iconClick(tipo_0, 'IZQ', tipoCuerpo[0])"
                    >
                      <img
                        src="assets/images/iconos/flecha-atras.png"
                        height="20"
                      />
                    </div>
                    <div
                      class="item-content-detail contenido-left animate__animated d-none"
                    >
                      <p>
                        {{ tipoCuerpo[0].descripcion }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="align-items-center d-flex casco-item justify-content-center py-2 width-40"
                  #tipo_1
                >
                  <div
                    class="casco-container z-index-2"
                    (click)="iconClick(tipo_1, 'IZQ', tipoCuerpo[1])"
                  >
                    <img src="assets/images/tipos/{{ tipoCuerpo[1].foto }}" />
                  </div>
                  <div
                    class="z-index-1 d-flex align-items-center casco-item casco-item-left"
                  >
                    <div
                      class="col-1 d-flex justify-content-center pointer"
                      (click)="iconClick(tipo_1, 'IZQ', tipoCuerpo[1])"
                    >
                      <img
                        src="assets/images/iconos/flecha-atras.png"
                        height="20"
                      />
                    </div>
                    <div
                      class="item-content-detail contenido-left animate__animated d-none"
                    >
                      <p>
                        {{ tipoCuerpo[1].descripcion }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex justify-content-center avatar">
                <img class="personaje" [src]="personaje" />
                <img
                  *ngIf="cinturon"
                  class="cinturon-tipo"
                  src="assets/images/poderes/{{ cinturon }}"
                />
                <img
                  *ngIf="tipoCuerpoElegido"
                  class="tipoCuerpo {{ tipoCuerpoElegido?.codigo }}-puesto"
                  src="assets/images/tipos/{{ tipoCuerpoElegido?.foto }}"
                />
              </div>
              <div
                class="align-items-start col-4 d-flex flex-column justify-content-center p-0"
              >
                <div
                  class="align-items-center d-flex casco-item justify-content-center py-2 width-40"
                  #tipo_2
                >
                  <div
                    class="casco-container z-index-2"
                    (click)="iconClick(tipo_2, 'DER', tipoCuerpo[2])"
                  >
                    <img src="assets/images/tipos/{{ tipoCuerpo[2].foto }}" />
                  </div>
                  <div
                    class="align-items-center d-flex casco-item casco-item-right justify-content-end z-index-1"
                  >
                    <div
                      class="item-content-detail contenido-right animate__animated d-none"
                    >
                      <p>
                        {{ tipoCuerpo[2].descripcion }}
                      </p>
                    </div>
                    <div
                      class="col-1 d-flex justify-content-center pointer"
                      (click)="iconClick(tipo_2, 'DER', tipoCuerpo[2])"
                    >
                      <img
                        src="assets/images/iconos/flecha-adelante.png"
                        height="20"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="align-items-center d-flex casco-item justify-content-center py-2 width-40"
                  #tipo_3
                >
                  <div
                    class="casco-container z-index-2"
                    (click)="iconClick(tipo_3, 'DER', tipoCuerpo[3])"
                  >
                    <img src="assets/images/tipos/{{ tipoCuerpo[3].foto }}" />
                  </div>
                  <div
                    class="align-items-center d-flex casco-item casco-item-right justify-content-end z-index-1"
                  >
                    <div
                      class="item-content-detail contenido-right animate__animated d-none"
                    >
                      <p>
                        {{ tipoCuerpo[3].descripcion }}
                      </p>
                    </div>
                    <div
                      class="col-1 d-flex justify-content-center pointer"
                      (click)="iconClick(tipo_3, 'DER', tipoCuerpo[3])"
                    >
                      <img
                        src="assets/images/iconos/flecha-adelante.png"
                        height="20"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row container__actions">
              <div class="d-flex">
                <button
                  [disabled]="isLoading"
                  type="button"
                  class="btn-primary-toulouse"
                  (click)="siguiente()"
                >
                  <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                  >{{ isLoading ? "Espere..." : "Siguiente" }}
                </button>
              </div>
            </div>
          </div>
 
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="30"
      [totalBars]="3"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
