<div class="nombre-heroe">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 text-right otrosDevices div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>
      <div
        class="avatar-container col-12 col-md-12 d-flex div-contenido flex-wrap justify-content-end px-3"
      >
        <div class="col-md-6 d-flex flex-column flex-width-100 mt-4">
          <div class="align-items-center justify-content-between mx-0 row">
            <h1 class="title-text-white">
              Escribe tu nombre de
              <label class="title-text-primary">Superhéroe</label>
            </h1>
          </div>
          <div class="flex-column mx-0 row">
            <div>
              <textarea
                [(ngModel)]="nombreHeroe"
                class="form-control-retro"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-5 d-flex personaje-container row flex-width-100">
          <div class="position-relative avatar otrosDevices">
            <img *ngIf="personaje" class="personaje" [src]="personaje" />
            <img
              *ngIf="cinturon"
              class="cinturon"
              src="assets/images/poderes/{{ cinturon }}"
            />
            <img
              *ngIf="tipoCuerpoElegido"
              class="tipoCuerpo det-tipo {{ tipoCuerpoElegido?.codigo }}-puesto"
              src="assets/images/tipos/{{ tipoCuerpoElegido?.foto }}"
            />
            <img
              *ngIf="pecheraElegida"
              class="pechera {{ pecheraElegida.codigo }}-puesto"
              src="assets/images/pecheras/{{ pecheraElegida?.foto }}"
            />
            <img
              *ngIf="calzadoElegido"
              class="calzado {{ calzadoElegido.codigo }}-puesto"
              src="assets/images/calzados/{{ calzadoElegido?.foto }}"
            />
            <img
              *ngIf="cascoElegido"
              class="miCasco {{ cascoElegido.codigo }}-puesto"
              src="assets/images/cascos/{{ cascoElegido?.foto }}"
            />
          </div>
          <div class="text-center col-12">
            <button
              [disabled]="isLoading"
              type="button"
              class="btn-primary-toulouse"
              (click)="siguiente()"
              style="width: 16rem"
            >
              <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
              ><span>{{ isLoading ? "Espere..." : "Siguiente" }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="99"
      [totalBars]="10"
    ></app-stage-footer>
  </div>
</div>

<!--MODAL DE AYUDA Y MENSAJE DIRECTO-->
<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>

<!--MODAL DE FELICITACIONES-->
<app-modal-mensaje
  (clickBoton)="clickBotonModalToulouse($event)"
  [mostrarModal]="mostrarModalFelicitacion"
  [mostrarIconoCierre]="false"
  [mostrarIconoDetalle]="false"
  [mostrarBoton]="true"
  [textoBoton]="textoBoton"
  [titulo]="tituloModalFelicitacion"
  [detalle]="detalleModalFelicitacion"
>
</app-modal-mensaje>
