import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-password',
  templateUrl: './modal-password.component.html',
  styleUrls: ['./modal-password.component.scss']
})
export class ModalPasswordComponent implements OnInit {
  @Input() mostrarModal: boolean;
  @Output() cerrarModal = new EventEmitter<boolean>();
  @Output() cambiarPassword = new EventEmitter<string>();

  correo: string = '';
  validarCampo: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  cerrarModalToulouse() {
    this.mostrarModal = false;
    this.correo = '';
    this.validarCampo = false;

    this.cerrarModal.emit(false);
  }

  cambiarPasswordToulouse() {
    const correoEscrito = this.correo;
    if (this.correo.length === 0) {
      this.validarCampo = true;
      return;
    }
    this.correo = '';
    this.validarCampo = false;
    this.mostrarModal = false;
    this.cambiarPassword.emit(correoEscrito);
  }
}
