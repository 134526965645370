import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { EventType, FlowStage, FlowState } from 'src/app/interfaces/Emblue';
import { IEtapas } from 'src/app/interfaces/IEtapas';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { EmblueService } from 'src/app/services/emblue.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-info-etapas',
  templateUrl: './info-etapas.component.html',
  styleUrls: ['./info-etapas.component.scss'],
})
export class InfoEtapasComponent implements OnInit {
  //constantes
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;

  //variables de store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;
  //Variables de apoyo
  infoEtapa: IEtapas;
  tituloEtapa: string = '';
  detalleEtapa: string = '';
  etapasPath: string = ''; //Se usará para el backbutton
  paramCodigoEtapa: string = '';

  constructor(
    private router: Router,
    private etapa: EtapaService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private emblue: EmblueService
  ) {
    //obtenemos el parametro de la url si es que tiene, sino mostrará un null
    this.paramCodigoEtapa = this.route.snapshot.paramMap.get('cod');
  }

  ngOnInit(): void {
    //Nos suscribimos al store pero luego de obtener 2 valores nos desuscribimos con el take para evitar fuga de memoria
    this.store
      .select('user')
      .pipe(take(2))
      .subscribe((res) => {
        this.userInfo = res.info; //seteamos el valor del store
        this.heroeInfo = res.heroe;
        if (this.userInfo) {
          //si ya no es null
          this.etapasPath = this.userInfo.flagTrackingTest?.etapasPath; //almacenamos el etapas path antes de entrar a la página etapa
          this.infoEtapa = this.etapa.mapeoDeRutasyMensajes(this.etapasPath); //Obtenemos el info de la etapa en base al path

          //Una vez obtenido la info seteamos los valores
          this.tituloEtapa = this.infoEtapa.titulo;
          this.detalleEtapa = this.infoEtapa.descripcion;
        }
      });

    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });
  }

  async crearTest() {
    const rutaSiguiente = this.obtenerRutaSiguiente();
    const tracking = this.userInfo.flagTrackingTest;
    this.store.dispatch(isLoading());

    const isTheFirstTime = !this.userInfo.hizoTest;
    if (isTheFirstTime) {
      switch (rutaSiguiente) {
        case '/alumno/mi-cuerpo':
          await this.emblue.registerEvent(EventType.test, {
            Estado: FlowState.test,
            Etapa: FlowStage.lookingForHero,
            Email: this.userInfo.correo,
          });
          break;
        case '/alumno/cuerpo':
          await this.emblue.registerEvent(EventType.test, {
            Estado: FlowState.test,
            Etapa: FlowStage.knowMe,
            Email: this.userInfo.correo,
          });
          break;
        case '/alumno/mochila-don':
          await this.emblue.registerEvent(EventType.test, {
            Estado: FlowState.test,
            Etapa: FlowStage.inspectTalent,
            Email: this.userInfo.correo,
          });
          break;
        case '/alumno/problemas':
          await this.emblue.registerEvent(EventType.test, {
            Estado: FlowState.test,
            Etapa: FlowStage.mision,
            Email: this.userInfo.correo,
          });
          break;
      }
    }

    //si el objeto flagtrackingtest.uuid es vacio creará el test ya que se asume que es la primera vez
    if (tracking.uuidTestFlag === '') {
      if (tracking.uuidTest === '') {
        this.etapa.crearTest(this.userInfo.uuid).then((res) => {
          this.store.dispatch(stopLoading());
          this.router.navigate([rutaSiguiente]); //De la infoetapa obtenida se pasa a la siguiente pantalla
        });
      } else {
        this.store.dispatch(stopLoading());
        this.router.navigate([rutaSiguiente]); //De la infoetapa obtenida se pasa a la siguiente pantalla
      }
    } else {
      this.store.dispatch(stopLoading());
      //si existe significa que ya se hizo un registro y no es necesario crear nada solo enviar al siguiente path segun infoEtapa
      if (tracking.uuidTest !== '') {
        this.router.navigate([rutaSiguiente]);
      }
    }
  }

  atras() {
    const rutaPrevia = this.obtenerRutaPrevia();
    this.router.navigate([rutaPrevia]);
  }

  obtenerRutaPrevia() {
    if (this.paramCodigoEtapa) {
      //Si tiene algún valor
      const infoEtapa = this.etapa.obtenerEtapaPorCodigo(this.paramCodigoEtapa);
      if (infoEtapa) {
        //Si no es null
        return infoEtapa.pathPrevio;
      } else {
        return this.etapasPath;
      }
    } else {
      return this.etapasPath;
    }
  }
  obtenerRutaSiguiente() {
    if (this.paramCodigoEtapa) {
      //Si tiene algún valor
      const infoEtapa = this.etapa.obtenerEtapaPorCodigo(this.paramCodigoEtapa);
      if (infoEtapa) {
        //Si no es null
        return infoEtapa.pathSiguiente;
      } else {
        return this.infoEtapa.pathSiguiente;
      }
    } else {
      return this.infoEtapa.pathSiguiente;
    }
  }
}
