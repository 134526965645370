import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carreras-matriz',
  templateUrl: './carreras-matriz.component.html',
  styleUrls: ['./carreras-matriz.component.scss']
})
export class CarrerasMatrizComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
