<div class="mochila">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 text-right otrosDevices div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>
      <div
        class="avatar-container col-12 col-md-12 d-flex div-contenido flex-wrap justify-content-end px-3 flex-width-100"
      >
        <div class="col-12 col-md-8 d-flex flex-wrap my-2 flex-width-100">
          <div class="col-12 mb-4 px-0">
            <h1 class="title-text-white pl-lg-2">Equípate</h1>
          </div>
          <div class="col-12 d-flex row px-0 mx-0">
            <div class="col-7 col-md-8 d-flex opciones row px-0 mx-0">
              <div
                *ngFor="let item of itemsCopia; let i = index"
                class="align-items-center d-flex justify-content-center py-2"
              >
                <div
                  [id]="item.codigo"
                  class="div-ropa-eleccion {{ item.flagClase }}"
                  (click)="elegirItem(item, itemDiv)"
                  #itemDiv
                >
                  <img src="assets/images/mochila/{{ item.foto }}" />
                  <label>{{ item.titulo }}</label>
                </div>
              </div>
            </div>
            <div
              class="align-items-center col-5 col-md-4 d-flex personaje justify-content-center"
            >
              <div class="col-12 m-0 row div-detalle-responsive">
                <div>
                  <h4>
                    {{
                      itemSeleccionado
                        ? itemSeleccionado.titulo
                        : "Esperando tu equipo..."
                    }}
                  </h4>
                </div>
              </div>
              <div
                class="descripcion-responsive d-none col-12 text-white mt-2 mb-3"
              >
                <p>{{ itemSeleccionado?.descripcion }}</p>
              </div>

              <div class="mochila-res position-relative">
                <div class="div-item-elegido-0">
                  <img
                    *ngIf="misDones.length == 1"
                    src="assets/images/mochila/{{ misDones[0].foto }}"
                  />
                </div>
                <div class="div-item-elegido-1">
                  <img
                    *ngIf="misHabilidades.length >= 1"
                    src="assets/images/mochila/{{ misHabilidades[0].foto }}"
                  />
                </div>
                <div class="div-item-elegido-2">
                  <img
                    *ngIf="misHabilidades.length == 2"
                    src="assets/images/mochila/{{ misHabilidades[1].foto }}"
                  />
                </div>
                <div class="div-item-elegido-3">
                  <img
                    *ngIf="misHerramientas.length == 1"
                    src="assets/images/mochila/{{ misHerramientas[0].foto }}"
                  />
                </div>
                <img class="avatar" src="assets/images/mochila/mochila.svg" />
              </div>

              <button
                [disabled]="isLoading"
                type="button"
                class="d-none sig-cel btn-primary-toulouse mt-4 w-100"
                (click)="siguiente()"
              >
                <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                >{{ isLoading ? "Espere..." : "Siguiente" }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="align-items-end col-12 col-md-3 d-flex justify-content-center div-detalle otrosDevices"
        >
          <div class="row mx-0">
            <div class="col-12 d-flex detalle-opcion flex-column">
              <div class="nombre">
                {{
                  itemSeleccionado !== ""
                    ? itemSeleccionado.titulo
                    : "Esperando tu equipo..."
                }}
              </div>
              <div class="detalle">
                <img
                  *ngIf="itemSeleccionado !== ''"
                  src="assets/images/mochila/{{ itemSeleccionado.foto }}"
                />
                <img
                  *ngIf="itemSeleccionado === ''"
                  src="assets/images/mochila/sin-item.svg"
                />
                <label>{{
                  itemSeleccionado !== ""
                    ? itemSeleccionado.descripcion
                    : "Elige algún item y equipate :)"
                }}</label>
              </div>
              <button
                [disabled]="isLoading"
                type="button"
                class="btn-primary-toulouse mt-4 w-100"
                (click)="siguiente()"
              >
                <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                >{{ isLoading ? "Espere..." : "Siguiente" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="70"
      [totalBars]="7"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
