import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-mochila',
  templateUrl: './mochila.component.html',
  styleUrls: ['./mochila.component.scss'],
})
export class MochilaComponent implements OnInit {
  //Constantes
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  items = GlobalConstants.MOCHILA;

  //Variables de apoyo
  itemsCopia: IAvatar[] = this.items;
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  itemSeleccionado: any = '';
  misDones: IAvatar[] = [];
  misHabilidades: IAvatar[] = [];
  misHerramientas: IAvatar[] = [];
  pathActual: string = '';
  maxEleccion: number = 0;

  //Variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  @ViewChildren('itemDiv') elementsRef: QueryList<ElementRef>;
  refTemp: any = [];

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService
  ) {
    this.pathActual = this.router.url;
    this.maxEleccion = this.calcularMaximaEleccion(this.router.url);
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe((res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;

        if (res.heroe) {
          //cuand sea true o tenga un valor
          this.misDones = [...res.heroe?.dones];
          this.misHabilidades = [...res.heroe?.habilidades];
          this.misHerramientas = [...res.heroe?.herramientas];

          const auxItems = [...this.items];

          const pathActual = this.router.url;
          if (pathActual === '/alumno/mochila-don') {
            this.misHabilidades = [];
            this.misHerramientas = [];

            this.itemsCopia = auxItems.map((res) => {
              if (res.codigo === this.misDones[0]?.codigo) {
                return {
                  ...res,
                  flagClase: 'elegido',
                  tipo: 'don',
                };
              }
              return res;
            });
          } else if (pathActual === '/alumno/mochila-hab') {
            this.misHerramientas = [];

            this.itemsCopia = auxItems.map((res) => {
              if (res.codigo === this.misDones[0]?.codigo) {
                return {
                  ...res,
                  flagClase:
                    this.router.url === '/alumno/mochila-don'
                      ? 'elegido'
                      : 'item-locked',
                  tipo: 'don',
                };
              }

              if (
                res.codigo === this.misHabilidades[0]?.codigo ||
                res.codigo === this.misHabilidades[1]?.codigo
              ) {
                return {
                  ...res,
                  flagClase: 'elegido',
                  tipo: 'hab',
                };
              }
              return res;
            });
          } else if (pathActual === '/alumno/mochila-herr') {
            this.itemsCopia = auxItems.map((res) => {
              if (res.codigo === this.misDones[0]?.codigo) {
                return {
                  ...res,
                  flagClase:
                    this.router.url === '/alumno/mochila-don'
                      ? 'elegido'
                      : 'item-locked',
                  tipo: 'don',
                };
              }

              if (
                res.codigo === this.misHabilidades[0]?.codigo ||
                res.codigo === this.misHabilidades[1]?.codigo
              ) {
                return {
                  ...res,
                  flagClase:
                    this.router.url === '/alumno/mochila-hab'
                      ? 'elegido'
                      : 'item-locked',
                  tipo: 'hab',
                };
              }

              if (res.codigo === this.misHerramientas[0]?.codigo) {
                return {
                  ...res,
                  flagClase: 'elegido',
                  tipo: 'herr',
                };
              }
              return res;
            });
          }
        }
      });
    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });

    //la primera vez el modal aparecerá de manera directa
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  elegirItem(itemElegido: IAvatar, refElement: HTMLElement) {
    if (this.router.url.split('-')[1] === itemElegido.tipo) {
      //Si la url contiene el tipo de item hab/don/herr
      this.agregarRemoverItemSegunPath(
        this.pathActual,
        itemElegido,
        refElement
      );
    } else {
      if (itemElegido.tipo) {
        this.alerts.modalInfo(
          null,
          'Este equipo ya ha sido elegido, escoja otro.'
        );
        return;
      } else {
        this.agregarRemoverItemSegunPath(
          this.pathActual,
          itemElegido,
          refElement
        );
      }
    }
  }

  siguiente() {
    this.validarBotonSiguiente(this.pathActual);
  }

  //Calcular cuantas eleccionas tendrá por path
  calcularMaximaEleccion(path: string) {
    switch (path) {
      case '/alumno/mochila-don':
        return 1;
      case '/alumno/mochila-hab':
        return 2;
      case '/alumno/mochila-herr':
        return 1;
    }
  }
  agregarRemoverItemSegunPath(
    path: string,
    item: IAvatar,
    refElement?: HTMLElement
  ) {
    this.elementsRef.map((res_a) => {
      const ref: HTMLElement = res_a.nativeElement;
      if (ref.classList.value.indexOf('elegido') > -1) {
        if (this.refTemp[0] !== ref && this.refTemp[1] !== ref) {
          this.refTemp.push(ref);
        }
      }
    });

    switch (path) {
      case '/alumno/mochila-don':
        if (this.misDones.length > 0) {
          this.refTemp[0]?.classList.remove('elegido');
          this.misDones = [];
          this.refTemp = [];
        }
        this.refTemp.push(refElement);
        this.misDones.push(item);
        refElement?.classList.add('elegido');
        this.itemSeleccionado = item;
        break;
      case '/alumno/mochila-hab':
        const filtrarHabilidad = this.misHabilidades.find(
          (res) => res.codigo === item.codigo
        );
        const habilidadYaAgregada = Boolean(filtrarHabilidad);
        if (habilidadYaAgregada) {
          const removerHabilidad = this.misHabilidades.filter(
            ({ codigo }) => codigo !== filtrarHabilidad?.codigo
          );
          this.misHabilidades = removerHabilidad;

          const refToDelete = this.refTemp.find(
            ({ id }) => id === refElement.id
          );
          const refFiltereds = this.refTemp.filter(
            ({ id }) => id !== refElement.id
          );

          refToDelete?.classList.remove('elegido');
          this.refTemp = refFiltereds;
        } else {
          if (this.misHabilidades.length >= 2) {
            this.alerts.modalInfo(
              null,
              'No puedes elegir más de 2 hábilidades'
            );
            return;
          }

          this.refTemp.push(refElement);
          this.misHabilidades.push(item);
          refElement?.classList.add('elegido');
          this.itemSeleccionado = item;
        }
        break;
      case '/alumno/mochila-herr':
        if (this.misHerramientas.length > 0) {
          this.refTemp[0]?.classList.remove('elegido');
          this.misHerramientas = [];
          this.refTemp = [];
        }
        this.refTemp.push(refElement);
        this.misHerramientas.push(item);
        refElement?.classList.add('elegido');
        this.itemSeleccionado = item;

        break;
    }
  }

  async validarBotonSiguiente(path: string) {
    switch (path) {
      case '/alumno/mochila-don':
        if (this.misDones.length < this.maxEleccion) {
          this.alerts.modalInfo(
            null,
            'Debes elegir ' + this.maxEleccion + ' elementos para continuar'
          );
        } else {
          this.store.dispatch(isLoading());
          const dataHeroe = {
            dones: this.misDones,
            habilidades: [],
            herramientas: [],
          };
          await this.etapaService.actualizarTest(
            this.userInfo.uuid,
            this.userInfo.flagTrackingTest.uuidTest,
            dataHeroe
          );

          //actualizamos el último path visitado

          const dataUser: IUser = {
            flagTrackingTest: {
              ...this.userInfo.flagTrackingTest,
              lastPath: this.router.url,
            },
            fechFinTest: '',
            misCarreras: [],
            miSuperHeroe: {
              CDO: '',
              calzado:
                this.heroeInfo?.calzado[0].titulo +
                ' - ' +
                this.heroeInfo?.calzado[0].descripcion,
              casco:
                this.heroeInfo?.casco[0].titulo +
                ' - ' +
                this.heroeInfo?.casco[0].descripcion,
              don:
                this.misDones[0].titulo + ' - ' + this.misDones[0].descripcion,
              habilidades: '',
              herramientas: '',
              nick: '',
              poder:
                this.heroeInfo?.poder[0].titulo +
                ' - ' +
                this.heroeInfo?.poder[0].descripcion,
              proposito: '',
              tipoCuerpo:
                this.heroeInfo?.tipoCuerpo[0].titulo +
                ' - ' +
                this.heroeInfo?.tipoCuerpo[0].descripcion,
              vestimenta:
                this.heroeInfo?.vestimenta[0].titulo +
                ' - ' +
                this.heroeInfo?.vestimenta[0].descripcion,
            },
          };

          this.usuario
            .updUsuarioFireStore(this.userInfo.uuid, dataUser)
            .then((res) => {
              this.store.dispatch(stopLoading());
              this.store.dispatch(
                setUser({ info: { ...this.userInfo, ...dataUser } })
              );
              this.store.dispatch(
                setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } })
              );
              this.router.navigate(['/alumno/mochila-hab']);
            })
            .catch((err) => {
              this.store.dispatch(stopLoading());
              this.alerts.modalError(null, 'Sucedio un error:' + err);
            });
        }
        break;
      case '/alumno/mochila-hab':
        if (this.misHabilidades.length < this.maxEleccion) {
          this.alerts.modalInfo(
            null,
            'Debes elegir ' + this.maxEleccion + ' elementos para continuar'
          );
        } else {
          this.store.dispatch(isLoading());

          const dataHeroe = {
            habilidades: this.misHabilidades,
            herramientas: [],
          };
          await this.etapaService.actualizarTest(
            this.userInfo.uuid,
            this.userInfo.flagTrackingTest.uuidTest,
            dataHeroe
          );

          //Guardamos en la base de datos
          let habilidadesTexto: any = this.misHabilidades.map(
            (res) => res.titulo + ' - ' + res.descripcion
          );
          habilidadesTexto = habilidadesTexto.join(',');
          const dataUser: IUser = {
            flagTrackingTest: {
              ...this.userInfo.flagTrackingTest,
              lastPath: this.router.url,
            },
            fechFinTest: '',
            misCarreras: [],
            miSuperHeroe: {
              CDO: '',
              calzado:
                this.heroeInfo?.calzado[0].titulo +
                ' - ' +
                this.heroeInfo?.calzado[0].descripcion,
              casco:
                this.heroeInfo?.casco[0].titulo +
                ' - ' +
                this.heroeInfo?.casco[0].descripcion,
              don:
                this.heroeInfo?.dones[0].titulo +
                ' - ' +
                this.heroeInfo?.dones[0].descripcion,
              habilidades: habilidadesTexto,
              herramientas: '',
              nick: '',
              poder:
                this.heroeInfo?.poder[0].titulo +
                ' - ' +
                this.heroeInfo?.poder[0].descripcion,
              proposito: '',
              tipoCuerpo:
                this.heroeInfo?.tipoCuerpo[0].titulo +
                ' - ' +
                this.heroeInfo?.tipoCuerpo[0].descripcion,
              vestimenta:
                this.heroeInfo?.vestimenta[0].titulo +
                ' - ' +
                this.heroeInfo?.vestimenta[0].descripcion,
            },
          };

          //actualizamos el último path visitado
          this.usuario
            .updUsuarioFireStore(this.userInfo.uuid, dataUser)
            .then((res) => {
              this.store.dispatch(stopLoading());
              this.store.dispatch(
                setUser({ info: { ...this.userInfo, ...dataUser } })
              );
              this.store.dispatch(
                setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } })
              );
              this.router.navigate(['/alumno/mochila-herr']);
            })
            .catch((err) => {
              this.store.dispatch(stopLoading());
              this.alerts.modalError(null, 'Sucedio un error:' + err);
            });
        }
        break;
      case '/alumno/mochila-herr':
        if (this.misHerramientas.length < this.maxEleccion) {
          this.alerts.modalInfo(
            null,
            'Debes elegir ' + this.maxEleccion + ' elementos para continuar'
          );
        } else {
          this.store.dispatch(isLoading());

          const dataHeroe = {
            herramientas: this.misHerramientas,
          };
          await this.etapaService.actualizarTest(
            this.userInfo.uuid,
            this.userInfo.flagTrackingTest.uuidTest,
            dataHeroe
          );

          //Como es la ultima ruta agregamos el etapaspath para que sea mapeado
          //dataUser.flagTrackingTest.etapasPath = this.router.url;

          //Guardamos en la base de datos
          let habilidadesTexto: any = this.heroeInfo?.habilidades.map(
            (res) => res.titulo + ' - ' + res.descripcion
          );
          habilidadesTexto = habilidadesTexto.join(',');
          const dataUser: IUser = {
            flagTrackingTest: {
              ...this.userInfo.flagTrackingTest,
              etapasPath: this.router.url,
              lastPath: this.router.url,
            },
            fechFinTest: '',
            misCarreras: [],
            miSuperHeroe: {
              CDO: '',
              calzado:
                this.heroeInfo?.calzado[0].titulo +
                ' - ' +
                this.heroeInfo?.calzado[0].descripcion,
              casco:
                this.heroeInfo?.casco[0].titulo +
                ' - ' +
                this.heroeInfo?.casco[0].descripcion,
              don:
                this.heroeInfo?.dones[0].titulo +
                ' - ' +
                this.heroeInfo?.dones[0].descripcion,
              habilidades: habilidadesTexto,
              herramientas:
                this.misHabilidades[0].titulo +
                ' - ' +
                this.misHabilidades[0].descripcion,
              nick: '',
              poder:
                this.heroeInfo?.poder[0].titulo +
                ' - ' +
                this.heroeInfo?.poder[0].descripcion,
              proposito: '',
              tipoCuerpo:
                this.heroeInfo?.tipoCuerpo[0].titulo +
                ' - ' +
                this.heroeInfo?.tipoCuerpo[0].descripcion,
              vestimenta:
                this.heroeInfo?.vestimenta[0].titulo +
                ' - ' +
                this.heroeInfo?.vestimenta[0].descripcion,
            },
          };

          this.usuario
            .updUsuarioFireStore(this.userInfo.uuid, dataUser)
            .then((res) => {
              this.store.dispatch(stopLoading());
              this.store.dispatch(
                setUser({ info: { ...this.userInfo, ...dataUser } })
              );
              this.store.dispatch(
                setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } })
              );
              this.router.navigate(['/alumno/etapa']);
            })
            .catch((err) => {
              this.store.dispatch(stopLoading());
              this.alerts.modalError(null, 'Sucedio un error:' + err);
            });
        }
        break;
    }
  }

  atras() {
    switch (this.router.url) {
      case '/alumno/mochila-don':
        const etapaInfo = this.etapaService.obtenerCodigoEtapa(this.router.url);
        this.router.navigate(['/alumno/etapa', etapaInfo.codigo]);
        break;
      case '/alumno/mochila-hab':
        this.router.navigate(['/alumno/mochila-don']);
        break;
      case '/alumno/mochila-herr':
        this.router.navigate(['/alumno/mochila-hab']);
        break;
    }
  }

  //Modal
  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }
}
