import { createAction, props } from '@ngrx/store';
import { IDetalleSuperHeroe, IUser } from './../../interfaces/IUser';


export const setUser = createAction('[Auth], setUser', props<{ info: IUser }>());
export const setIsTheLastStep = createAction('[User], isTheLastStep', props<{ isTheLastStep: boolean }>());
export const unSetUser = createAction('[Auth], Un SetUser');
export const setHeroe = createAction('[Heroe], setHeroe', props<{ heroe: IDetalleSuperHeroe }>());
export const clearHeroe = createAction('[Heroe], clearHeroe');
export const setToken = createAction('[Auth Token] Set Token', props<{ tokenProps: string }>());
export const clearToken = createAction('[Auth Token] Clear Token');