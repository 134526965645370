import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.scss'],
})
export class MisDatosComponent implements OnInit {
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;
  userInfo: IUser;
  isLoading: boolean;
  isTheLastStep: boolean = false;

  registroForm: FormGroup;
  gradosColegio = GlobalConstants.GRADOS_COLEGIO;

  constructor(
    private usuario: UserService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private alerts: AlertsService,
    private router: Router
  ) {
    this.registroForm = formBuilder.group({
      nombres: ['', [Validators.required, Validators.minLength(2)]],
      apellidoPaterno: ['', [Validators.required, Validators.minLength(2)]],
      apellidoMaterno: ['', [Validators.required, Validators.minLength(2)]],
      celular: [
        '',
        {
          validators: [Validators.required, Validators.minLength(9)],
          updateOn: 'blur',
        },
      ],
      telefono: ['', [Validators.minLength(7)]],
      colegio: ['', [Validators.required, Validators.minLength(5)]],
      gradoACursar: ['', [Validators.required, Validators.minLength(1)]],
      distritoColegio: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
    this.registroForm.enable();
    this.store.select('ui').subscribe((state) => {
      this.isLoading = state.isLoading;
    });
    this.store.select('user').subscribe((state) => {
      this.userInfo = state.info;
      this.isTheLastStep = state.isTheLastStep;
      if (this.userInfo) {
        this.setValoresInicialesForm(this.userInfo);
      }
    });
  }

  guardarEdicion() {
    if (!this.registroForm.valid) {
      this.alerts.modalAlert(
        'Campos Incompletos',
        'Debe completar todos los campos para poder continuar'
      );
      return;
    }

    this.store.dispatch(isLoading());
    this.registroForm.disable();

    const userData = {
      ...this.registroForm.value,
      registroDatos: true,
    };
    this.usuario
      .updUsuarioFireStore(this.userInfo.uuid, userData)
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(
          setUser({
            info: {
              ...this.userInfo,
              ...userData,
            },
          })
        );
        //this.habilitarEdicion = false;
        this.alerts.modalInfo('Información Actualizada', null).then((res) => {
          if (res.value) {
            if (this.isTheLastStep) {
              this.router.navigate(['/alumno/resultado']);
            } else {
              this.router.navigate(['/alumno/home']);
            }
          }
        });
      })
      .catch((err) => {
        this.store.dispatch(stopLoading());
        this.registroForm.enable();
        this.alerts.modalError(
          null,
          `Sucedio un problema del tipo: ${err.message}`
        );
      });
  }

  atras() {
    if (this.isTheLastStep) {
      this.router.navigate(['/alumno/mi-nombre']);
    } else {
      this.router.navigate(['/alumno/home']);
    }
  }

  setValoresInicialesForm(user: IUser) {
    this.registroForm.patchValue({
      ...user,
    });
  }

  addActivo(wrap: HTMLElement) {
    wrap.classList.add('is-focused');
  }
  remActivo(wrap: HTMLElement) {
    if (this.registroForm.get(wrap.children[1].id).value) {
      return;
    }
    wrap.classList.remove('is-focused');
  }
}
