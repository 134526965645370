<div class="mis-datos">
  <div class="circulo-lineas"></div>
  <div class="circulo"></div>

  <div
    class="container-fluid full-vh animate__animated animate__faster animate__zoomIn"
  >
    <div
      class="row justify-content-between div-superior position-relative z-index-front"
    >
      <div
        (click)="atras()"
        class="btn-back-yellow-only pointer"
        *ngIf="!isTheLastStep"
      >
        <img src="assets/images/iconos/flecha-atras.png" />
        <span>Atrás</span>
      </div>
      <img src="assets/images/logos/{{ logo }}" class="logo" />
    </div>
    <div class="row div-inferior">
      <div
        class="align-items-center col-12 col-md-5 d-flex justify-content-center"
      >
        <div class="row text-center">
          <h1 class="title-text-white py-4 col-12">Mis Datos</h1>
        </div>
      </div>
      <div class="col-12 col-md-5 p-5">
        <form [formGroup]="registroForm" class="h-100 mi-form-opacity p-lg-4">
          <div class="px-4 h-100">
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('nombres').value != ''"
              #formNombres
            >
              <!-- manually specified -->
              <label for="nombres" class="bmd-label-floating">Nombres *</label>
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="nombres"
                formControlName="nombres"
                (focus)="addActivo(formNombres)"
                (blur)="remActivo(formNombres)"
              />
              <!-- <small *ngIf="campoNoValido('nombres')">Este campo es obligatorio</small> -->
              <control-messages
                [control]="registroForm.get('nombres')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('apellidoPaterno').value != ''
              "
              #formApePaterno
            >
              <!-- manually specified -->
              <label for="apellidoPaterno" class="bmd-label-floating"
                >Apellido paterno *</label
              >
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="apellidoPaterno"
                formControlName="apellidoPaterno"
                (focus)="addActivo(formApePaterno)"
                (blur)="remActivo(formApePaterno)"
              />
              <control-messages
                [control]="registroForm.get('apellidoPaterno')"
              ></control-messages>
            </div>
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('apellidoMaterno').value != ''
              "
              #formApeMaterno
            >
              <!-- manually specified -->
              <label for="apellidoMaterno" class="bmd-label-floating"
                >Apellido materno *</label
              >
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="apellidoMaterno"
                formControlName="apellidoMaterno"
                (focus)="addActivo(formApeMaterno)"
                (blur)="remActivo(formApeMaterno)"
              />
              <control-messages
                [control]="registroForm.get('apellidoMaterno')"
              ></control-messages>
            </div>
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('celular').value != ''"
              #formCelular
            >
              <!-- manually specified -->
              <label for="celular" class="bmd-label-floating">Celular *</label>
              <input
                maxlength="9"
                autoComplete="off"
                type="text"
                OnlyNumber="true"
                class="form-control"
                id="celular"
                formControlName="celular"
                (focus)="addActivo(formCelular)"
                (blur)="remActivo(formCelular)"
              />
              <control-messages
                [control]="registroForm.get('celular')"
              ></control-messages>
            </div>
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('telefono').value != ''"
              #formTelefono
            >
              <!-- manually specified -->
              <label for="telefono" class="bmd-label-floating"
                >Teléfono fijo
              </label>
              <input
                maxlength="7"
                autoComplete="off"
                type="text"
                OnlyNumber="true"
                class="form-control"
                id="telefono"
                formControlName="telefono"
                (focus)="addActivo(formTelefono)"
                (blur)="remActivo(formTelefono)"
              />
              <control-messages
                [control]="registroForm.get('telefono')"
              ></control-messages>
            </div>
            <div class="form-group bmd-form-group is-focused" #formGradoACursar>
              <!-- manually specified -->
              <label for="gradoACursar" class="bmd-label-floating"
                >¿Qué grado cursas? *</label
              >
              <select
                class="form-control control-reset-b4"
                id="gradoACursar"
                formControlName="gradoACursar"
                style="
                  border: none !important;
                  border-bottom: 2px solid #e3c0d2 !important;
                  background-image: none;
                "
              >
                <option value="" class="text-dark">Seleccione</option>
                <option
                  *ngFor="let grado of gradosColegio"
                  [ngValue]="grado"
                  class="text-dark"
                >
                  {{ grado }}
                </option>
              </select>
              <control-messages
                [control]="registroForm.get('gradoACursar')"
              ></control-messages>
            </div>
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('colegio').value != ''"
              #formColegio
            >
              <!-- manually specified -->
              <label for="colegio" class="bmd-label-floating"
                >¿En qué colegio estás? *</label
              >
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="colegio"
                formControlName="colegio"
                (focus)="addActivo(formColegio)"
                (blur)="remActivo(formColegio)"
              />
              <control-messages
                [control]="registroForm.get('colegio')"
              ></control-messages>
            </div>
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('distritoColegio').value != ''
              "
              #formDistritoColegio
            >
              <!-- manually specified -->
              <label for="distritoColegio" class="bmd-label-floating"
                >¿En qué distrito está tu colegio? *</label
              >
              <input
                type="text"
                class="form-control"
                id="distritoColegio"
                formControlName="distritoColegio"
                (focus)="addActivo(formDistritoColegio)"
                (blur)="remActivo(formDistritoColegio)"
              />
              <control-messages
                [control]="registroForm.get('distritoColegio')"
              ></control-messages>
            </div>
          </div>
        </form>
      </div>
      <div
        class="align-items-lg-end align-items-md-center col-12 col-md-2 d-flex justify-content-center py-lg-5"
      >
        <div class="my-3">
          <button
            [disabled]="isLoading"
            (click)="guardarEdicion()"
            type="submit"
            class="border-light btn-primary-toulouse text-white py-3 d-flex"
          >
            <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i>
            <div>{{ isLoading ? "Espere..." : "Finalizar" }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
