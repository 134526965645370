import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAvatar } from '../interfaces/IAvatar';
import { IEtapas } from '../interfaces/IEtapas';
import { IMensajesPopup } from '../interfaces/IMensajesPopup';
import { IDetalleSuperHeroe } from '../interfaces/IUser';
import { setHeroe, setUser } from '../store/actions/user.action';
import { AppState } from '../store/app.store';
import { GlobalConstants } from './../global/GlobalConstants';
import { IMensajesEntrenadora } from './../interfaces/IMensajesEntrenadora';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EtapaService {
  private mensajesPopup: IMensajesPopup[] = GlobalConstants.MENSAJES_POPUP;
  private mensajesEntrenadora: IMensajesEntrenadora[] = GlobalConstants.MENSAJES_ENTRENADORA;
  private listaEtapas: IEtapas[] = GlobalConstants.PANTALLA_ETAPA;
  private listaPoderes: IAvatar[] = GlobalConstants.PODERES;
  private listaTipoCuerpo: IAvatar[] = GlobalConstants.TIPO_CUERPO;
  private listaPecheras: IAvatar[] = GlobalConstants.PECHERAS;

  constructor(
    private fireStore: AngularFirestore,
    private usuario: UserService,
    private auth: AuthService,
    private router: Router,
    private store: Store<AppState>

  ) {
  }

  cargarCarreras(poder?, casco?) {
    return this.fireStore
      .collection('carreras').get().toPromise();
    /*.collection('carreras', ref =>
      ref.where(`poderes.${poder}`, '==', true)
        //.where(`CDO.${problemaCDO}`, '==', true)
        .where(`cascos.${casco}`, '==', true)
        .where('activo', '==', true)
    ).get().toPromise();*/
  }



  async crearTest(userUUID: string) {
    const infoTest: IDetalleSuperHeroe = {
      misCarreras: [],
      paginaActual: '',
      nick: '',
      proposito: '',
      tipoCuerpo: [],
      casco: [],
      calzado: [],
      vestimenta: [],
      dones: [],
      habilidades: [],
      herramientas: [],
      poder: [],
      CDO: []
    };
    const miTest = await this.fireStore.doc(`usuarios/${userUUID}`).collection('misTests').add(infoTest);
    const updUser = await this.usuario.updUsuarioFireStore(userUUID, {
      flagTrackingTest: {
        uuidTest: miTest.id,
        uuidTestFlag: miTest.id,
        lastPath: '/alumno/mi-cuerpo',
        etapasPath: '/alumno/home'//Seteamos el home como etapaspath pues recién se creará el test
      }
    });
    const misDatos = await this.fireStore.doc(`usuarios/${userUUID}`).get().toPromise();
    this.store.dispatch(setHeroe({ heroe: infoTest })); //heroe al store
    this.store.dispatch(setUser({ info: misDatos.data() }));//info actualizada del store

    return updUser;
  }
  //Actualizar contneido del test poderes,casco,calzado,etc
  actualizarTest(uuid: string, testId: string, infoTest: IDetalleSuperHeroe) {
    return this.fireStore.doc(`usuarios/${uuid}/misTests/${testId}`).update(infoTest);
  }
  //Filtra los mensajes de ayuda en base al path actual
  cargarMensajesPopup(path: string) {
    return this.mensajesPopup.filter(res => res.path === path)[0];
  }
  //Muestra los mensajes de la entrenadora en base al path actual
  cargarMensajesEntrenadora(path: string) {
    return this.mensajesEntrenadora.filter(res => res.path === path)[0];
  }
  //Muestra la ruta anterior, siguiente, titulo y detalle de una etapa, esto solo aplica para la pantalla de Etapas
  mapeoDeRutasyMensajes(ultimoPath: string) {
    return this.listaEtapas.filter(res => res.pathPrevio === ultimoPath)[0];
  }
  //Obtiene el código de una etpaa en base a la ruta siguiente
  obtenerCodigoEtapa(pathActual: string) {
    return this.listaEtapas.filter(res => res.pathSiguiente === pathActual)[0];
  }
  obtenerEtapaPorCodigo(cod: string) {
    return this.listaEtapas.filter(res => res.codigo === cod)[0];
  }
  obtenerPersonaje(folder, foto) {
    return `assets/images/${folder}/${foto}.svg`;
  }
}