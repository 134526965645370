import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/global/GlobalConstants';

@Component({
  selector: 'app-boton-ayuda',
  templateUrl: './boton-ayuda.component.html',
  styleUrls: ['./boton-ayuda.component.scss']
})
export class BotonAyudaComponent implements OnInit {
  iconoAyuda = GlobalConstants.ICONO_AYUDA;
  @Output() abrirAyuda = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  _abrirAyuda() {
    this.abrirAyuda.emit(true);
  }
}
