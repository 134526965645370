import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { IUser } from '../interfaces/IUser';
import { FileItem } from '../models/FileItem';
@Injectable({
  providedIn: 'root'
})
export class CargarImagenService {
  taskStorage: AngularFireUploadTask;
  constructor(
    private fireStore: AngularFirestore,
    private storage: AngularFireStorage
  ) { }



  private actualizarURLPerfilUsuario(urlImagen: string, usuarioId: string) {
    return this.fireStore.doc(`usuarios/${usuarioId}`).set({
      fotoPerfil: urlImagen
    }, { merge: true });
  }

  cargarImagenAFirebase(imagen: FileItem, usuario: IUser) {
    const path = `fotosPerfil/usuario_${usuario.uuid}`;
    const ref = this.storage.ref(path);
    this.taskStorage = this.storage.upload(path, imagen.archivo);
    this.taskStorage.percentageChanges().subscribe(res => {
      imagen.progreso = res;
    });
    this.taskStorage.snapshotChanges().subscribe(
      async (snapshot) => {
        if (snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes) {
        } else {
          const urlfotocargada = await ref.getDownloadURL().toPromise();
          imagen.url = urlfotocargada;
          await this.actualizarURLPerfilUsuario(urlfotocargada, usuario.uuid);
        }
      }
    );
  }

  mostrarImagenPrevia(imagen: FileItem) {
    const imagenPrevia = imagen.archivo;
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        return res({ data: reader.result, name: imagenPrevia.name, size: imagenPrevia.size, type: imagenPrevia.type });
      }
      reader.readAsDataURL(imagenPrevia);
    });
  }
}