<div class="casco" id="resultado">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 text-right otrosDevices div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>
      <div
        class="avatar-container col-12 col-md-12 div-contenido d-flex flex-wrap px-3"
      >
        <div
          class="left-title-container align-items-center col-12 col-md-4 d-flex justify-content-center my-2 flex-width-100"
        >
          <h1 class="title-text-white text-center">Ponte un casco</h1>
        </div>

        <div class="col-12 m-0 row div-detalle-responsive">
          <div>
            <h4>{{ cascoElegido ? cascoElegido.titulo : "" }}</h4>
          </div>
        </div>
        <div class="descripcion-responsive d-none col-12 text-white mt-2 mb-3">
          <p>{{ cascoElegido?.descripcion }}</p>
        </div>

        <div class="col-md-8 col-12 flex-width-100">
          <div class="row justify-content-center">
            <div class="col-6 col-md-5 d-flex opciones row flex-width-100">
              <div
                class="align-items-center col-6 col-md-6 d-flex justify-content-center py-2"
                *ngFor="let casco of cascos; let index = index"
              >
                <div
                  class="casco-{{ index }} casco-div"
                  [class.elegido]="casco.codigo === cascoElegido?.codigo"
                  (click)="elegirCasco(index, casco)"
                >
                  <img src="assets/images/cascos/{{ casco.foto }}" />
                  <label>{{ casco.titulo }}</label>
                </div>
              </div>
            </div>
            <div
              class="align-items-center col-6 col-md-3 d-flex personaje justify-content-center"
            >
              <div class="position-relative">
                <img
                  *ngIf="cascoElegido"
                  class="avatar-elegido {{ cascoElegido.codigo }}-puesto"
                  src="assets/images/cascos/{{ cascoElegido?.foto }}"
                  #avatarElegido
                />
                <img *ngIf="cabeza" class="avatar" [src]="cabeza" />
              </div>
            </div>
            <div
              class="col-12 col-md-4 d-flex detalle-opcion flex-column justify-content-end"
            >
              <div class="nombre otrosDevices">{{ cascoElegido?.titulo }}</div>
              <div class="detalle otrosDevices">
                <img
                  *ngIf="cascoElegido"
                  src="assets/images/cascos/{{ cascoElegido?.foto }}"
                />
                <label>{{ cascoElegido?.descripcion }}</label>
              </div>
              <button
                [disabled]="isLoading"
                type="button"
                class="btn-primary-toulouse mt-4 w-100 d-flex justify-content-center"
                (click)="siguiente()"
              >
                <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                ><span>{{ isLoading ? "Espere..." : "Siguiente" }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="60"
      [totalBars]="6"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
