<div class="admin_form admin">
  <div class="circulo-lineas"></div>
  <div class="circulo"></div>

  <div
    class="container-fluid full-vh animate__animated animate__faster animate__zoomIn"
  >
    <div class="row justify-content-between div-superior position-relative">
      <a [routerLink]="['/admin']" class="btn-back-yellow-only">
        <img src="assets/images/iconos/flecha-atras.png" />
        <span>Atrás</span>
      </a>
      <img src="assets/images/logos/{{ logo }}" class="logo" />
    </div>

    <div class="row mx-0 align-items-center">
      <div class="col-12">
        <h1 class="title-text-white text-center">Módulo de descargas</h1>
      </div>
      <div class="col-md-10 col-12 mt-4 mb-md-4 mb-0 form-inline">
        <div class="d-flex pl-lg-3 pl-md-3 row">
          <div
            class="col-md-2 col-sm-2 col-6 form-group bmd-form-group mr-md-2 pl-0"
          >
            <label for="SelectAnho" class="bmd-label-static text-white"
              >Año:</label
            >
            <select
              class="form-control"
              id="SelectAnho"
              [(ngModel)]="anhoActual"
            >
              <option
                *ngFor="let anho of anhos"
                [value]="anho"
                [selected]="anho == anhoActual ? true : false"
              >
                {{ anho }}
              </option>
            </select>
          </div>
          <div
            class="col-md-3 col-sm-3 col-6 mr-md-2 mr-0 form-group bmd-form-group pl-0"
          >
            <label for="SelectMes" class="bmd-label-static text-white"
              >Mes:</label
            >
            <select class="form-control" id="SelectMes" [(ngModel)]="mesActual">
              <option
                *ngFor="let mes of meses"
                [value]="mes"
                [selected]="mes == mesActual ? true : false"
              >
                {{ mes }}
              </option>
            </select>
          </div>
          <div
            class="col-md-4 col-sm-4 col-8 pl-md-auto pl-0 form-group bmd-form-group mr-md-2 mr-0"
          >
            <label for="TextBuscar" class="bmd-label-static text-white"
              >Buscar:</label
            >
            <input
              autoComplete="off"
              type="text"
              class="form-control"
              id="TextBuscar"
              [(ngModel)]="buscarValue"
              (keyup.enter)="buscarDescargas()"
            />
          </div>
          <div
            class="col-md-1 col-sm-1 col-1 pl-md-auto pl-0 form-group bmd-form-group mr-md-2 mr-sm-1 mr-3"
          >
            <button
              type="submit"
              class="btn btn-primary btn-raised"
              (click)="buscarDescargas()"
              data-toggle="tooltip"
              data-placement="top"
              title="Buscar"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div
            class="col-md-1 col-sm-1 col-1 ml-md-0 ml-3 pl-md-auto pl-0 form-group bmd-form-group"
          >
            <button
              type="submit"
              class="btn btn-secondary btn-raised"
              (click)="limpiar()"
              data-toggle="tooltip"
              data-placement="top"
              title="Limpiar búsqueda"
            >
              <i class="fas fa-eraser"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-12 text-right mb-md-0 mb-4">
        <div class="row">
          <div class="col-12 form-group bmd-form-group">
            <button
              type="submit"
              class="btn btn-success btn-raised w-100"
              (click)="exportar()"
              [disabled]="btnExportar"
            >
              <i class="fas fa-file-excel"></i> Excel
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="descargas-tabla">
          <div class="text-center" *ngIf="procesando">
            <i class="fas fa-spinner fa-spin mr-1"></i> Procesando...
          </div>
          <table
            [hidden]="procesando"
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border hover"
          >
            <thead>
              <tr>
                <!-- <th>Ver</th> -->
                <th>N°</th>
                <th>Estado</th>
                <th>Fecha de creación</th>
                <th>Nombres</th>
                <th>Apellido paterno</th>
                <th>Apellido materno</th>
                <th>Género</th>
                <th>Celular</th>
                <th>Teléfono</th>
                <th>Correo</th>
                <th>Colegio</th>
                <th>Distrito del colegio</th>
                <th>Grado</th>
                <th>Carreras</th>
                <th>Propósito</th>
                <th>Calzado</th>
                <th>Don</th>
                <th>Tipo de Cuerpo</th>
                <th>Hábilidades</th>
                <th>Casco</th>
                <th>Nick</th>
                <th>Poder</th>
                <th>Herramientas</th>
                <th>ODS</th>
                <th>Vestimenta</th>
                <th>Fecha Fin Test</th>
                <th>Total Carreras Toulouse</th>
                <th>Carreras Tolouse</th>
                <th>utm_campaign</th>
                <th>utm_content</th>
                <th>utm_medium</th>
                <th>utm_source</th>
                <th>utm_term</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let alumno of alumnos; let i = index">
                <!--                                 <td>
                                    <div class="tls-lupa pointer" data-toggle="modal" data-target="#detalleAlumno">
                                        <i class="fas fa-search"></i>
                                    </div>
                                </td> -->
                <td>{{ i + 1 }}</td>
                <td>{{ alumno.estado }}</td>
                <td>{{ alumno.fechaCreacion }}</td>
                <td>{{ alumno.nombres }}</td>
                <td>{{ alumno.apellidoPaterno }}</td>
                <td>{{ alumno.apellidoMaterno }}</td>
                <td>{{ alumno.genero }}</td>
                <td>{{ alumno.celular }}</td>
                <td>{{ alumno.telefono }}</td>
                <td>{{ alumno.correo }}</td>
                <td>{{ alumno.colegio }}</td>
                <td>{{ alumno.distritoColegio }}</td>
                <td>{{ alumno.gradoACursar }}</td>
                <td>{{ alumno.misCarreras.join(", ") }}</td>
                <td>{{ alumno.proposito }}</td>
                <td>{{ alumno.calzado }}</td>
                <td>{{ alumno.don }}</td>
                <td>{{ alumno.tipoCuerpo }}</td>
                <td>{{ alumno.habilidades }}</td>
                <td>{{ alumno.casco }}</td>
                <td>{{ alumno.nick }}</td>
                <td>{{ alumno.poder }}</td>
                <td>{{ alumno.herramientas }}</td>
                <td>{{ alumno.CDO }}</td>
                <td>{{ alumno.vestimenta }}</td>
                <td>{{ alumno.fechFinTest }}</td>
                <td>{{ alumno.totalCarrerasToulouse }}</td>
                <td>{{ alumno.carrerasToulouse }}</td>
                <td>{{ alumno.utm_campaign }}</td>
                <td>{{ alumno.utm_content }}</td>
                <td>{{ alumno.utm_medium }}</td>
                <td>{{ alumno.utm_source }}</td>
                <td>{{ alumno.utm_term }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="detalleAlumno"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Información adicional
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 form-group bmd-form-group">
            <label>Año: 2020</label>
          </div>
          <div class="col-12 form-group bmd-form-group">
            <label>Mes: Agosto</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
