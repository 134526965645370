import { IAvatar } from '../interfaces/IAvatar';
import { IEtapas } from '../interfaces/IEtapas';
import { IMensajesEntrenadora } from '../interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from '../interfaces/IMensajesPopup';

export class GlobalConstants {
  //endpoints
  public static admin = {
    descargas: 'https://us-central1-ms-tls-prd.cloudfunctions.net/listarUsuariosFromDB',
    alumnosSinCarreras: 'https://us-central1-ms-tls-prd.cloudfunctions.net/listarUsuariosSinCarrerasFromDB',
    editarAlumnosSinCarrera: 'https://us-central1-ms-tls-prd.cloudfunctions.net/actualizarCarrerasAlumnoPorId',
    carreras: 'https://us-central1-ms-tls-prd.cloudfunctions.net/listarCarrerasFromDB',
    agregarCarrera: 'https://us-central1-ms-tls-prd.cloudfunctions.net/agregarCarrerasToDB',
    editarCarrera: 'https://us-central1-ms-tls-prd.cloudfunctions.net/actualizarCarreraPorId',
    eliminarCarrera: 'https://us-central1-ms-tls-prd.cloudfunctions.net/eliminarCarreraId'
  }

  //Meses
  public static MESES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  //grados
  public static GRADOS_COLEGIO = ['3ro de secundaria', '4to de secundaria', '5to de secundaria', 'Egresado del colegio'];
  //endpoints
  public static URL_BASE = 'https://us-central1-ms-tls-prd.cloudfunctions.net';
  //imagenes
  public static LOGO_TOULOUSE_ROJO_BLANCO = 'logo_redwhite.svg';
  public static LOGO_TOULOUSE_AMARILLO_BLANCO = 'logo-yellowwhite.svg';
  public static ICONO_AYUDA = 'bateria-blanca.svg';
  public static ICONO_AYUDA_POP = 'bateria-fucsia.svg';




  //Mensaje del home
  public static HOME_MENSAJE = `
  <p>¡Hola! Soy la entrenadora de superhéroes Lau Trecna, pero me puedes llamar Lau.<br/><br/>
  Debes saber que todos los superhéroes tienen un punto de quiebre en su vida: se sienten perdidos, sin saber quiénes son ni cuál es su lugar... Escoger una carrera se siente un poco así ¿verdad?<br/><br/>
  Hasta que algo pasa, que los ayuda a reconocer su fuerza interior y salvar el mundo. Lo mismo pasará contigo en este viaje, hemos diseñado esta aventura para que descubras tu pasión y tu misión de vida. Yo te acompañaré en todo el recorrido. Estoy lista cuando tú lo estes.</p>
  `;
  public static HOME_MENSAJE_CONTINUACION = `Hola de nuevo, continuemos tu entrenamiento, ¿dónde quieres ir?`;

  //Datos en duro de las etapas
  public static TITULO_ETAPAS = '#ModoSuperHéroe';
  public static PANTALLA_ETAPA: IEtapas[] = [
    {
      codigo: 'ETAPA-1',
      pathPrevio: '/alumno/home',
      pathSiguiente: '/alumno/mi-cuerpo',
      titulo: 'Descubre el superhéroe que vive en ti',
      descripcion: ` No todo está en Google, así que tu primera misión será buscar dentro de ti para encontrarte y reafirmar quién eres. Los siguientes ejercicios los hice para que te descubras y conozcas mejor. ¿Vamos?`
    },
    {
      codigo: 'ETAPA-2',
      pathPrevio: '/alumno/poder',
      pathSiguiente: '/alumno/cuerpo',
      titulo: 'Conócete mejor',
      descripcion: `¡Vamos bien, continuemos! La siguiente misión consistirá en profundizar en tu identidad. Todo superhéroe tiene una identidad que lo define, lo hace único: Superman no tiene la personalidad de Batman ni Batichica la de la Mujer Maravilla. Tu identidad es lo que te hace ser quien eres y la suma de todas esas características te diferenciarán de todos, haciéndote especial. <br/><br/>Los siguientes ejercicios te ayudarán a verte con perspectiva. ¿Estás listo?`
    },
    {
      codigo: 'ETAPA-3',
      pathPrevio: '/alumno/casco',
      pathSiguiente: '/alumno/mochila-don',
      titulo: 'Escaneando Talentos',
      descripcion: `¿Te conté que de niña me encantaba aconsejar a mis amiguitos? mis maestros me hicieron ver que tenía talento para hacer que los demás confíen en mí. Cuando elijas tu carrera, debes pensar en qué cosas eres bueno y te gusta hacer, porque esas habilidades te ayudarán a definir tu profesión.`
    },
    {
      codigo: 'ETAPA-4',
      pathPrevio: '/alumno/mochila-herr',
      pathSiguiente: '/alumno/problemas',
      titulo: 'Misión Propósito',
      descripcion: `Pronto descubriremos  qué guía tu camino. Esto te
            ayudará a tomar decisiones difíciles, como cuando Peter Parker decidió seguir su propósito como Spiderman.<br/><br/>
            Ojo, no hay edad mínima para pensarlo, hay quienes nunca se lo preguntan, y hay quienes la tienen clara desde muy temprana edad. Cada quien lo descubre a su ritmo.`
    },
    {
      codigo: 'ETAPA-5',
      pathPrevio: '/alumno/proposito',
      pathSiguiente: '/alumno/mi-nombre',
      titulo: 'Presentación del héroe',
      descripcion: `Llegó la hora de inspirar al mundo, desde tu posición con tus ideas e ideales, cada uno tiene algo que aportar con su talento, un paso a la vez, porque hasta la acción más pequeña suma para los grandes cambios y esos grandes cambios no son nada sin las pequeñas acciones.`
    }
  ];
  public static MENSAJES_ENTRENADORA: IMensajesEntrenadora[] = [
    {
      path: '/alumno/mi-cuerpo',
      mensaje: `¿Con cuál te identificas? Elige uno y empecemos el entrenamiento.`,
      foto: 'entrenadora-pequenia-1-final.svg'
    },
    {
      path: '/alumno/poder',
      mensaje: `Es momento de empezar a reconocer tus pasiones.<br/><br/>
            De todas las opciones, ¿qué área del saber es la que más te gusta?`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/cuerpo',
      mensaje: `Es momento de elegir la estructura del cuerpo con el que enfrentarás tu súper misión de vida. ¿Qué descripción es la que mejor te representa?`,
      foto: 'entrenadora-pequenia-2-final.svg'
    },
    {
      path: '/alumno/vistete',
      mensaje: `Los siguientes súper trajes describen tu manera de socializar con los demás. ¿Cómo eres en entornos de estudio y con grupos de gente que no conoces?`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/calzate',
      mensaje: `¡Estas zapatillas potencian tu capacidad ejecutora! Elige el calzado que mejor represente tus súper pasos.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/casco',
      mensaje: `¿Recuerdas el casco de Magneto? Estos cascos funcionan muy parecido: potencian la manera en la que abordas los problemas.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/mochila-don',
      mensaje: `Selecciona el elemento que represente mejor eso que te sale de manera natural y crees que puede ser la base para tu carrera profesional. ¡Sólo puedes elegir uno!`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/mochila-hab',
      mensaje: `Tienes muchas habilidades, pero ¿cuáles crees que son importantes para desarrollar en tu carrera profesional?<br/>
            Escoge los dos instrumentos que mejor te representen.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/mochila-herr',
      mensaje: `¿Conoces la leyenda de Excalibur? Era la espada del Rey Arturo. Llegó el momento de eligir las herramientas que te ayudarán a cumplir tus supermisiones.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/problemas',
      mensaje: `¡Superhéroe! Este es un llamado para mejorar el mundo. Elige las 5 causas que más te resuenen.;#¡Es momento de elegir! De estas 5, escoge sólo 3.;#De estás 3 causas ¿Cuál es la que más te inquieta?¿con qué causa te comprometes? Elige 1, mejorar el mundo será tu misión de superhéroe.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/proposito',
      mensaje: `Hola, un consejo, recuerda redactar de manera simple y directa tu propósito, por ejemplo el mío: Mi propósito en la vida es que las personas encuentren su destino para que puedan ser felices haciendo lo que les gusta.<br/><br/>
            Debe ser simple y directo. Preguntarte por qué y para qué quisieras luchar por esa causa te ayudará a enfocarte en tu propósito.`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/mi-nombre',
      mensaje: `Un superhéroe y una superheroína, necesitan un súper-nombre que los acompañe. Piensa en las habilidades que tienes, sé creativo, ¡diviértete!`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/resultado',
      mensaje: `Antes que me olvide, recuerda que queda pendiente una última misión, pero las indicaciones y cómo acceder a ella te las enviaré luego, quédate atento a tu email ¡Nos vemos!`,
      foto: 'entrenadora-pequenia-3-final.svg'
    },
    {
      path: '/alumno/consultar-resultado',
      mensaje: `Antes que me olvide, recuerda que queda pendiente una última misión, pero las indicaciones y cómo acceder a ella te las enviaré luego, quédate atento a tu email ¡Nos vemos!`,
      foto: 'entrenadora-pequenia-3-final.svg'
    }
  ];
  public static MENSAJES_POPUP: IMensajesPopup[] = [
    {
      path: '/alumno/mi-cuerpo',
      mensajeAyuda: {
        titulo: '',
        mensaje: ``
      },
      mensajeDirecto: {
        titulo: '',
        mensaje: ``
      }
    },
    {
      path: '/alumno/poder',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Si tienes dudas, tranqui, no pasa nada. Estas preguntas te ayudarán a elegir tu área de conocimiento preferida.<br/><br/>
                Recuerda, nadie te apura, dedícale el tiempo que haga falta.<br/><br/>
                ¿Cuál es tu asignatura favorita?<br/>
                ¿Qué te resulta más interesante de esas materias?<br/>
                ¿Sobre cuál de esos temas te gustaría saber más?`
      },
      mensajeDirecto: {
        titulo: 'Pasión ven a mí',
        mensaje: `¿Qué es la pasión? Si estás dispuesta/o a realizar un esfuerzo por ello y dedicarle muchas horas sin que se sienta como una carga, entonces puede que eso sea tu pasión.<br/><br/>
                ¿Te gusta comunicar ideas? ¿Contribuir con tu comunidad? ¿Te preocupa el medio ambiente? ¿Te atraen las tecnologías? ¿Entender el comportamiento? ¿Contar historias?<br/><br/>
                ¿Qué te apasiona?`
      }
    },
    {
      path: '/alumno/cuerpo',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Aquí estoy para ayudarte. Si no tienes claro qué tipo de cuerpo es el más adecuado para ti, tranqui, tómate tu tiempo resolviendo estas preguntas. Te sugiero anotar tus respuestas en algún cuaderno.<br/><br/>
                ¿Qué momentos marcaron un antes y después en tu vida? ¿Qué pasaba por tu cabeza en esos momentos?<br/>
                Cuando algo no sale como esperabas, ¿cómo reaccionas?<br/>
                ¿Cuál ha sido la experiencia más difícil de tu vida y qué hiciste al respecto?<br/>
                Piensa en una situación que haya sido un punto de quiebre para ti ¿cómo te recuperaste?`
      },
      mensajeDirecto: {
        titulo: 'Preparado para todo cambio',
        mensaje: `Piénsalo, el mundo está en continua evolución, todo cambia, más rápido o más despacio, todo se transforma: el cambio es una constante. Mientras más rápido nos adaptemos, mejor.
                Probablemente, el trabajo que realices todavía no existe. Y si ya existe, dentro de unos años se hará de una forma aún desconocida.`
      }
    },
    {
      path: '/alumno/vistete',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Aquí estoy para ayudarte con tus dudas. Estas preguntas te ayudarán si no tienes claro qué tipo de traje es el más adecuado para ti. Te recomiendo escribir tu respuesta en un cuaderno.<br/><br/>
                ¿Cómo te relacionas con las personas?<br/>
                ¿Qué significa la familia para ti? ¿Y la amistad?<br/>
                ¿Cómo te relacionas en grupos grandes de personas que no conoces?<br/>
                ¿En qué tipo de entorno te sientes más a gusto?`
      },
      mensajeDirecto: {
        titulo: 'Entendiendo mi forma de ser',
        mensaje: `Una forma de conocernos mejor, es a través de otras miradas; cómo te muestras y cómo interactúas dice mucho de tu mundo interior.<br/><br/>
                ¿Cómo eres con tus amigos? ¿Cómo te comportas frente a desconocidos o cuando recién conoces a alguien? ¿Cómo eres en el colegio? ¿Cómo eres en casa?`
      }
    },
    {
      path: '/alumno/calzate',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `¡Hola!, veamos cómo puedo ayudarte... Te dejo algunas preguntas que te servirán para aclarar qué tipo de zapatillas es el adecuado para ti. Tómate tu tiempo para responderlas.<br/><br/>
                Cuando tienes que hacer una tarea, ¿cómo la abordas? ¿saltas directo a la acción o prefieres tomarte el tiempo en pensar cómo hacerla?<br/><br/>
                ¿Te gusta hacer planes y seguirlos o prefieres improvisar?<br/>
                ¿Te organizas y haces las tareas con tiempo o procrastinas hasta el último momento?`
      },
      mensajeDirecto: {
        titulo: 'Súper acciones ¡Actívense!',
        mensaje: `Somos lo que hacemos, me lo repetía siempre mi maestro.<br/><br/>
                ¿Cómo actúas  bajo presión? ¿Cuando debes trabajar en un  proyecto largo y de muchos pasos... cómo te organizas?<br/><br/>
                Batman era pura estrategia, mientras Robin de improvisar sobre la marcha...<br/><br/>
                ¿Cómo eres tú?`
      }
    },
    {
      path: '/alumno/casco',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Aquí estoy para aclarar tus dudas. Si no tienes claro qué casco elegir, te dejo estas preguntas que pueden ayudarte a decidir.<br/><br/>
                Cuando tienes que tomar una decisión, ¿qué cosas tienes en cuenta?<br/>
                ¿Te gusta pensar en el futuro y hacer planes a largo plazo o prefieres vivir el momento?<br/>
                ¿Cómo funciona tu cabeza cuando tienes que escoger entre diferentes opciones? ¿Qué es lo primero que piensas al momento de elegir?`
      },
      mensajeDirecto: {
        titulo: 'Un problema, muchas miradas',
        mensaje: `Todas las maneras de abordar un problema están bien, cada quien tiene la suya. Lo importante es reconocer cómo lo haces tú para trazar tu propio camino de acción.`
      }
    },
    {
      path: '/alumno/mochila-don',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Hola hola, si no tienes claro qué tipo de instrumento se adecúa más a ti, te dejo estas preguntas que te ayudarán a aclarar tu duda. Tómatelo con calma que nadie te apura.<br/><br/>
                ¿Qué es lo mejor que sabes hacer?<br/>
                ¿Qué haces bien de manera natural? Nadie te lo ha enseñado y no sabes cómo lo aprendiste.<br/>
                ¿Qué cosa haces con mucha facilidad y te sale bien sin mucho esfuerzo?<br/>
                ¿Que es lo que más te gusta hacer en la vida?`
      },
      mensajeDirecto: {
        titulo: 'Don ven a mí',
        mensaje: `Es tiempo de pensar en tu don, eso natural que nadie te enseñó y que tienes desde que naciste. Como el don para volar de Supergirl.`
      }
    },
    {
      path: '/alumno/mochila-hab',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `¿Con dudas?  Aquí tengo algunas preguntas que te ayudarán a elegir el instrumento adecuado para ti.
                Sé honesto contigo. Puedes escribir en un cuaderno si lo necesitas.<br/><br/>
                ¿Qué crees que haces mejor que el resto de personas?<br/>
                ¿Cuáles son tus fortalezas?<br/>
                ¿Qué cosas has aprendido a hacer y te salen con facilidad?<br/>
                ¿Qué otras cosas haces muy bien?<br/>
                ¿Qué actividad hace que se te pase el tiempo volando?<br/>
                ¿Con qué cosas disfrutas más? ¿qué te divierte?<br/>
                ¿Cuál de ellas puedes aplicar en tu desarrollo profesional?`
      },
      mensajeDirecto: {
        titulo: 'Equipando Habilidad',
        mensaje: `Con "habilidad" nos referimos a toda capacidad que has aprendido y desarrollado, que necesita práctica y dedicación, como tocar guitarra, o el poder de escuchar a las personas sin juzgarlas, o hablar asertivamente, etc.`
      }
    },
    {
      path: '/alumno/mochila-herr',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `¡Lau a tus órdenes!
                Contesta estas preguntas si tienes dudas de qué instrumento es el más adecuado para ti.<br/><br/>
                Sin prisa, que es importante tomarte el tiempo y pensar bien las respuestas.<br/><br/>
                ¿Qué habilidad te gustaría aprender?<br/>
                ¿Qué destreza consideras que será importante para tu futuro y deberías desarrollar?<br/>
                ¿En qué te gustaría destacar cuando seas grande?<br/>
                ¿Qué es esa capacidad que te repites: "algún día aprenderé a...", dentro del ámbito profesional?`
      },
      mensajeDirecto: {
        titulo: 'Quiero esos poderes',
        mensaje: `Siempre admiré a los superhéroes voladores, yo no lo hago mal, pero creo que podría mejorar.
                Es bueno identificar qué otras habilidades te gustaría reforzar y pueden ser importantes en nuestro desarrollo profesional, siempre estás a tiempo de aprender algo nuevo.`
      }
    },
    {
      path: '/alumno/problemas',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `
                Estas preguntas te ayudarán a elegir mejor tu causa. Esto va de decidir bien, tómate el tiempo que te haga falta. Puedes escribir tus respuestas o pensarlas.<br/><br/>
                Si pudieras arreglar un problema del mundo como por arte de magia, ¿cuál escogerías?<br/>
                ¿Qué temas son los que más te preocupan?<br/>
                ¿Qué problemas del mundo te inquietan?<br/>
                ¿Dónde crees que podrías generar más impacto?<br/>
                Si un día ganaras un Nobel, ¿en qué categoría y por qué te imaginas que te lo darían?`
      },
      mensajeDirecto: {
        titulo: 'La huella de mis súper poderes',
        mensaje: `Todo superhéroe tiene una gran misión que cumplir, es hora de elegir la tuya. El mundo tiene muchos problemas y tú, con tu talento y habilidades, ayudarás a encontrar las mejores soluciones. El reto es elegir la causa por la que quieres luchar, no tengas miedo por lo grande que suene, una gran carrera siempre empieza con el primer paso... por pequeño que sea.`
      }
    },
    {
      path: '/alumno/proposito',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Piensa en todo el recorrido, todas las preguntas que te has hecho, todas las respuestas que te has dado... ¿qué quieres conseguir? ¿por qué es importante eso para ti?`
      },
      mensajeDirecto: {
        titulo: 'Súper misión aceptada',
        mensaje: `¡Iniciamos la recta final! Ya estás cerca de descubrir tu gran misión en la vida y la carrera que puedes elegir basada en tus pasiones. Tu viaje ya va tomando forma, es momento de construir tu propósito y plasmarlo.`
      }
    },
    {
      path: '/alumno/mi-nombre',
      mensajeAyuda: {
        titulo: '¿Tienes dudas?',
        mensaje: `Igual que durante todo el proceso, la repuesta sólo la tienes tú. Ya sabes quién eres y qué quieres conseguir, ¿cómo quieres presentarte al mundo? Piensa un nombre que te represente, ya sea por tus habilidades, por la causa que persigues o por todo ello.`
      },
      mensajeDirecto: {
        titulo: 'Súper misión cargando',
        mensaje: `Llegó la hora de inspirar al mundo.<br/>
                ¿Verdad que ha sido un gran entrenamiento? Aún te falta un último pasito antes de descubrir tu carrera y tu misión de vida. ¿Estás listo?`
      }
    }
  ];
  //Datos del juego
  public static TIPO_CUERPO: IAvatar[] = [
    {
      codigo: 'tipo-0',
      titulo: 'Cuerpo de goma',
      foto: 'tipo-0.svg',
      descripcion: 'Soy muy flexible, llego a todo, pero vuelvo a mi forma natural en cuanto puedo.',
      hombre: 'hombre-poder-0',
      mujer: 'mujer-poder-0',
    },
    {
      codigo: 'tipo-1',
      titulo: 'Esqueleto de diamantes',
      foto: 'tipo-1.svg',
      descripcion: 'Soy fuerte e inflexible, nada me altera.'
    },
    {
      codigo: 'tipo-2',
      titulo: 'Relleno de agua',
      foto: 'tipo-2.svg',
      descripcion: 'Me adapto a cualquier circunstancia, dejo que el contexto me  moldee.'
    },
    {
      codigo: 'tipo-3',
      titulo: 'Huesos de bambú',
      foto: 'tipo-3.svg',
      descripcion: 'Soy flexible, resistente y me adapto a las circunstancias.'
    }
  ];
  public static CALZADO: IAvatar[] = [
    {
      codigo: 'calzado-0',
      titulo: 'Zapatillas de Usain Bolt',
      foto: 'calzado-0.svg',
      descripcion: 'Me gusta ejecutar, soy veloz, hago mis tareas con rapidez y diligencia.'
    },
    {
      codigo: 'calzado-1',
      titulo: 'Botas propulsoras',
      foto: 'calzado-1.svg',
      descripcion: 'Me interesa explorar, divagar e imaginar antes que resolver.'
    },
    {
      codigo: 'calzado-2',
      titulo: 'Sandalias de ir sobre seguro',
      foto: 'calzado-2.svg',
      descripcion: 'Me gusta tener un plan claro y detallado, sino, no voy.'
    },
    {
      codigo: 'calzado-3',
      titulo: 'Zapatos voladores',
      foto: 'calzado-3.svg',
      descripcion: 'Me gusta tener muchas opciones antes de empezar la tarea, luego no hay quien me pare.'
    },
  ];
  public static PECHERAS: IAvatar[] = [
    {
      codigo: 'pechera-0',
      titulo: 'Uniforme de quarterback',
      foto: 'pechera-0.svg',
      auxFoto: 'pechera-0.svg',
      descripcion: 'Lidero de forma natural, todo el mundo me sigue.'
    },
    {
      codigo: 'pechera-1',
      titulo: 'Polo de oso amoroso',
      foto: 'pechera-1.svg',
      auxFoto: 'pechera-1.svg',
      descripcion: 'Reparto amor y abrazos, todo el mundo me quiere y yo a todo el mundo.'
    },
    {
      codigo: 'pechera-2',
      titulo: 'Enterizo de neón',
      foto: 'pechera-2.svg',
      auxFoto: 'pechera-2.svg',
      descripcion: 'Soy el alma de la fiesta, pura diversión, toda la gente se ríe conmigo.'
    },
    {
      codigo: 'pechera-3',
      titulo: 'Traje de orejas',
      foto: 'pechera-3.svg',
      auxFoto: 'pechera-3.svg',
      descripcion: 'Se me da muy bien escuchar a otros, tengo mucha empatía.'
    },
    {
      codigo: 'pechera-4',
      titulo: 'Capa de invisibilidad',
      foto: 'pechera-4.svg',
      auxFoto: 'pechera-4-visual.svg',
      descripcion: 'Nadie me ve, paso desapercibida/o.'
    },
    {
      codigo: 'pechera-5',
      titulo: 'Pijama',
      foto: 'pechera-5.svg',
      auxFoto: 'pechera-5.svg',
      descripcion: 'No me gusta mucho la gente, me relaciono lo justo y necesario.'
    },
  ];

  public static CASCOS: IAvatar[] = [
    {
      codigo: 'casco-0',
      titulo: 'Tercer ojo',
      foto: 'casco-0.svg',
      descripcion: 'Tengo una gran intuición y me dejo guiar por ella, tomo decisiones según lo que siento en el momento.'
    },
    {
      codigo: 'casco-1',
      titulo: 'Cerebrito',
      foto: 'casco-1.svg',
      descripcion: 'Tengo una memoria infinita, no hay dato que se me olvide, decido con evidencias y data histórica.'
    },
    {
      codigo: 'casco-2',
      titulo: 'Casco magnético',
      foto: 'casco-2.svg',
      descripcion: 'Ordeno y doy estructura a pensamientos complejos y hago conexiones súper rápido.'
    },
    {
      codigo: 'casco-3',
      titulo: 'Calculín',
      foto: 'casco-3.svg',
      descripcion: 'No hay operación  que se me resista, calculo pros y contras de todo.'
    },
    {
      codigo: 'casco-4',
      titulo: 'Sombrero de copa',
      foto: 'casco-4.svg',
      descripcion: 'Tomo decisiones con sensatez y prudencia.'
    },
    {
      codigo: 'casco-5',
      titulo: 'Casco sónico',
      foto: 'casco-5.svg',
      descripcion: 'No pienso mucho las cosas, me lanzo a la acción, me apunto a todo.'
    }];
  public static MOCHILA: IAvatar[] = [
    {
      codigo: 'habilidad-0',
      titulo: 'Balanza',
      foto: 'habilidad-0.svg',
      descripcion: 'Sirve para encontrar siempre la opción más justa, para mediar en el conflicto.'
    },
    {
      codigo: 'habilidad-1',
      titulo: 'Baqueta de Beethoven',
      foto: 'habilidad-1.svg',
      descripcion: 'Sirve para ver el mundo en clave de Sol. Todo es música.'
    },
    {
      codigo: 'habilidad-2',
      titulo: 'Bombilla',
      foto: 'habilidad-2.svg',
      descripcion: 'Sirve para tener ideas ingeniosas cada vez que se ilumina.'
    },
    {
      codigo: 'habilidad-3',
      titulo: 'Dedos mágicos',
      foto: 'habilidad-3.svg',
      descripcion: 'Sirve para ser hábil en cualquier cosa que requiera destreza manual.'
    },
    {
      codigo: 'habilidad-4',
      titulo: 'Destornillador cuántico',
      foto: 'habilidad-4.svg',
      descripcion: 'Sirve para entender asuntos de manera holística, comprender las partes y las secuencias.'
    },
    {
      codigo: 'habilidad-5',
      titulo: 'Escudo de indiferencia',
      foto: 'habilidad-5.svg',
      descripcion: 'Sirve para protegerse de la opinión de los demás sin que te afecte.'
    },
    {
      codigo: 'habilidad-6',
      titulo: 'Escudo oráculo',
      foto: 'habilidad-6.svg',
      descripcion: 'Sirve para imaginar posibles escenarios futuros ante un inconveniente dado.'
    },
    {
      codigo: 'habilidad-7',
      titulo: 'Espada láser',
      foto: 'habilidad-7.svg',
      descripcion: 'Sirve para ver dentro de las personas y encontrar su potencial.'
    },
    {
      codigo: 'habilidad-8',
      titulo: 'Flauta de Hamelin',
      foto: 'habilidad-8.svg',
      descripcion: 'Sirve para atraer a las personas y conseguir que me sigan.'
    },
    {
      codigo: 'habilidad-9',
      titulo: 'Guante sanador',
      foto: 'habilidad-9.svg',
      descripcion: 'Sirve para curar y proteger a quienes lo necesitan.'
    },
    {
      codigo: 'habilidad-10',
      titulo: 'Lengua de Babel',
      foto: 'habilidad-10.svg',
      descripcion: 'Sirve para entender y aprender idiomas con mucha facilidad.'
    },
    {
      codigo: 'habilidad-11',
      titulo: 'Lentes de aumento',
      foto: 'habilidad-11.svg',
      descripcion: 'Sirve para fijarme en los detalles, recopilar información y analizar datos.'
    },
    {
      codigo: 'habilidad-12',
      titulo: 'Linterna',
      foto: 'habilidad-12.svg',
      descripcion: 'Sirve para tomar decisiones con seguridad.'
    },
    {
      codigo: 'habilidad-13',
      titulo: 'Llave de tuercas',
      foto: 'habilidad-13.svg',
      descripcion: 'Sirve para construir las grandes infraestructuras, maquinarias y su funcionamiento.'
    },
    {
      codigo: 'habilidad-14',
      titulo: 'Lupa',
      foto: 'habilidad-14.svg',
      descripcion: 'Sirve para cuestionar conceptos y creencias con pensamiento crítico.'
    },
    {
      codigo: 'habilidad-15',
      titulo: 'Machete',
      foto: 'habilidad-15.svg',
      descripcion: 'Sirve para abrir nuevos caminos que explorar y encontrar nuevas oportunidades.'
    },
    {
      codigo: 'habilidad-16',
      titulo: 'Maletín',
      foto: 'habilidad-16.svg',
      descripcion: 'Sirve para hacer buenas negociaciones y lograr siempre el mejor trato.'
    },
    {
      codigo: 'habilidad-17',
      titulo: 'Martillo',
      foto: 'habilidad-17.svg',
      descripcion: 'Sirve para derribar los obstáculos que se presentan.'
    },
    {
      codigo: 'habilidad-18',
      titulo: 'Megáfono',
      foto: 'habilidad-18.svg',
      descripcion: 'Sirve para comunicar con asertividad y hacer que los demás entiendan mi mensaje.'
    },
    {
      codigo: 'habilidad-19',
      titulo: 'Pincel',
      foto: 'habilidad-19.svg',
      descripcion: 'Sirve para expresarse de manera artística y traducir conceptos en imágenes y piezas bellas.'
    },
    {
      codigo: 'habilidad-20',
      titulo: 'Pluma de literata',
      foto: 'habilidad-20.svg',
      descripcion: 'Sirve para escribir textos hermosos y redactar con eficacia.'
    },
    {
      codigo: 'habilidad-21',
      titulo: 'Poción Bauleo de Harry Potter',
      foto: 'habilidad-21.svg',
      descripcion: 'Sirve para ordenar y poner todo en su lugar de manera automática.'
    },
    {
      codigo: 'habilidad-22',
      titulo: 'Varita mágica',
      foto: 'habilidad-22.svg',
      descripcion: 'Sirve para cumplir los deseos de los demás.'
    },
    {
      codigo: 'habilidad-23',
      titulo: 'Liana de tarzán',
      foto: 'habilidad-23.svg',
      descripcion: 'Sirve para entender a los animales.'
    }
  ];
  public static PODERES: IAvatar[] = [
    {
      codigo: 'poder-0',
      titulo: 'HUMANIDADES',
      descripcion: 'Historia, lenguaje, filosofía, idiomas, etc.',
      foto: 'poder-0.svg',
      auxFoto: 'cinturon_poder-0.svg',
      hombre: 'hombre_poder-0',
      mujer: 'mujer_poder-0',
    },
    {
      codigo: 'poder-1',
      titulo: 'CIENCIAS SOCIALES',
      foto: 'poder-1.svg',
      descripcion: 'Economía, sociología, antropología, comunicación, política, cívica, negocios, etc.',
      auxFoto: 'cinturon_poder-1.svg',
      hombre: 'hombre_poder-1',
      mujer: 'mujer_poder-1',
    },
    {
      codigo: 'poder-2',
      titulo: 'CIENCIAS DE LA NATURALEZA',
      foto: 'poder-2.svg',
      descripcion: 'Biología, medicina, ambientales, geología, etc.',
      auxFoto: 'cinturon_poder-2.svg',
      hombre: 'hombre_poder-2',
      mujer: 'mujer_poder-2',
    },
    {
      codigo: 'poder-3',
      titulo: 'TECNOLOGÍA',
      foto: 'poder-3.svg',
      descripcion: 'Tecnología, informática, telecomunicaciones, robótica, electrónica, etc.',
      auxFoto: 'cinturon_poder-3.svg',
      hombre: 'hombre_poder-3',
      mujer: 'mujer_poder-3',
    },
    {
      codigo: 'poder-4',
      titulo: 'ARTE',
      foto: 'poder-0.svg',
      descripcion: 'Arte, dibujo, música, deportes, etc.',
      auxFoto: 'cinturon_poder-4.svg',
      hombre: 'hombre_poder-4',
      mujer: 'mujer_poder-4',
    },
    {
      codigo: 'poder-5',
      titulo: 'CIENCIAS PURAS',
      foto: 'poder-2.svg',
      descripcion: 'Física, química, matemáticas, etc.',
      auxFoto: 'cinturon_poder-5.svg',
      hombre: 'hombre_poder-5',
      mujer: 'mujer_poder-5',
    },
  ];
  public static PROBLEMAS: IAvatar[] = [
    {
      codigo: 'problema-0',
      titulo: 'Cuidar y <b>proteger a los animales</b> y preservar los recursos derivados de ellos',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-1',
      titulo: 'Erradicar <b>enfermedades</b> y mejorar la salud de las personas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-2',
      titulo: 'Fortalecer las relaciones sociales para <b>mejorar la convivencia</b> de todos los habitantes del planeta',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-3',
      titulo: 'Garantizar la <b>igualdad de derechos</b> y oportunidades para todas las personas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-4',
      titulo: 'Generar <b>redes y alianzas</b> para apoyar el resto de causas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-5',
      titulo: 'Inventar o <b>descubrir soluciones</b> para facilitar la vida de las personas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-6',
      titulo: 'Lograr la <b>paz en el mundo</b>, acabar con las guerras y conflictos armados',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-7',
      titulo: 'Mejorar la <b>calidad de vida</b> de la personas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-8',
      titulo: 'Potenciar el <b>desarrollo verde y sostenible</b> de las ciudades',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-9',
      titulo: 'Preservar la <b>cultura y tradiciones</b> de las minorías del mundo',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-10',
      titulo: 'Proteger los derechos de la <b>infancia</b>',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-11',
      titulo: 'Repartir los <b>recursos naturales</b> de manera equitativa',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-12',
      titulo: 'Romper con el círculo de <b>pobreza</b>',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-13',
      titulo: 'Salvar el planeta, detener el <b>cambio climático</b> y recuperar ecosistemas',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
    {
      codigo: 'problema-14',
      titulo: 'Terminar con el <b>hambre</b> en el mundo',
      foto: '',
      descripcion: '',
      hombre: '',
      mujer: '',
    },
  ];
}

export enum GENEROS {
  HOMBRE = 'HOMBRE',
  MUJER = 'MUJER'
};