import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stage-header',
  templateUrl: './stage-header.component.html',
  styleUrls: ['./stage-header.component.scss'],
})
export class StageHeaderComponent implements OnInit {
  @Input() entrenadora = {
    mensaje: '',
    foto: '',
  };
  @Input() tituloEtapa = '';
  @Input() showHelper = true;
  @Output()
  public goBack = new EventEmitter<MouseEvent>();

  @Output()
  public openHelper = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  public handleOpenHelper(event: MouseEvent) {
    this.openHelper.emit(event);
  }

  public handleGoBack(event: MouseEvent) {
    this.goBack.emit(event);
  }
}
