import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { FileItem } from '../models/FileItem';

@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirective {
  /* @Input() archivoSeleccionado: FileItem; */
  archivoSeleccionado: FileItem;
  @Output() mouseSobre: EventEmitter<boolean> = new EventEmitter();
  @Output() archivoObtenido: EventEmitter<any> = new EventEmitter();
  constructor() { }

  @HostListener('dragover', ['$event'])
  public onDragEnter(event: any) {
    this.mouseSobre.emit(true);
    this._prevenirCompartimientoBrowser(event);
  }
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any) {
    this.mouseSobre.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any) {
    const archivoSoltado = this._obtenerReferenciaArchivoSoltado(event);//Almacenamos en una constante la ref del archivo soltado
    if (!archivoSoltado) { //Si es null o undefined
      return;
    }

    //Caso contrario mapeamos los o el archivo(s) soltado(s)
    this._mapearArchivosSoltados(archivoSoltado.files);
    this._prevenirCompartimientoBrowser(event);
    this.mouseSobre.emit(false);
  }


  private _obtenerReferenciaArchivoSoltado(event: any) {
    //En algunos navegadores la referencia del archivo soltado es mediante el dataTransfer en otro dentro del originalEvent
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _mapearArchivosSoltados(archivosSoltados: FileList) {
    //El FileList no devuelve un array devuelve un objeto con propiedades 0,1,etc que son los archivos soltados
    //{0:File1, 1:File2 ....}

    //En nuestro caso quisieramos asociarlo a nuestro archivoSeleccionado para ello haremos lo siguiente
    //a través de getOwnPropertyNames iterare cada propeidad del objeto para obtener los valores 0,1,2,etc.

    for (const propiedad in Object.getOwnPropertyNames(archivosSoltados)) {
      //propiedad devolverá 0,1,2,etc que son las porpiedades que guarda archivosoltados
      const primerArchivo = archivosSoltados[propiedad];
      if (this._archivoPuedeSerCargado(primerArchivo)) {
        //Le indicamos a nuestra variable que almacene la referencia de nuestro modelo FIleItem
        this.archivoSeleccionado = new FileItem(primerArchivo);
        this.archivoObtenido.emit(this.archivoSeleccionado);
      } else {
        this.archivoObtenido.emit(null);
      }
    }
  }

  //Con este metodo evitamos que la página tenga un defecto normal como recargar y a la vez detenemos la propagación
  //que son acciones que se ejecutan cuando algo cambia en la página luego de que el usuario realiza una acción
  //como drag and drop evitando que la imagen se abrá en otra ventana
  private _prevenirCompartimientoBrowser(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  //Creamos validaciones para saber si es un archivo de imagen valido y es menor a 1 mega
  private _archivoPuedeSerCargado(archivo: File): boolean {
    if (!this._esMayorA1Mega(archivo.size) && this._esUnaImagen(archivo.type)) {
      return true;
    }
    return false;
  }

  private _esUnaImagen(tipoFile: string): boolean {
    const formatosValidos = ['image/jpg', 'image/png', 'image/jpeg'];

    return (tipoFile === '' || tipoFile === undefined || formatosValidos.indexOf(tipoFile) < 0) ? false : tipoFile.startsWith('image');
  }
  private _esMayorA1Mega(archivoSize: number): boolean {
    //La clase File retorna bytes entonces para pasarlo a megas debemos dividirlo 2 veces entre 1024
    //porque el orden es así bytes -> kylobytes -> megabytes
    const pesoFileAMegas = (archivoSize / 1024 / 1024);
    //Si es mayor a 1mega
    return (pesoFileAMegas > 1) ? true : false;
  }
}
