<div class="poder">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (openHelper)="mostrarAyuda()"
      (goBack)="atras()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end avatar-container position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="mt-md-2 helper-web"
      ></app-boton-ayuda>

      <div
        class="col-12 col-md-12 d-flex flex-wrap justify-content-end px-3"
      >
        <div
          class="avatar-container__left align-items-center col-12 col-md-4 d-flex justify-content-center"
        >
          <h1 class="title-text-white">Elige tu primer poder</h1>
        </div>

        <div class="col-12 power-detail-mobile row p-0 m-0">
          <div>
            <p>{{ poderElegido ? poderElegido.descripcion : "" }}</p>
          </div>
        </div>

        <div class="col-md-7 col-12 avatar-container__right">
          <div class="row mt-2">
            <div
              class="items-left align-items-end col-4 d-flex flex-column justify-content-center"
            >
              <div
                class="poder-0 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_0
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_0, 'IZQ', poderes[0])"
                  >
                    <img src="assets/images/poderes/{{ poderes[0].foto }}" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[0].titulo }}</label>
                  </div>
                </div>
                <div
                  class="z-index-1 d-flex align-items-center power-item-container__detalle power-item-container__detalle-left"
                >
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_0, 'IZQ', poderes[0])"
                  >
                    <img
                      src="assets/images/iconos/flecha-atras.png"
                      height="20"
                    />
                  </div>
                  <div
                    class="contenido power-left-content animate__animated d-none"
                  >
                    <p>{{ poderes[0].descripcion }}</p>
                  </div>
                </div>
              </div>
              <div
                class="poder-1 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_1
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_1, 'IZQ', poderes[1])"
                  >
                    <img src="assets/images/poderes/poder-1.svg" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[1].titulo }}</label>
                  </div>
                </div>

                <div
                  class="z-index-1 d-flex align-items-center power-item-container__detalle power-item-container__detalle-left"
                >
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_1, 'IZQ', poderes[1])"
                  >
                    <img
                      src="assets/images/iconos/flecha-atras.png"
                      height="20"
                    />
                  </div>
                  <div
                    class="contenido power-left-content animate__animated d-none"
                  >
                    <p>{{ poderes[1].descripcion }}</p>
                  </div>
                </div>
              </div>
              <div
                class="poder-2 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_2
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_2, 'IZQ', poderes[2])"
                  >
                    <img src="assets/images/poderes/poder-2.svg" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[2].titulo }}</label>
                  </div>
                </div>
                <div
                  class="z-index-1 d-flex align-items-center power-item-container__detalle power-item-container__detalle-left"
                >
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_2, 'IZQ', poderes[2])"
                  >
                    <img
                      src="assets/images/iconos/flecha-atras.png"
                      height="20"
                    />
                  </div>
                  <div
                    class="contenido power-left-content animate__animated d-none"
                  >
                    <p>{{ poderes[2].descripcion }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 col-md-3 d-flex justify-content-center avatar">
              <img *ngIf="userInfo" class="avatar" [src]="personaje" />
              <img
                *ngIf="cinturon"
                class="cinturon-seleccionado position-absolute"
                src="assets/images/poderes/{{ cinturon }}"
              />
            </div>
            <div
              class="items-right align-items-start col-4 d-flex flex-column justify-content-center"
            >
              <div
                class="poder-3 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_3
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_3, 'DER', poderes[3])"
                  >
                    <img src="assets/images/poderes/poder-3.svg" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[3].titulo }}</label>
                  </div>
                </div>
                <div
                  class="align-items-center d-flex power-item-container__detalle power-item-container__detalle-right justify-content-end z-index-1"
                >
                  <div
                    class="contenido power-content-right animate__animated d-none"
                  >
                    <p>{{ poderes[3].descripcion }}</p>
                  </div>
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_3, 'DER', poderes[3])"
                  >
                    <img
                      src="assets/images/iconos/flecha-adelante.png"
                      height="20"
                    />
                  </div>
                </div>
              </div>
              <div
                class="poder-4 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_4
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_4, 'DER', poderes[4])"
                  >
                    <img src="assets/images/poderes/poder-4.svg" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[4].titulo }}</label>
                  </div>
                </div>
                <div
                  class="align-items-center d-flex power-item-container__detalle power-item-container__detalle-right justify-content-end z-index-1"
                >
                  <div
                    class="contenido power-content-right animate__animated d-none"
                  >
                    <p>{{ poderes[4].descripcion }}</p>
                  </div>
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_4, 'DER', poderes[4])"
                  >
                    <img
                      src="assets/images/iconos/flecha-adelante.png"
                      height="20"
                    />
                  </div>
                </div>
              </div>
              <div
                class="poder-5 align-items-center d-flex power-item-container justify-content-center py-2"
                #poder_5
              >
                <div>
                  <div
                    class="cinturon-disponible z-index-2"
                    (click)="iconClick(poder_5, 'DER', poderes[5])"
                  >
                    <img src="assets/images/poderes/poder-5.svg" />
                  </div>
                  <div class="power-item-container__title">
                    <label>{{ poderes[5].titulo }}</label>
                  </div>
                </div>
                <div
                  class="align-items-center d-flex power-item-container__detalle power-item-container__detalle-right justify-content-end z-index-1"
                >
                  <div
                    class="contenido power-content-right animate__animated d-none"
                  >
                    <p>{{ poderes[5].descripcion }}</p>
                  </div>
                  <div
                    class="col-1 d-flex justify-content-center pointer"
                    (click)="iconClick(poder_5, 'DER', poderes[5])"
                  >
                    <img
                      src="assets/images/iconos/flecha-adelante.png"
                      height="20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row container__actions">
            <div class="d-flex">
              <button
                [disabled]="isLoading"
                type="button"
                class="btn-primary-toulouse"
                (click)="siguiente()"
              >
                <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                >{{ isLoading ? "Espere..." : "Siguiente" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="20"
      [totalBars]="2"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
