import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  static getValidatorErrorMessage(tipoError: string, detalleTipoError?: any) {
    const config = {
      required: 'Este campo es obligatorio',
      invalidCreditCard: 'Es una tarjeta inválida',
      invalidEmailAddress: 'Es un email inválido',
      invalidPassword:
        'Contraseña invalida. Debería tener 6 caracteres, y contener números.',
      minlength: `Los caracteres mínimos son ${detalleTipoError.requiredLength}`
    };

    return config[tipoError];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

}
