import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { AppState } from 'src/app/store/app.store';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../../global/GlobalConstants';

declare var $: any;

@Component({
  selector: 'app-carreras',
  templateUrl: './carreras.component.html',
  styleUrls: ['./carreras.component.scss']
})
export class CarrerasComponent implements OnInit {
  //Globales
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;
  carreraForm: FormGroup;
  //Auxiliares
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  tokenUsuario = '';

  dtOptions: any = {};
  dtTrigger = new Subject();

  carrerasInit: any = [];
  carreras: any = [];

  mostrarModal = false;
  buscarValue = '';
  procesando = true;
  guardando = false;
  nuevaCarrera = false;

  //Matriz de carrera
  matrizCarreras: any;

  //forms
  ngModelpoderElegido = null;
  poderesElegido: any[] = [];
  /*   ngModelCDOElegido = null;
    CDOElegidos: any = []; */

  ngModelCalzadoElegido = null;
  CalzadosElegidos: any = [];
  ngModelTrajeElegido = null;
  TrajesElegidos: any = [];

  ngModelhabilidadElegida = null;
  habilidadesElegidos: any = [];
  ngModelcascoElegido = null;
  cascosElegidos: any = [];
  ngModelEsCarreraToulouse = false;

  poderes = GlobalConstants.PODERES;
  /* CDOs = GlobalConstants.PROBLEMAS; */
  calzados = GlobalConstants.CALZADO;
  trajes = GlobalConstants.PECHERAS;
  habilidades = GlobalConstants.MOCHILA;
  cascos = GlobalConstants.CASCOS;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, private etapaService: EtapaService, private alertService: AlertsService, private store: Store<AppState>) {
    this.carreraForm = formBuilder.group(
      {
        codigo: ['', [Validators.required]],
        nombre: ['', Validators.required],
        activo: [true, Validators.required],
        carrToulouse: [false, Validators.required],
        id: ['', Validators.required],
        linkAsociado: ['']
      }
    );

    //Ordenamos habilidades de manera alfabetica

    this.habilidades.sort((a, b) => (a.titulo > b.titulo) ? 1 : ((b.titulo > a.titulo) ? -1 : 0));
  }

  ngOnInit(): void {
    $('[data-toggle="tooltip"]').tooltip();

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      ordering: true,
      info: false,
      pageLength: 5,
      scrollX: 400,
      lengthChange: false,
      language: {
        lengthMenu: 'Mostrar _MENU_ registros por página',
        zeroRecords: 'Sin resultados',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No hay registros disponibles.',
        infoFiltered: '(filtrado de _MAX_ registros)',
        paginate: {
          previous: '<i class="fas fa-angle-left"></i>',
          next: '<i class="fas fa-angle-right"></i>',
          first: '<i class="fas fa-angle-double-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>'
        }
      }
    };


    this.store.select('token').pipe(take(1)).subscribe(resToken => {
      if (resToken.tokenData || resToken.tokenData !== '') {
        this.tokenUsuario = resToken.tokenData;
        this.http.post(`${GlobalConstants.admin.carreras}`, '', {
          headers: {
            authorization: `Bearer ${resToken.tokenData}`
          }
        }).subscribe((carreras: any) => {
          carreras.map((item: any, index: number) => {
            item.orden = index + 1;
          });
          this.carrerasInit = carreras;
          this.carreras = carreras;//carreras.sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()));
          this.dtTrigger.next();
          this.procesando = false;
        });
      }
    });
  }


  buscarCarreras() {
    this.carreras = [];
    this.procesando = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if (this.buscarValue === '') {
        this.carreras.map((item: any, index: number) => {
          item.orden = index + 1;
        });
        this.carreras = [...this.carrerasInit.sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()))];
      } else {
        const carrerasFiltradas = this.carrerasInit.filter((item: any, index: number) => {
          if (item.codigo.toUpperCase().indexOf(this.buscarValue.toUpperCase()) !== -1 ||
            item.nombre.toUpperCase().indexOf(this.buscarValue.toUpperCase()) !== -1) {
            return item;
          }
        });
        this.carreras = [...carrerasFiltradas.sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()))];
      }
      this.dtTrigger.next();
      this.procesando = false;
      setTimeout(() => {
        $('#nombreCol').click();
      }, 100);
    });

  }


  resetearTabla() {
    this.procesando = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      setTimeout(() => {
        this.dtTrigger.next();
        this.procesando = false;
      }, 500);
    });
  }

  nuevaCarreraPopUp() {
    this.carreraForm.get('codigo').enable();

    let codigosOrdenados = this.obtenerCodigosOrdenados();
    let correlativo = 0;
    if (codigosOrdenados.length === 0) {
      correlativo++;
    } else {
      //El último número registrado + 1
      correlativo = codigosOrdenados[codigosOrdenados.length - 1] + 1;
    }

    this.carreraForm.get('codigo').setValue('carrera-' + correlativo);
    this.carreraForm.get('nombre').setValue('');
    this.carreraForm.get('linkAsociado').setValue('');
    this.carreraForm.get('activo').setValue(true);
    this.carreraForm.get('carrToulouse').setValue(false);

    this.nuevaCarrera = true;
  }
  obtenerCarreraPopUp(fila: any) {
    this.carreraForm.get('codigo').disable();

    this.poderesElegido = [], this.TrajesElegidos = [], this.CalzadosElegidos = [],
    /* this.CDOElegidos = [], */ this.habilidadesElegidos = [], this.cascosElegidos = [];
    this.nuevaCarrera = false;

    this.carreraForm.get('codigo').setValue(fila.codigo);
    this.carreraForm.get('nombre').setValue(fila.nombre);
    this.carreraForm.get('activo').setValue(fila.activo);
    this.carreraForm.get('carrToulouse').setValue(fila.carrToulouse);
    this.carreraForm.get('linkAsociado').setValue(fila.linkAsociado);
    this.carreraForm.get('id').setValue(fila.id);

    if (fila.poderes) {
      Object.entries(fila.poderes).map(res => {
        this.poderesElegido.push({
          codigo: res[0],
          titulo: this.obtenerDetallePorCodigo('PODER', res[0])
        });
      });
    }

    /*     if (fila.CDO) {
          Object.entries(fila.CDO).map(res => {
            this.CDOElegidos.push({
              codigo: res[0],
              titulo: this.obtenerDetallePorCodigo('CDO', res[0])
            });
          });
        } */
    if (fila.calzados) {
      Object.entries(fila.calzados).map(res => {
        this.CalzadosElegidos.push({
          codigo: res[0],
          titulo: this.obtenerDetallePorCodigo('CALZADO', res[0])
        });
      });
    }
    if (fila.trajes) {
      Object.entries(fila.trajes).map(res => {
        this.TrajesElegidos.push({
          codigo: res[0],
          titulo: this.obtenerDetallePorCodigo('TRAJE', res[0])
        });
      });
    }

    if (fila.habilidades) {
      Object.entries(fila.habilidades).map(res => {
        this.habilidadesElegidos.push({
          codigo: res[0],
          titulo: this.obtenerDetallePorCodigo('HABILIDAD', res[0])
        });
      });
    }

    if (fila.cascos) {
      Object.entries(fila.cascos).map(res => {
        this.cascosElegidos.push({
          codigo: res[0],
          titulo: this.obtenerDetallePorCodigo('CASCO', res[0])
        });
      });
    }
  }

  agregarCarrera() {
    this.guardando = true;
    const dataEnviada = {
      codigo: this.carreraForm.get('codigo').value,
      nombre: this.carreraForm.get('nombre').value,
      activo: this.carreraForm.get('activo').value,
      linkAsociado: this.carreraForm.get('linkAsociado').value,
      carrToulouse: this.carreraForm.get('carrToulouse').value
    };

    // Nueva
    if (this.nuevaCarrera) {

      if (this.carreraForm.get('codigo').value.length === 0) {
        this.alertService.modalAlert('Oops', 'El código no puede estar en blanco.');
        this.guardando = false;
        return;
      }

      if (this.carreraForm.get('nombre').value.length === 0) {
        this.alertService.modalAlert('Oops', 'El nombre no puede estar en blanco.');
        this.guardando = false;
        return;
      }

      let contadorCodigoExiste = 0, contadorNombreExiste = 0;
      this.carrerasInit.map((item: any) => {
        if (item.codigo.toUpperCase() === this.carreraForm.get('codigo').value.toUpperCase()) {
          contadorCodigoExiste++;
        }
        if (item.nombre.toUpperCase() === this.carreraForm.get('nombre').value.toUpperCase()) {
          contadorNombreExiste++;
        }
      });

      if (contadorCodigoExiste > 0) {
        this.alertService.modalAlert('Oops', 'El código ya se encuentra en uso.');
        this.guardando = false;
        return;
      }

      if (contadorNombreExiste > 0) {
        this.alertService.modalAlert('Oops', 'El nombre ya se encuentra en uso.');
        this.guardando = false;
        return;
      }

      this.http.post(GlobalConstants.admin.agregarCarrera, {
        carrera: dataEnviada
      }, {
        headers: {
          authorization: `Bearer ${this.tokenUsuario}`
        }
      }).toPromise().then(res => {
        this.guardando = false;
        this.agregarCarreraArray(dataEnviada);

        $('#agregarCarrera').modal('hide');

        this.alertService.modalInfo('', 'La carrera se registró exitosamente.');
        //alert('La carrera se registró exitosamente.');
      }).catch(err => {
        this.guardando = false;
        $('#agregarCarrera').modal('hide');
        this.alertService.modalError(null, 'Ocurrió un error en el registro, por favor intentelo nuevamente');

        //alert('Ocurrió un error en el registro, por favor intentelo nuevamente.');
      });

    }
    // Editar
    else {
      if (this.carreraForm.get('nombre').value.length === 0) {
        this.alertService.modalAlert('Oops', 'El nombre no puede estar en blanco.');
        this.guardando = false;
        return;
      }

      let contadorNombreExiste = 0;
      const infoInicial = this.carrerasInit.find(res => res.codigo === dataEnviada.codigo);
      if (infoInicial.nombre.toUpperCase() !== this.carreraForm.get('nombre').value.toUpperCase()) {//Si el nombre no es igual que valide
        this.carrerasInit.map((item: any) => {
          if (item.nombre.toUpperCase() === this.carreraForm.get('nombre').value.toUpperCase()) {
            contadorNombreExiste++;
          }
        });
        if (contadorNombreExiste > 0) {
          this.alertService.modalAlert('Oops', 'El nombre ya se encuentra en uso.');
          this.guardando = false;
          return;
        }
      }




      this.http.put(GlobalConstants.admin.editarCarrera, {
        carreraId: this.carreraForm.get('id').value,
        carrera: dataEnviada
      }, {
        headers: {
          authorization: `Bearer ${this.tokenUsuario}`
        }
      }).toPromise().then(res => {
        this.guardando = false;
        $('#agregarCarrera').modal('hide');
        this.actualizarCarreraArray(dataEnviada, this.carreraForm.get('id').value);
        this.alertService.modalInfo('', 'La carrera se actualizó exitosamente.');
      }).catch(err => {
        this.guardando = false;
        $('#agregarCarrera').modal('hide');
        this.alertService.modalError(null, `Ocurrió un error en la actualización, por favor intentelo nuevamente.  Error del tipo : ${err.error?.mensaje}`);
      });
    }
  }
  eliminarCarrera(carrera) {
    this.guardando = true;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': `Bearer ${this.tokenUsuario}`
      }),
      body: {
        carreraId: carrera.id
      }
    };

    Swal.fire({
      title: 'Espera...',
      text: '¿Está seguro que desea eliminar esta carrera?',
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sí',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, cancelar.',
      showLoaderOnConfirm: true,
      preConfirm: (respuestaConfirm) => {
        if (respuestaConfirm === true) {
          return this.http.delete(GlobalConstants.admin.eliminarCarrera, httpOptions).toPromise().then(res => {
            return res;
          }).catch(err => {
            return err;
          });
        } else {
          return;
        }
      },
    }).then((res: any) => {
      if (res.value) {
        this.eliminarCarreraArray(carrera);
        this.alertService.modalSuccess(null, 'Se eliminó correctamente');
        this.guardando = false;
      } else {
        this.guardando = false;
      }
    });
  }

  get nombreNoValido() {
    return (
      this.carreraForm.get('nombre').invalid &&
      this.carreraForm.get('nombre').touched
    );
  }
  get codigoNoValido() {
    return (
      this.carreraForm.get('codigo').invalid &&
      this.carreraForm.get('codigo').touched
    );
  }
  get activoNoValido() {
    return (
      !this.carreraForm.get('activo').value
    );
  }

  agregarCarreraArray(data) {
    this.carreras.push(data);
    this.carrerasInit.push(data);

    this.resetearTabla();
  }

  actualizarCarreraArray(data, id) {
    const dataActualizada = this.carreras.map(res => {
      if (res.id === id) {
        return { ...res, ...data };
      }
      return res;
    });
    this.carreras = [...dataActualizada];
    this.carrerasInit = [...dataActualizada];

    this.resetearTabla();
  }

  eliminarCarreraArray(carrera) {
    const indice = this.carreras.findIndex((el) => el.id === carrera.id);
    const indiceTablaInicial = this.carrerasInit.findIndex((el) => el.id === carrera.id);
    this.carreras.splice(indice, 1);
    this.carrerasInit.splice(indiceTablaInicial, 1);

    this.resetearTabla();
  }

  //Lógica de matriz de carreras
  agregarElemento(tipo) {
    switch (tipo) {
      case 'PODER':
        if (!this.ngModelpoderElegido || this.ngModelpoderElegido === '') {
          this.alertService.modalAlert('', 'Debe elegir un valor');
          return;
        }
        if (this.poderesElegido.length > 1) {
          this.alertService.modalAlert('', 'El máximo es 2');
          return;
        }
        if (this.poderesElegido.filter(res => res.codigo === this.ngModelpoderElegido.codigo).length > 0) {
          this.alertService.modalAlert('', 'Ya existe este elemento');
          return;
        }
        this.poderesElegido.push({
          codigo: this.ngModelpoderElegido.codigo,
          titulo: this.ngModelpoderElegido.titulo,
        });
        break;

      /*       case 'CDO':
              if (!this.ngModelCDOElegido || this.ngModelCDOElegido === '') {
                this.alertService.modalAlert('', 'Debe elegir un valor');
                return;
              }
              if (this.CDOElegidos.length > 1) {
                this.alertService.modalAlert('', 'El máximo es 2');
                return;
              }
              if (this.CDOElegidos.filter(res => res.codigo === this.ngModelCDOElegido.codigo).length > 0) {
                this.alertService.modalAlert('', 'Ya existe este elemento');
                return;
              }
              this.CDOElegidos.push({
                codigo: this.ngModelCDOElegido.codigo,
                titulo: this.ngModelCDOElegido.titulo,
              });
              break; */

      case 'CALZADO':
        if (!this.ngModelCalzadoElegido || this.ngModelCalzadoElegido === '') {
          this.alertService.modalAlert('', 'Debe elegir un valor');
          return;
        }
        if (this.CalzadosElegidos.length > 1) {
          this.alertService.modalAlert('', 'El máximo es 2');
          return;
        }
        if (this.CalzadosElegidos.filter(res => res.codigo === this.ngModelCalzadoElegido.codigo).length > 0) {
          this.alertService.modalAlert('', 'Ya existe este elemento');
          return;
        }
        this.CalzadosElegidos.push({
          codigo: this.ngModelCalzadoElegido.codigo,
          titulo: this.ngModelCalzadoElegido.titulo,
        });
        break;

      case 'TRAJE':
        if (!this.ngModelTrajeElegido || this.ngModelTrajeElegido === '') {
          this.alertService.modalAlert('', 'Debe elegir un valor');
          return;
        }
        if (this.TrajesElegidos.length > 2) {
          this.alertService.modalAlert('', 'El máximo es 3');
          return;
        }
        if (this.TrajesElegidos.filter(res => res.codigo === this.ngModelTrajeElegido.codigo).length > 0) {
          this.alertService.modalAlert('', 'Ya existe este elemento');
          return;
        }
        this.TrajesElegidos.push({
          codigo: this.ngModelTrajeElegido.codigo,
          titulo: this.ngModelTrajeElegido.titulo,
        });
        break;
      case 'HABILIDAD':
        if (!this.ngModelhabilidadElegida || this.ngModelhabilidadElegida === '') {
          this.alertService.modalAlert('', 'Debe elegir un valor');
          return;
        }
        if (this.habilidadesElegidos.length > 6) {
          this.alertService.modalAlert('', 'El máximo es 7');
          return;
        }
        if (this.habilidadesElegidos.filter(res => res.codigo === this.ngModelhabilidadElegida.codigo).length > 0) {
          this.alertService.modalAlert('', 'Ya existe este elemento');
          return;
        }
        this.habilidadesElegidos.push({
          codigo: this.ngModelhabilidadElegida.codigo,
          titulo: this.ngModelhabilidadElegida.titulo,
        });
        break;

      case 'CASCO':
        if (!this.ngModelcascoElegido || this.ngModelcascoElegido === '') {
          this.alertService.modalAlert('', 'Debe elegir un valor');
          return;
        }
        if (this.cascosElegidos.length > 2) {
          this.alertService.modalAlert('', 'El máximo es 3');
          return;
        }
        if (this.cascosElegidos.filter(res => res.codigo === this.ngModelcascoElegido.codigo).length > 0) {
          this.alertService.modalAlert('', 'Ya existe este elemento');
          return;
        }
        this.cascosElegidos.push({
          codigo: this.ngModelcascoElegido.codigo,
          titulo: this.ngModelcascoElegido.titulo,
        });
        break;
    }

  }
  removerElemento(tipo, pos) {
    switch (tipo) {
      case 'PODER':
        this.poderesElegido.splice(pos, 1);
        break;
      case 'CDO':
      /*         this.CDOElegidos.splice(pos, 1);
              break; */
      case 'CALZADO':
        this.CalzadosElegidos.splice(pos, 1);
        break;
      case 'TRAJE':
        this.TrajesElegidos.splice(pos, 1);
        break;
      case 'HABILIDAD':
        this.habilidadesElegidos.splice(pos, 1);
        break;
      case 'CASCO':
        this.cascosElegidos.splice(pos, 1);
        break;
    }
  }
  registrarMatriz() {
    this.guardando = true;
    const _pod = this.poderesElegido;
    /* const _CDO = this.CDOElegidos; */
    const _traj = this.TrajesElegidos;
    const _calz = this.CalzadosElegidos;
    const _hab = this.habilidadesElegidos;
    const _cas = this.cascosElegidos;
    if (_pod.length === 2 && /* _CDO.length === 2 */ _traj.length === 3 && _calz.length === 2 && _hab.length === 7 && _cas.length === 3) {
      const dataAEnviar = {
        matrizAsociada: true,
        poderes: {
          [_pod[0].codigo]: true,
          [_pod[1].codigo]: true
        },
        /*         CDO: {
                  [_CDO[0].codigo]: true,
                  [_CDO[1].codigo]: true
                }, */
        trajes: {
          [_traj[0].codigo]: true,
          [_traj[1].codigo]: true,
          [_traj[2].codigo]: true
        },
        calzados: {
          [_calz[0].codigo]: true,
          [_calz[1].codigo]: true
        },
        habilidades: {
          [_hab[0].codigo]: true,
          [_hab[1].codigo]: true,
          [_hab[2].codigo]: true,
          [_hab[3].codigo]: true,
          [_hab[4].codigo]: true,
          [_hab[5].codigo]: true,
          [_hab[6].codigo]: true
        },
        cascos: {
          [_cas[0].codigo]: true,
          [_cas[1].codigo]: true,
          [_cas[2].codigo]: true
        }
      };

      this.http.put(GlobalConstants.admin.editarCarrera, {
        carreraId: this.carreraForm.get('id').value,
        carrera: {
          codigo: this.carreraForm.get('codigo').value,
          nombre: this.carreraForm.get('nombre').value,
          activo: this.carreraForm.get('activo').value,
          ...dataAEnviar
        }
      }, {
        headers: {
          authorization: `Bearer ${this.tokenUsuario}`
        }
      }).toPromise().then(res => {
        this.guardando = false;
        this.actualizarCarreraArray(dataAEnviar, this.carreraForm.get('id').value)
        $('#agregarMatriz').modal('hide');
        this.alertService.modalInfo('', 'La matriz se actualizó exitosamente.');

        //Resetear ngmodel
        this.ngModelpoderElegido = null;
        /* this.ngModelCDOElegido = null; */
        this.ngModelCalzadoElegido = null;
        this.ngModelTrajeElegido = null;
        this.ngModelhabilidadElegida = null;
        this.ngModelcascoElegido = null;
      }).catch(err => {
        this.guardando = false;
        $('#agregarMatriz').modal('hide');
        this.alertService.modalError(null, 'Ocurrió un error en la actualización, por favor intentelo nuevamente');
      });
    } else {
      this.guardando = false;
      this.alertService.modalAlert('', 'Hay valores faltantes, por favor ingrese la cantidad según la especificación. 2 Poderes, 3 trajes, 2 calzados, 7 Hábilidades y 3 Cascos');
    }
  }

  obtenerDetallePorCodigo(tipo, codigo) {
    switch (tipo) {
      case 'PODER':
        return this.poderes.filter(res => res.codigo === codigo)[0].titulo;
      /*       case 'CDO':
              return this.CDOs.filter(res => res.codigo === codigo)[0].titulo; */
      case 'TRAJE':
        return this.trajes.filter(res => res.codigo === codigo)[0].titulo;
      case 'CALZADO':
        return this.calzados.filter(res => res.codigo === codigo)[0].titulo;
      case 'HABILIDAD':
        return this.habilidades.filter(res => res.codigo === codigo)[0].titulo;
      case 'CASCO':
        return this.cascos.filter(res => res.codigo === codigo)[0].titulo;
    }
  }

  obtenerCodigosOrdenados() {
    let soloNumeros = this.carrerasInit.map(res => {
      return parseInt(res.codigo.split('-')[1]);
    });
    return soloNumeros.sort((a, b) => a - b);
  }
}


/*             Swal.showValidationMessage(
              `Request failed: ${err}`
            ); */