<div class="proposito">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 otrosDevices text-right div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>
      <div
        class="avatar-container col-12 col-md-12 d-flex div-contenido flex-wrap justify-content-end px-3"
      >
        <div
          class="col-md-6 d-flex flex-column justify-content-center flex-width-100 prim-detalle"
        >
          <div class="align-items-center justify-content-between row pr-lg-5">
            <h1 class="title-text-white">Mi propósito</h1>
            <!--                         <div class="d-flex ods-item elegido">
                            <label>{{problemaElegido}}</label>
                        </div> -->
          </div>
          <div class="row flex-column pr-lg-5">
            <div class="pt-3 text-white">
              <div class="my-2">
                ¿Para qué quieres
                <span
                  class="font-weight-bold"
                  style="text-transform: lowercase"
                  [innerHTML]="problemaElegido"
                ></span>
                y por qué es relevante?
              </div>
            </div>
            <div>
              <textarea
                class="form-control-retro"
                [(ngModel)]="propositoText"
                maxlength="110"
              ></textarea>
              <div class="mt-1 mb-2" style="font-size: 0.75rem; color: #fffbb8">
                Van {{ propositoText.length }} caracteres de 110
              </div>
            </div>
          </div>
          <div class="text-center">
            <button
              [disabled]="isLoading"
              type="button"
              class="btn-primary-toulouse"
              (click)="siguiente()"
              style="width: 16rem"
            >
              <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
              ><span>{{ isLoading ? "Espere..." : "Siguiente" }}</span>
            </button>
          </div>
        </div>
        <div class="col-md-5 d-flex personaje-container row flex-width-100">
          <div class="position-relative avatar otrosDevices">
            <img *ngIf="personaje" class="personaje" [src]="personaje" />
            <img
              *ngIf="cinturon"
              class="cinturon"
              src="assets/images/poderes/{{ cinturon }}"
            />
            <img
              *ngIf="tipoCuerpoElegido"
              class="tipoCuerpo det-tipo {{ tipoCuerpoElegido?.codigo }}-puesto"
              src="assets/images/tipos/{{ tipoCuerpoElegido?.foto }}"
            />
            <img
              *ngIf="pecheraElegida"
              class="pechera {{ pecheraElegida.codigo }}-puesto"
              src="assets/images/pecheras/{{ pecheraElegida?.foto }}"
            />
            <img
              *ngIf="calzadoElegido"
              class="calzado {{ calzadoElegido.codigo }}-puesto"
              src="assets/images/calzados/{{ calzadoElegido?.foto }}"
            />
            <img
              *ngIf="cascoElegido"
              class="miCasco {{ cascoElegido.codigo }}-puesto"
              src="assets/images/cascos/{{ cascoElegido?.foto }}"
            />
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="90"
      [totalBars]="9"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
