import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import {
  setHeroe,
  setIsTheLastStep,
  setUser,
} from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';
import { isLoading, stopLoading } from './../../store/actions/ui.action';

dayjs.locale('es'); // use Spanish locale globally

@Component({
  selector: 'app-nombre-heroe',
  templateUrl: './nombre-heroe.component.html',
  styleUrls: ['./nombre-heroe.component.scss'],
})
export class NombreHeroeComponent implements OnInit {
  //Constantes
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;

  //variables de ayuda
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  nombreHeroe: string = '';
  carrerasFiltradas = [];

  //variables de ayuda mensaje de felicitacion
  mostrarModalFelicitacion: boolean = false;
  tituloModalFelicitacion: string = '¡Felicidades!';
  detalleModalFelicitacion: string = '';
  textoBoton: string = '';

  personaje: string; //Personaje elegido
  cinturon: string; //cinturon del personaje basado en el poder
  tipoCuerpoElegido: IAvatar; //Tipo de cuerpo o gema
  pecheraElegida: IAvatar; //pecheraElegida
  calzadoElegido: IAvatar; //calzado elegido
  cascoElegido: IAvatar; //casco elegido

  //variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService,
    private http: HttpClient
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe((res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;

        if (this.heroeInfo) {
          const genero = this.userInfo.genero.toLocaleLowerCase();
          this.personaje = this.etapaService.obtenerPersonaje(
            'poderes',
            this.heroeInfo.poder[0][genero]
          );
          this.cinturon = this.heroeInfo?.poder[0].auxFoto;
          this.tipoCuerpoElegido = res.heroe?.tipoCuerpo[0];
          this.pecheraElegida = res.heroe?.vestimenta[0];
          this.calzadoElegido = res.heroe?.calzado[0];
          this.cascoElegido = res.heroe?.casco[0];
          this.nombreHeroe = res.heroe?.nick;
        }
      });
    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });

    //la primera vez el modal aparecerá
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;

    //mostrarModalFelicitacion: boolean = false;
    this.tituloModalFelicitacion = '¡Felicidades!';
    this.textoBoton = 'Ver mi héroe';
    this.detalleModalFelicitacion =
      'Tu misión ha sido completada, me siento orgullosa de tu logro. Te falta un paso para conocer a tu superhéroe...';
  }

  async siguiente() {
    if (this.nombreHeroe.length === 0) {
      this.alerts.modalInfo(
        null,
        'Debes ingresar tu nombre de superhéroe para continuar'
      );
    } else {
      this.store.dispatch(isLoading());

      //Después actualizamos el poder elegido
      const dataHeroe = {
        nick: this.nombreHeroe,
      };
      await this.etapaService.actualizarTest(
        this.userInfo.uuid,
        this.userInfo.flagTrackingTest.uuidTest,
        dataHeroe
      );

      //Obtenemos las carreras que coincidan
      const resCarreras = await this.obtenerCarreras();
      //Agregamos las coincidencias
      resCarreras.docs.map((res) => {
        this.carrerasFiltradas.push(res.data());
      });

      //Una vez obtenida las carreras la filtramos y ordenamos y la agregamos a la info final
      const misCarreras = this.filtrarCarrerasYOrdenarlas();

      //Si no obtiene ninguna carrera agregamos el flag
      let tieneCarreras = false;
      if (misCarreras.length > 0) {
        tieneCarreras = true;
      }

      //Primero actualizamos el último path visitado
      const infoFinalHeroe = { ...this.heroeInfo, ...dataHeroe, misCarreras };
      let habilidadesTexto: any = this.heroeInfo?.habilidades.map(
        (res) => res.titulo + ' - ' + res.descripcion
      );
      habilidadesTexto = habilidadesTexto.join(',');

      const soloCarrerasToulouse = misCarreras
        .filter((res) => res.carrToulouse)
        .map((res) => res.nombre);
      const carreras = misCarreras.map((res) => res.nombre);
      const tresCarrerasPrioritarias = [
        ...soloCarrerasToulouse,
        ...carreras,
      ]?.slice(0, 3);

      const dataUser: IUser = {
        flagTrackingTest: {
          ...this.userInfo.flagTrackingTest,
          lastPath: this.router.url,
        },
        testTerminado: true,
        tieneCarreras,
        fechFinTest: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        todasLasCarrerasObtenidas: misCarreras,
        misCarreras: tresCarrerasPrioritarias,
        misCarrerasToulouse: soloCarrerasToulouse,
        miSuperHeroe: {
          CDO: infoFinalHeroe.CDO[0].titulo,
          calzado:
            infoFinalHeroe.calzado[0].titulo +
            ' - ' +
            infoFinalHeroe.calzado[0].descripcion,
          casco:
            infoFinalHeroe.casco[0].titulo +
            ' - ' +
            infoFinalHeroe.casco[0].descripcion,
          don:
            infoFinalHeroe.dones[0].titulo +
            ' - ' +
            infoFinalHeroe.dones[0].descripcion,
          habilidades: habilidadesTexto,
          herramientas:
            infoFinalHeroe.herramientas[0].titulo +
            ' - ' +
            infoFinalHeroe.herramientas[0].descripcion,
          nick: infoFinalHeroe.nick,
          poder:
            infoFinalHeroe.poder[0].titulo +
            ' - ' +
            infoFinalHeroe.poder[0].descripcion,
          proposito: infoFinalHeroe.proposito,
          tipoCuerpo:
            infoFinalHeroe.tipoCuerpo[0].titulo +
            ' - ' +
            infoFinalHeroe.tipoCuerpo[0].descripcion,
          vestimenta:
            infoFinalHeroe.vestimenta[0].titulo +
            ' - ' +
            infoFinalHeroe.vestimenta[0].descripcion,
        },
      };

      this.usuario
        .updUsuarioFireStore(this.userInfo.uuid, dataUser)
        .then((res) => {
          this.store.dispatch(stopLoading());
          this.store.dispatch(
            setUser({ info: { ...this.userInfo, ...dataUser } })
          );
          this.store.dispatch(setHeroe({ heroe: infoFinalHeroe }));
          this.store.dispatch(setIsTheLastStep({ isTheLastStep: true }));
          this.mostrarFelicitaciones();
        })
        .catch((err) => {
          this.store.dispatch(stopLoading());
          this.alerts.modalError(null, 'Sucedio un error:' + err);
        });
    }
  }

  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  mostrarFelicitaciones() {
    this.mostrarModalFelicitacion = true;
  }

  //eventos output
  clickBotonModalToulouse(event) {
    if (this.userInfo.registroDatos === false || !this.userInfo.registroDatos) {
      this.router.navigate(['/alumno/mis-datos']);
    } else {
      this.router.navigate(['/alumno/resultado']);
    }
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }

  obtenerCarreras() {
    return this.etapaService.cargarCarreras();
  }

  filtrarCarrerasYOrdenarlas() {
    let carrerasConPuntajes = [];
    carrerasConPuntajes = this.carrerasFiltradas.map((carr) => {
      let _puntaje = 0;

      //Si tiene poder +6
      if (carr.poderes?.hasOwnProperty(this.heroeInfo.poder[0].codigo)) {
        _puntaje += 6;
      }

      //Si tiene CDO +1
      if (carr.CDO?.hasOwnProperty(this.heroeInfo.CDO[0].codigo)) {
        _puntaje += 1;
      }

      //Si tiene habilidad - dones - herramienta +2
      if (carr.habilidades?.hasOwnProperty(this.heroeInfo.dones[0].codigo)) {
        _puntaje += 2;
      }
      if (
        carr.habilidades?.hasOwnProperty(this.heroeInfo.habilidades[0].codigo)
      ) {
        _puntaje += 2;
      }
      if (
        carr.habilidades?.hasOwnProperty(this.heroeInfo.habilidades[1].codigo)
      ) {
        _puntaje += 2;
      }
      if (
        carr.habilidades?.hasOwnProperty(this.heroeInfo.herramientas[0].codigo)
      ) {
        _puntaje += 2;
      }

      //Si tiene casco +3
      if (carr.cascos?.hasOwnProperty(this.heroeInfo.casco[0].codigo)) {
        _puntaje += 3;
      }

      //Si tiene zapato +2
      if (carr.calzados?.hasOwnProperty(this.heroeInfo.calzado[0].codigo)) {
        _puntaje += 2;
      }

      //Si tiene ropa +1
      if (carr.trajes?.hasOwnProperty(this.heroeInfo.vestimenta[0].codigo)) {
        _puntaje += 1;
      }

      return {
        ...carr,
        puntaje: _puntaje,
      };
    });

    const top5Carreras = carrerasConPuntajes
      .sort((a, b) => b.puntaje - a.puntaje)
      .slice(0, 5);
    return top5Carreras;
  }
  atras() {
    this.router.navigate(['/alumno/proposito']);
  }
}
