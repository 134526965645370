import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.store';
import { GlobalConstants } from '../../global/GlobalConstants';

declare var $: any;

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.scss'],
})
export class DescargasComponent implements OnInit {
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  tokenObtenido = '';
  dtOptions: any = {};
  dtTrigger = new Subject();

  fechaActual: any = new Date();
  anhoActual: any = this.fechaActual.getFullYear();
  mesActual: any = GlobalConstants.MESES[this.fechaActual.getMonth()];
  buscarValue = '';

  alumnosInit: any = [];
  alumnos: any = [];
  anhos: any = [];
  meses: any = GlobalConstants.MESES;

  btnExportar = true;
  procesando = true;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    for (let i = this.fechaActual.getFullYear(); i >= 2020; i--) {
      this.anhos.push(i);
    }
  }

  ngOnInit(): void {
    $('[data-toggle="tooltip"]').tooltip();

    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      ordering: false,
      info: false,
      processing: true,
      pageLength: 5,
      scrollX: 400,
      language: {
        lengthMenu: 'Mostrar _MENU_ registros por página',
        zeroRecords: 'Sin resultados',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No hay registros disponibles.',
        infoFiltered: '(filtrado de _MAX_ registros)',
        paginate: {
          previous: '<i class="fas fa-angle-left"></i>',
          next: '<i class="fas fa-angle-right"></i>',
          first: '<i class="fas fa-angle-double-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>',
        },
      },
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
            ],
          },
        },
      ],
    };

    this.store
      .select('token')
      .pipe(take(2))
      .subscribe((resToken) => {
        if (resToken.tokenData || resToken.tokenData !== '') {
          this.tokenObtenido = resToken.tokenData;

          this.http
            .get(
              `${GlobalConstants.admin.descargas}?mes=${
                this.getMesIndex(this.mesActual) + 1
              }&anio=${this.anhoActual}`,
              {
                headers: {
                  authorization: `Bearer ${resToken.tokenData}`,
                },
              }
            )
            .subscribe((alumnos: any) => {
              /*           alumnos.map((item: any, index: number) => {
                      item.orden = index + 1;
                      item.misCarreras = ["Ing. Química", "Comunicaciones"];
                    }); */

              const alumnosMapper = this.mapperAlumnos(alumnos);
              this.alumnosInit = alumnosMapper;
              this.alumnos = alumnosMapper;
              this.dtTrigger.next();
              this.procesando = false;

              if (this.alumnos.length > 0) {
                this.btnExportar = false;
              } else {
                this.btnExportar = true;
              }
            });
        }
      });
  }

  mapperAlumnos(alumnos) {
    const alumnosMapper = alumnos.map(
      ({
        detalleDecision,
        queEstudiare,
        razonDeEstudiar,
        analyticsParams,
        ...item
      }) => {
        if (item.misCarrerasToulouse) {
          return {
            ...item,
            carrerasToulouse: item.misCarrerasToulouse
              .join(', ')
              .replace(/, ((?:.(?!, ))+)$/, ' y $1'),
            totalCarrerasToulouse: item.misCarrerasToulouse.length,
            utm_campaign: analyticsParams?.utm_campaign,
            utm_content: analyticsParams?.utm_content,
            utm_medium: analyticsParams?.utm_medium,
            utm_source: analyticsParams?.utm_source,
            utm_term: analyticsParams?.utm_term,
          };
        }
        return {
          ...item,
          carrerasToulouse: '',
          totalCarrerasToulouse: 0,
          utm_campaign: analyticsParams?.utm_campaign,
          utm_content: analyticsParams?.utm_content,
          utm_medium: analyticsParams?.utm_medium,
          utm_source: analyticsParams?.utm_source,
          utm_term: analyticsParams?.utm_term,
        };
      }
    );
    return alumnosMapper;
  }

  limpiar() {
    this.procesando = true;
    this.anhoActual = this.fechaActual.getFullYear();
    this.mesActual = GlobalConstants.MESES[this.fechaActual.getMonth()];
    this.buscarValue = '';

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.alumnos = this.alumnosInit;
      if (this.alumnos.length > 0) {
        this.btnExportar = false;
      } else {
        this.btnExportar = true;
      }
    });

    this.dtTrigger.next();
    this.procesando = false;
  }

  buscarDescargas() {
    this.procesando = true;

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();

      this.http
        .get(
          `${GlobalConstants.admin.descargas}?mes=${
            this.getMesIndex(this.mesActual) + 1
          }&anio=${this.anhoActual}`,
          {
            headers: {
              authorization: `Bearer ${this.tokenObtenido}`,
            },
          }
        )
        .subscribe((resultados: any) => {
          let valido = false;

          if (this.buscarValue !== '') {
            resultados = resultados.filter((item: any) => {
              if (
                item.nombres
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.apellidoPaterno
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.apellidoMaterno
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.genero
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.celular
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.telefono
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.correo
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.colegio
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.distritoColegio
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.gradoACursar
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.proposito
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.calzado
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.don
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.tipoCuerpo
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.habilidades
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.casco
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.nick
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.poder
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.herramientas
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.CDO?.toUpperCase().indexOf(
                  this.buscarValue?.toUpperCase()
                ) > -1 ||
                item.vestimenta
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.carrerasToulouse
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.estado
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.utm_campaign
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.utm_content
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.utm_medium
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.utm_source
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1 ||
                item.utm_term
                  ?.toUpperCase()
                  .indexOf(this.buscarValue?.toUpperCase()) > -1
              ) {
                valido = true;
              } else {
                valido = false;
              }
              return valido;
            });
          }

          const alumnosMapper = this.mapperAlumnos(resultados);
          this.alumnos = alumnosMapper;

          if (this.alumnos.length > 0) {
            this.btnExportar = false;
          } else {
            this.btnExportar = true;
          }

          this.dtTrigger.next();
          this.procesando = false;
        });
    });
  }

  exportar() {
    if (this.alumnos.length > 0) {
      $('.buttons-excel').click();
    } else {
      this.btnExportar = true;
    }
  }

  getMesIndex(mesParam: string) {
    return GlobalConstants.MESES.findIndex(
      (data: any) => data.indexOf(mesParam) > -1
    );
  }
}
