<div class="modal" [ngClass]="{' d-block show': mostrarModal}" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button *ngIf="mostrarIconoCierre" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarModalToulouse()">
                    <img src="assets/images/iconos/icono-x.svg"/>
                </button>
            </div>
            <div class="modal-body">
                <div class="superior">
                    <img src="assets/images/iconos/bateria-fucsia.svg" *ngIf="mostrarIconoDetalle"/>
                    <label>{{titulo}}</label>
                </div>
                <div class="inferior">
                    <p class="text-center" [innerHTML]="detalle">
                    <!--   {{detalle}} -->
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="clickBotonModal()" class="btn-click" *ngIf="mostrarBoton">{{textoBoton}}</button>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{' modal-backdrop show': mostrarModal}"></div>