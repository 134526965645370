import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';
@Component({
  selector: 'app-proposito',
  templateUrl: './proposito.component.html',
  styleUrls: ['./proposito.component.scss']
})
export class PropositoComponent implements OnInit {
  //constantes
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;

  //variables de ayuda
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  problemaElegido: string = '';
  propositoText: string = '';


  personaje: string; //Personaje elegido
  cinturon: string; //cinturon del personaje basado en el poder
  tipoCuerpoElegido: IAvatar;//Tipo de cuerpo o gema
  pecheraElegida: IAvatar; //pecheraElegida
  calzadoElegido: IAvatar;//calzado elegido
  cascoElegido: IAvatar;//casco elegido

  //variabes del store
  userInfo: IUser;
  isLoading: boolean;
  heroeInfo: IDetalleSuperHeroe;

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(this.router.url);
  }

  ngOnInit(): void {
    this.store.select('user').pipe(take(3)).subscribe(res => {
      this.userInfo = res.info;
      this.heroeInfo = res.heroe;

      if (this.heroeInfo) {
        const genero = this.userInfo.genero.toLocaleLowerCase();
        this.personaje = this.etapaService.obtenerPersonaje('poderes', this.heroeInfo.poder[0][genero]);
        this.cinturon = this.heroeInfo?.poder[0].auxFoto;
        this.tipoCuerpoElegido = res.heroe?.tipoCuerpo[0];
        this.pecheraElegida = res.heroe?.vestimenta[0];
        this.calzadoElegido = res.heroe?.calzado[0];
        this.cascoElegido = res.heroe?.casco[0];

        this.problemaElegido = res.heroe.CDO[0]?.titulo;
        this.propositoText = res.heroe?.proposito;
      }
    });
    this.store.select('ui').pipe(take(3)).subscribe(res => {
      this.isLoading = res.isLoading;
    });

    //la primera vez el modal aparecerá
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  async siguiente() {
    if (this.propositoText.length === 0) {
      this.alerts.modalInfo(null, 'Debes ingresar tu propósito para continuar');
      return;
    }

    if (this.propositoText.length > 110) {
      this.alerts.modalInfo(null, 'La cantidad máxima de caracteres a escribir son 110.');
      return;
    }

    this.store.dispatch(isLoading());

    //actualizamos el poder elegido
    const dataHeroe = {
      proposito: this.propositoText
    };
    await this.etapaService.actualizarTest(this.userInfo.uuid, this.userInfo.flagTrackingTest.uuidTest, dataHeroe);


    let habilidadesTexto: any = this.heroeInfo?.habilidades.map(res => res.titulo + ' - ' + res.descripcion);
    habilidadesTexto = habilidadesTexto.join(',');
    const dataUser: IUser = {
      flagTrackingTest: {
        ...this.userInfo.flagTrackingTest,
        lastPath: this.router.url
      },
      fechFinTest: '',
      misCarreras: [],
      miSuperHeroe: {
        CDO: this.heroeInfo?.CDO[0].titulo,
        calzado: this.heroeInfo?.calzado[0].titulo + ' - ' + this.heroeInfo?.calzado[0].descripcion,
        casco: this.heroeInfo?.casco[0].titulo + ' - ' + this.heroeInfo?.casco[0].descripcion,
        don: this.heroeInfo?.dones[0].titulo + ' - ' + this.heroeInfo?.dones[0].descripcion,
        habilidades: habilidadesTexto,
        herramientas: this.heroeInfo?.habilidades[0].titulo + ' - ' + this.heroeInfo?.habilidades[0].descripcion,
        nick: '',
        poder: this.heroeInfo?.poder[0].titulo + ' - ' + this.heroeInfo?.poder[0].descripcion,
        proposito: this.propositoText,
        tipoCuerpo: this.heroeInfo?.tipoCuerpo[0].titulo + ' - ' + this.heroeInfo?.tipoCuerpo[0].descripcion,
        vestimenta: this.heroeInfo?.calzado[0].titulo + ' - ' + this.heroeInfo?.calzado[0].descripcion,
      }
    };

    this.usuario.updUsuarioFireStore(this.userInfo.uuid, dataUser).then(res => {
      this.store.dispatch(stopLoading());
      this.store.dispatch(setUser({ info: { ...this.userInfo, ...dataUser } }));
      this.store.dispatch(setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } }));
      this.router.navigate(['/alumno/mi-nombre']);
    }).catch(err => {
      this.store.dispatch(stopLoading());
      this.alerts.modalError(null, 'Sucedio un error:' + err);
    });

  }

  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }
  atras() {
    this.router.navigate(['/alumno/problemas']);
  }
}
