import { createReducer, on } from '@ngrx/store';
import { IDetalleSuperHeroe, IUser } from './../../interfaces/IUser';
import {
  clearHeroe,
  setHeroe,
  setUser,
  unSetUser,
  setIsTheLastStep,
} from './../actions/user.action';

export interface State {
  info: IUser;
  heroe: IDetalleSuperHeroe;
  isTheLastStep: boolean;
}

export const initialState: State = {
  info: null,
  heroe: null,
  isTheLastStep: false,
};

const _authReducer = createReducer(
  initialState,
  on(setUser, (state, { info }) => {
    return { ...state, info };
  }),
  on(unSetUser, (state) => {
    return { ...state, info: null };
  }),
  on(setHeroe, (lastState, { heroe }) => {
    return {
      ...lastState,
      heroe: heroe,
    };
  }),
  on(clearHeroe, (lastState) => {
    return {
      ...lastState,
      heroe: null,
    };
  }),
  on(setIsTheLastStep, (lastState, { isTheLastStep }) => {
    return {
      ...lastState,
      isTheLastStep,
    };
  })
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
