import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlumnosSinCarreraComponent } from './admin/alumnos-sin-carrera/alumnos-sin-carrera.component';
import { CarrerasComponent } from './admin/carreras/carreras.component';
import { DescargasComponent } from './admin/descargas/descargas.component';
import { MenuComponent } from './admin/menu/menu.component';
import { AuthAdminGuard } from './guards/auth.admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { CheckAdminGuard } from './guards/checkAdmin.guard';
import { LoginRegistroGuard } from './guards/loginRegistro.guard';
import { CalzateComponent } from './usuario/calzate/calzate.component';
import { CascoComponent } from './usuario/casco/casco.component';
import { HomeComponent } from './usuario/home/home.component';
import { InfoEtapasComponent } from './usuario/info-etapas/info-etapas.component';
import { LoginComponent } from './usuario/login/login.component';
import { MiCuerpoComponent } from './usuario/mi-cuerpo/mi-cuerpo.component';
import { MisDatosComponent } from './usuario/mis-datos/mis-datos.component';
import { MochilaComponent } from './usuario/mochila/mochila.component';
import { NombreHeroeComponent } from './usuario/nombre-heroe/nombre-heroe.component';
import { PoderComponent } from './usuario/poder/poder.component';
import { ProblemasComponent } from './usuario/problemas/problemas.component';
import { PropositoComponent } from './usuario/proposito/proposito.component';
import { RegistroComponent } from './usuario/registro/registro.component';
import { ResultadoComponent } from './usuario/resultado/resultado.component';
import { TipoCuerpoComponent } from './usuario/tipo-cuerpo/tipo-cuerpo.component';
import { VisteteComponent } from './usuario/vistete/vistete.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginRegistroGuard] },
  { path: 'registro', component: RegistroComponent, canActivate: [LoginRegistroGuard] },
  {
    path: 'admin',
    canActivate: [AuthGuard, AuthAdminGuard],
    children: [
      { path: '', component: MenuComponent },
      { path: 'carreras', component: CarrerasComponent },
      { path: 'descargas', component: DescargasComponent },
      { path: 'sin-carrera', component: AlumnosSinCarreraComponent },
    ]
  },
  {
    path: 'alumno',
    canActivate: [AuthGuard, CheckAdminGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'mis-datos', component: MisDatosComponent },
      { path: 'cuerpo', component: TipoCuerpoComponent },
      { path: 'mi-cuerpo', component: MiCuerpoComponent },
      { path: 'poder', component: PoderComponent },
      { path: 'casco', component: CascoComponent },
      { path: 'calzate', component: CalzateComponent },
      { path: 'vistete', component: VisteteComponent },
      { path: 'mochila-don', component: MochilaComponent },
      { path: 'mochila-hab', component: MochilaComponent },
      { path: 'mochila-herr', component: MochilaComponent },
      { path: 'etapa', component: InfoEtapasComponent },
      { path: 'etapa/:cod', component: InfoEtapasComponent },
      { path: 'problemas', component: ProblemasComponent },
      { path: 'info-etapas', component: InfoEtapasComponent },
      { path: 'proposito', component: PropositoComponent },
      { path: 'mi-nombre', component: NombreHeroeComponent },
      { path: 'resultado', component: ResultadoComponent },
      { path: 'consultar-resultado', component: ResultadoComponent },
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
