<div class="resultado">
  <div class="circulo-lineas"></div>
  <div  class="container-fluid full-vh px-0">

      <div class="cabecera otrosDevices row justify-content-between div-superior px-4 mx-0">
          <a (click)="salir()"  class="atras-div btn-back-yellow pointer">
              <img src="assets/images/iconos/flecha-atras.png"/>
            <span>Salir</span>
          </a>
          <div class="etapa d-flex align-items-center">
              <label class="detalle text-white">{{tituloEtapa}}</label>
          </div>
          <div class="d-flex align-items-center progress-parent">
            <div  class="progress-b bg-progreso progreso-div" >
                <label>¡COMPLETADO!</label>
                <img src="assets/images/iconos/estrella-terminada.svg" class="estrella"/>
            </div>
          </div>
      </div>

      <div class="cabecera d-none cabecera-movil row justify-content-between div-superior px-4 mx-0">
          <a (click)="salir()" class="atras-div btn-back-yellow flex-column">
              <img src="assets/images/iconos/flecha-atras.png"/>
            <span>Salir</span>
          </a>
          <div class="d-flex align-items-center progress-parent" style="width: 74%;margin-right: 1rem;">
            <div  class="progress-b bg-progreso progreso-div" >
                <label>¡COMPLETADO!</label>
                <img src="assets/images/iconos/estrella-terminada.svg" class="estrella"/>
            </div>
          </div>
      </div>


      <div class="align-items-end div-inferior mx-0 position-relative row" [style.background-image]="bgPoder" style="background-size:cover" >
          <div class="col-12 col-md-12 d-flex div-contenido flex-wrap justify-content-end px-3" #resultadosContenido>
              <div class="col-12 col-md-7 d-flex  flex-width-100">
                  <div class="contenedor-resultado" [ngClass]="{'justify-content-center': !existenCarreras}">
                      <div class="nick-heroe">
                          <span>{{miNick}}</span>
                      </div>
                      <div class="resultado-div">
                          <div class="bg-resultado">
                              <div [innerHTML]="mensajeResultado"> </div>
                          </div>

                      </div>
                      <div class="botonera-lg" *ngIf="existenCarreras">
                          <button [disabled]="contenidoCargado === false" class="btn-primary-toulouse" (click)="descargar()">Descarga tu informe completo</button>
                      </div>
                  </div>
              </div>
              <div class="align-items-end col-12 col-md-5 d-flex flex-width-100">
                  <div class="d-flex personaje-container align-items-end">
                      <div class="position-relative avatar">
                          <!-- <img class="personaje" [src]="personaje"/> -->
                          <img class="personaje" [src]="personaje" />
                          <img *ngIf="cinturon" class="cinturon" src="assets/images/poderes/{{cinturon}}"/>
                          <img *ngIf="tipoCuerpoElegido" class="tipoCuerpo det-tipo {{tipoCuerpoElegido?.codigo}}-puesto" src="assets/images/tipos/{{tipoCuerpoElegido?.foto}}"/>
                          <img *ngIf="pecheraElegida" class="pechera {{pecheraElegida.codigo}}-puesto" src="assets/images/pecheras/{{pecheraElegida?.foto}}"/>
                          <img *ngIf="calzadoElegido"class="calzado {{calzadoElegido.codigo}}-puesto" src="assets/images/calzados/{{calzadoElegido?.foto}}"/>
                          <img *ngIf="cascoElegido"class="miCasco {{cascoElegido.codigo}}-puesto" src="assets/images/cascos/{{cascoElegido?.foto}}"/>
                          <div class="sombra-personaje"></div>
                      </div>
                      <div class="mochila-res position-relative">
                          <div class="div-item-elegido-0">
                              <img *ngIf="misDones"  src="assets/images/mochila/{{misDones[0].foto}}" />
                          </div>
                          <div class="div-item-elegido-1">
                              <img *ngIf="misHabilidades"  src="assets/images/mochila/{{misHabilidades[0].foto}}"/>
                          </div>
                          <div class="div-item-elegido-2">
                              <img *ngIf="misHabilidades"   src="assets/images/mochila/{{misHabilidades[1].foto}}" />
                          </div>
                          <div class="div-item-elegido-3">
                              <img *ngIf="misHerramientas"  src="assets/images/mochila/{{misHerramientas[0].foto}}" />
                          </div>
                          <img class="mi-mochila" src="assets/images/mochila/mochila.svg"/>
                          <div class="sombra-mochila"></div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-12 botonera-mobile" *ngIf="existenCarreras">
                  <button  [disabled]="!contenidoCargado"  class="btn-primary-toulouse" (click)="descargar()">Descarga tu informe completo</button>
          </div>

          <app-entrenadora
              [mensaje]="entrenadora.mensaje"
              [foto]="entrenadora.foto"
              class="col-12 lautrec-message px-0">
          </app-entrenadora>

      </div>
      <div style="height: 0px;width:1150px;overflow-x: auto;overflow-y: auto;">
          <div class="contenedor-custom">
              <div style="height: auto;width: 1200px;background-size: cover;"   [style.background-image]="bgPoder"  #imgGenerada>
                  <div class="align-items-center div-inferior mx-0 position-relative row bg-blue">
                      <div class="col-12 col-md-12 d-flex div-contenido flex-wrap justify-content-end px-3">
                          <div class="col-12 d-flex">
                              <img class="rounded logo" src="assets/images/logos/logo_redwhite.png">
                              <div class="contenedor-resultado-export">
                                  <div class="nick-heroe">
                                      <span>{{miNick}}</span>
                                  </div>
                                  <div class="resultado-div">
                                      <div class="bg-resultado">
                                          <div [innerHTML]="mensajeResultadoDesc"></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="align-items-center d-flex personaje-export">
                                  <div class="d-flex personaje-container align-items-end">
                                      <div class="position-relative avatar">
                                          <!-- <img class="personaje" [src]="personaje"/> -->
                                          <img *ngIf="personaje" class="personaje" src="{{personaje?.split('.svg')[0]}}.png"/>
                                          <img *ngIf="cinturon" class="cinturon" src="assets/images/poderes/{{cinturon?.split('.svg')[0]}}.png"/>
                                          <img *ngIf="tipoCuerpoElegido" class="tipoCuerpo det-tipo {{tipoCuerpoElegido?.codigo}}-puesto" src="assets/images/tipos/{{tipoCuerpoElegido?.foto.split('.svg')[0]}}.png"/>
                                          <img *ngIf="pecheraElegida" class="pechera {{pecheraElegida.codigo}}-puesto" src="assets/images/pecheras/{{pecheraElegida?.foto.split('.svg')[0]}}.png"/>
                                          <img *ngIf="calzadoElegido"class="calzado {{calzadoElegido.codigo}}-puesto" src="assets/images/calzados/{{calzadoElegido?.foto.split('.svg')[0]}}.png"/>
                                          <img *ngIf="cascoElegido"class="miCasco {{cascoElegido.codigo}}-puesto" src="assets/images/cascos/{{cascoElegido?.foto.split('.svg')[0]}}.png"/>
                                          <div class="sombra-personaje"></div>
                                      </div>

                                      <div class="mochila-res position-relative">
                                          <div class="div-item-elegido-0 hab-0">
                                              <img *ngIf="misDones"  src="assets/images/mochila/{{misDones[0]?.foto.split('.svg')[0]}}.png" />
                                          </div>
                                          <div class="div-item-elegido-1 hab-1">
                                              <img *ngIf="misHabilidades"  src="assets/images/mochila/{{misHabilidades[0]?.foto.split('.svg')[0]}}.png"/>
                                          </div>
                                          <div class="div-item-elegido-2 hab-2">
                                              <img *ngIf="misHabilidades"   src="assets/images/mochila/{{misHabilidades[1]?.foto.split('.svg')[0]}}.png" />
                                          </div>
                                          <div class="div-item-elegido-3 hab-3">
                                              <img *ngIf="misHerramientas"  src="assets/images/mochila/{{misHerramientas[0]?.foto.split('.svg')[0]}}.png" />
                                          </div>
                                          <img class="mi-mochila" src="assets/images/mochila/mochila.png"/>
                                          <div class="sombra-mochila"></div>
                                      </div>

                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>

      </div>

  </div>
</div>


<!--MODAL DE FELICITACIONES-->
<app-modal-mensaje
  (clickBoton)="clickBotonModalToulouse($event)"
  [mostrarModal]="mostrarAnuncio"
  [mostrarIconoCierre]="iconoCierre"
  [mostrarIconoDetalle]="false"
  [mostrarBoton]="mostrarBoton"
  [textoBoton]=""
  [titulo]="tituloModal"
  [detalle]="detalleModal">
</app-modal-mensaje>

<div class="progress-aux animate__animated d-none animate__faster" #progress>
  <div class="progress-container">
      <img src="assets/images/loading.gif"/>
      <p class="text-center">Estamos generando la descarga un momento...</p>
  </div>
  <img class="entrenadora-loading" src="assets/images/iconos/entrenadora-loading.svg"/>
</div>