export enum StorageKey {
  analyticsParams = 'AnalitycsParams',
}

const save = (key: StorageKey, payload: any) => {
  sessionStorage.setItem(key, JSON.stringify(payload));
};

const load = <T>(key: StorageKey): T => {
  const data = sessionStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

const remove = (key: StorageKey) => {
  if (!key) {
    Object.keys(StorageKey).forEach((key) =>
      sessionStorage.removeItem(StorageKey[key])
    );
    return;
  }
  sessionStorage.removeItem(key);
};

export const Storage = {
  save,
  load,
  remove,
};
