import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-problemas',
  templateUrl: './problemas.component.html',
  styleUrls: ['./problemas.component.scss'],
})
export class ProblemasComponent implements OnInit {
  //constantes
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  problemas: IAvatar[] = GlobalConstants.PROBLEMAS;

  //Variables de ayuda
  @ViewChildren('refProblema') refProblema: QueryList<ElementRef>;
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  maximoElementosHTML: string = '';

  personaje: string; //Personaje elegido
  cinturon: string; //cinturon del personaje basado en el poder
  tipoCuerpoElegido: IAvatar; //Tipo de cuerpo o gema
  pecheraElegida: IAvatar; //pecheraElegida
  calzadoElegido: IAvatar; //calzado elegido
  cascoElegido: IAvatar; //casco elegido

  //variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  //Problemas por etapa
  etapaActual = 1;
  maxEleccion_1 = 5; //al inicio son 5 problemas como máximo
  problemasElegidos_1: IAvatar[] = [];

  maxEleccion_2 = 3; //al inicio son 5 problemas como máximo
  problemasElegidos_2: IAvatar[] = [];

  maxEleccion_3 = 1; //al inicio son 5 problemas como máximo
  problemasElegidos_3: IAvatar[] = [];

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alerts: AlertsService
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    //this.entrenadora = this.etapaService.cargarMensajesEntrenadora(this.router.url);
    this.obtenerMensajeEntrenadora();
    this.maximoElementosHTML = 'Selecciona 5 problemas que más te preocupan:';
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe((res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;

        if (this.heroeInfo) {
          const genero = this.userInfo.genero.toLocaleLowerCase();
          this.personaje = this.etapaService.obtenerPersonaje(
            'poderes',
            this.heroeInfo.poder[0][genero]
          );
          this.cinturon = this.heroeInfo?.poder[0].auxFoto;
          this.tipoCuerpoElegido = res.heroe?.tipoCuerpo[0];
          this.pecheraElegida = res.heroe?.vestimenta[0];
          this.calzadoElegido = res.heroe?.calzado[0];
          this.cascoElegido = res.heroe?.casco[0];
        }
      });
    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });

    //la primera vez el modal aparecerá
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  obtenerMensajeEntrenadora() {
    const entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
    this.entrenadora = {
      mensaje: entrenadora.mensaje.split(';#')[this.etapaActual - 1],
      foto: entrenadora.foto,
      path: '',
    };
  }

  async siguiente() {
    if (this.etapaActual === 1) {
      if (this.problemasElegidos_1.length < 5) {
        this.alerts.modalInfo(
          null,
          `Debes elegir ${this.maxEleccion_1} problemas`
        );
        return;
      } else {
        //this.maximoElementosHTML = this.maxEleccion_2;
        this.maximoElementosHTML = `Selecciona ${this.maxEleccion_2} problemas que más te preocupan:`;
        this.refProblema.map((res) => {
          res.nativeElement.classList.remove('elegido');
          return res;
        });
        this.problemas = [...this.problemasElegidos_1];

        this.etapaActual++;
        this.obtenerMensajeEntrenadora();
      }
    } else if (this.etapaActual === 2) {
      if (this.problemasElegidos_2.length < 3) {
        this.alerts.modalInfo(
          null,
          `Debes elegir ${this.maxEleccion_2} problemas`
        );
        return;
      } else {
        //this.maximoElementosHTML = this.maxEleccion_3;
        this.maximoElementosHTML = `Selecciona ${this.maxEleccion_3} problema que más te preocupa:`;
        this.refProblema.map((res) => {
          res.nativeElement.classList.remove('elegido');
          return res;
        });
        this.problemas = [...this.problemasElegidos_2];

        this.etapaActual++;
        this.obtenerMensajeEntrenadora();
      }
    } else if (this.etapaActual === 3) {
      if (this.problemasElegidos_3.length < 1) {
        this.alerts.modalInfo(
          null,
          `Debes elegir ${this.maxEleccion_3} problema`
        );
        return;
      } else {
        this.registrarContenido();
      }
    }
  }

  atras() {
    const etapaInfo = this.etapaService.obtenerCodigoEtapa(this.router.url);
    this.router.navigate(['/alumno/etapa', etapaInfo.codigo]);
  }

  async registrarContenido() {
    this.store.dispatch(isLoading());

    //Después actualizamos el poder elegido
    const dataHeroe = {
      CDO: [...this.problemasElegidos_3],
    };
    await this.etapaService.actualizarTest(
      this.userInfo.uuid,
      this.userInfo.flagTrackingTest.uuidTest,
      dataHeroe
    );

    //Primero actualizamos el último path visitado

    //Guardamos en la base de datos
    let habilidadesTexto: any = this.heroeInfo?.habilidades.map(
      (res) => res.titulo + ' - ' + res.descripcion
    );
    habilidadesTexto = habilidadesTexto.join(',');
    const dataUser: IUser = {
      flagTrackingTest: {
        ...this.userInfo.flagTrackingTest,
        lastPath: this.router.url,
      },
      fechFinTest: '',
      misCarreras: [],
      miSuperHeroe: {
        CDO: this.problemasElegidos_3[0].titulo,
        calzado:
          this.heroeInfo?.calzado[0].titulo +
          ' - ' +
          this.heroeInfo?.calzado[0].descripcion,
        casco:
          this.heroeInfo?.casco[0].titulo +
          ' - ' +
          this.heroeInfo?.casco[0].descripcion,
        don:
          this.heroeInfo?.dones[0].titulo +
          ' - ' +
          this.heroeInfo?.dones[0].descripcion,
        habilidades: habilidadesTexto,
        herramientas:
          this.heroeInfo?.habilidades[0].titulo +
          ' - ' +
          this.heroeInfo?.habilidades[0].descripcion,
        nick: '',
        poder:
          this.heroeInfo?.poder[0].titulo +
          ' - ' +
          this.heroeInfo?.poder[0].descripcion,
        proposito: '',
        tipoCuerpo:
          this.heroeInfo?.tipoCuerpo[0].titulo +
          ' - ' +
          this.heroeInfo?.tipoCuerpo[0].descripcion,
        vestimenta:
          this.heroeInfo?.vestimenta[0].titulo +
          ' - ' +
          this.heroeInfo?.vestimenta[0].descripcion,
      },
    };

    this.usuario
      .updUsuarioFireStore(this.userInfo.uuid, dataUser)
      .then((res) => {
        this.store.dispatch(stopLoading());
        this.store.dispatch(
          setUser({ info: { ...this.userInfo, ...dataUser } })
        );
        this.store.dispatch(
          setHeroe({ heroe: { ...this.heroeInfo, ...dataHeroe } })
        );
        this.router.navigate(['/alumno/proposito']);
      })
      .catch((err) => {
        this.store.dispatch(stopLoading());
      });
  }

  elementElegido(refElement: HTMLElement, item: IAvatar) {
    if (this.etapaActual === 1) {
      if (!this.problemasElegidos_1.find((res) => res.codigo === item.codigo)) {
        //Si es true(undefined) o sea no existe agregalo
        if (this.problemasElegidos_1.length < this.maxEleccion_1) {
          this.problemasElegidos_1.push(item);
          refElement?.classList.add('elegido');
        } else {
          this.alerts.modalInfo(
            null,
            'El máximo de elementos a escoger es ' + this.maxEleccion_1
          );
        }
      } else {
        //Sino remuevelo
        this.problemasElegidos_1 = this.problemasElegidos_1.filter(
          (res) => res.codigo !== item.codigo
        );
        refElement?.classList.remove('elegido');
      }
    } else if (this.etapaActual === 2) {
      if (!this.problemasElegidos_2.find((res) => res.codigo === item.codigo)) {
        //Si es true(undefined) o sea no existe agregalo
        if (this.problemasElegidos_2.length < this.maxEleccion_2) {
          this.problemasElegidos_2.push(item);
          refElement?.classList.add('elegido');
        } else {
          this.alerts.modalInfo(
            null,
            'El máximo de elementos a escoger es ' + this.maxEleccion_2
          );
        }
      } else {
        //Sino remuevelo
        this.problemasElegidos_2 = this.problemasElegidos_2.filter(
          (res) => res.codigo !== item.codigo
        );
        refElement?.classList.remove('elegido');
      }
    } else if (this.etapaActual === 3) {
      if (!this.problemasElegidos_3.find((res) => res.codigo === item.codigo)) {
        //Si es true(undefined) o sea no existe agregalo
        if (this.problemasElegidos_3.length < this.maxEleccion_3) {
          this.problemasElegidos_3.push(item);
          refElement?.classList.add('elegido');
        } else {
          this.alerts.modalInfo(
            null,
            'El máximo de elementos a escoger es ' + this.maxEleccion_3
          );
        }
      } else {
        //Sino remuevelo
        this.problemasElegidos_3 = this.problemasElegidos_3.filter(
          (res) => res.codigo !== item.codigo
        );
        refElement?.classList.remove('elegido');
      }
    }
  }

  mostrarAyuda() {
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }
  cerrarModalPadre(valorEmitido) {
    this.mostrarModal = valorEmitido;
  }
}
