<div class="mi-cuerpo">
  <div class="circle-with-lines"></div>
  <div class="container-fluid px-0">
    <app-stage-header
      (goBack)="atras()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
      [showHelper]="false"
    ></app-stage-header>

    <div class="row mx-0 align-items-center avatar-container">
      <div class="col-12 col-md-12 d-flex flex-wrap px-3">
        <div
          class="align-items-center col-12 col-lg-6 col-md-12 d-flex justify-content-center avatar-container__left"
        >
          <h1 class="title-text-white text-center">Elige tu avatar</h1>
        </div>
        <div class="col-md-5 col-12 avatar-container__right">
          <div class="row pl-lg-0">
            <div
              class="col-6 col-md-6 text-center"
              [class.character-container]="generoElegido === generos.HOMBRE"
            >
              <img
                src="assets/images/personajes/personaje_hombre.svg"
                class="avatar"
              />
              <div
                class="select-box"
                (click)="elegirPersonaje(generos.HOMBRE)"
              ></div>
            </div>
            <div
              class="col-6 col-md-6 text-center pl-0"
              [class.character-container]="generoElegido === generos.MUJER"
            >
              <img
                src="assets/images/personajes/personaje_mujer.svg"
                class="avatar"
              />
              <div
                class="select-box"
                (click)="elegirPersonaje(generos.MUJER)"
              ></div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-5">
            <button
              [disabled]="isLoading"
              type="button"
              class="border-light btn btn-primary-toulouse text-white"
              (click)="siguiente()"
            >
              <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
              >{{ isLoading ? "Espere..." : "Siguiente" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="10"
      [totalBars]="1"
    ></app-stage-footer>
  </div>
</div>
