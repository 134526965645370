import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataTableDirective } from 'angular-datatables';
import { forkJoin, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppState } from 'src/app/store/app.store';
import { GlobalConstants } from './../../global/GlobalConstants';
import { EtapaService } from './../../services/etapa.service';
import { ALUMNOS_DATA_BD } from './data';
@Component({
  selector: 'app-alumnos-sin-carrera',
  templateUrl: './alumnos-sin-carrera.component.html',
  styleUrls: ['./alumnos-sin-carrera.component.scss'],
})
export class AlumnosSinCarreraComponent implements OnInit {
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  tokenObtenido = '';
  dtOptions: any = {};
  dtTrigger = new Subject();

  alumnos: any = [];
  procesando = true;
  cargandoUI = false;

  //variables del store
  userInfo: IUser;
  isLoading: boolean;

  ALUMNOS_DATA;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private etapaService: EtapaService,
    private alertService: AlertsService
  ) {
    this.ALUMNOS_DATA = ALUMNOS_DATA_BD;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false,
      ordering: false,
      info: false,
      processing: true,
      pageLength: 100,
      language: {
        lengthMenu: 'Mostrar _MENU_ registros por página',
        zeroRecords: 'Sin resultados',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No hay registros disponibles.',
        infoFiltered: '(filtrado de _MAX_ registros)',
        paginate: {
          previous: '<i class="fas fa-angle-left"></i>',
          next: '<i class="fas fa-angle-right"></i>',
          first: '<i class="fas fa-angle-double-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>',
        },
      },
      dom: 'Bfrtip',
    };

    this.store
      .select('token')
      .pipe(take(2))
      .subscribe((resToken) => {
        if (resToken.tokenData || resToken.tokenData !== '') {
          //this.tokenObtenido = resToken.tokenData;
          //this.cargarTabla();
        }
      });
  }

  obtenerCodigos(alumno) {
    try {
      const soloPoder = alumno.poder.split(' - ')[0];
      const soloCDO = alumno.CDO;
      const soloDones = alumno.don.split(' - ')[0];
      const _habilidades = alumno.habilidades.split('.,');
      const soloHabilidadA = _habilidades[0].split(' - ')[0];
      const soloHabilidadB = _habilidades[1].split(' - ')[0];
      const soloHerramientas = alumno.herramientas.split(' - ')[0];
      const soloCasco = alumno.casco.split(' - ')[0];
      const soloCalzado = alumno.calzado.split(' - ')[0];
      const soloPechera = alumno.vestimenta.split(' - ')[0];

      //OBTENEMOS EL CÓDIG
      const codPoder = GlobalConstants.PODERES.filter(
        (pod) => pod.titulo.toUpperCase() === soloPoder.toUpperCase()
      )[0].codigo;
      const codODS = GlobalConstants.PROBLEMAS.filter(
        (prob) => prob.titulo.toUpperCase() === soloCDO.toUpperCase()
      )[0].codigo;
      const codDones = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloDones.toUpperCase()
      )[0].codigo;
      const codHabilidadA = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHabilidadA.toUpperCase()
      )[0].codigo;
      const codHabilidadB = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHabilidadB.toUpperCase()
      )[0].codigo;
      const codHerramientas = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHerramientas.toUpperCase()
      )[0].codigo;

      const codCasco = GlobalConstants.CASCOS.filter(
        (casc) => casc.titulo.toUpperCase() === soloCasco.toUpperCase()
      )[0].codigo;
      const codCalzado = GlobalConstants.CALZADO.filter(
        (calz) => calz.titulo.toUpperCase() === soloCalzado.toUpperCase()
      )[0].codigo;
      const codPechera = GlobalConstants.PECHERAS.filter(
        (pech) => pech.titulo.toUpperCase() === soloPechera.toUpperCase()
      )[0].codigo;

      return {
        codPoder,
        codODS,
        codDones,
        codHabilidadA,
        codHabilidadB,
        codHerramientas,
        codCasco,
        codCalzado,
        codPechera,
        error: false,
      };
    } catch (e) {
      return {
        error: true,
      };
    }
  }

  filtrarCarrerasYOrdenarlasNueva(
    carrerasFiltradas,
    codigoPoder,
    CDOCodigo,
    donCodigo,
    hab1Codigo,
    hab2Codigo,
    herrCodigo,
    cascoCodigo,
    calzadoCodigo,
    vestimentaCodigo
  ) {
    let carrerasConPuntajes = [];
    carrerasConPuntajes = carrerasFiltradas.map((carr) => {
      let _puntaje = 0;

      //Si tiene poder +6
      if (carr.poderes?.hasOwnProperty(codigoPoder)) {
        _puntaje += 6;
      }

      //Si tiene CDO +1
      if (carr.CDO?.hasOwnProperty(CDOCodigo)) {
        _puntaje += 1;
      }

      //Si tiene habilidad - dones - herramienta +2
      if (carr.habilidades?.hasOwnProperty(donCodigo)) {
        _puntaje += 2;
      }
      if (carr.habilidades?.hasOwnProperty(hab1Codigo)) {
        _puntaje += 2;
      }
      if (carr.habilidades?.hasOwnProperty(hab2Codigo)) {
        _puntaje += 2;
      }
      if (carr.habilidades?.hasOwnProperty(herrCodigo)) {
        _puntaje += 2;
      }

      //Si tiene casco +3
      if (carr.cascos?.hasOwnProperty(cascoCodigo)) {
        _puntaje += 3;
      }

      //Si tiene zapato +2
      if (carr.calzados?.hasOwnProperty(calzadoCodigo)) {
        _puntaje += 2;
      }

      //Si tiene ropa +1
      if (carr.trajes?.hasOwnProperty(vestimentaCodigo)) {
        _puntaje += 1;
      }

      return {
        ...carr,
        puntaje: _puntaje,
      };
    });

    const top5Carreras = carrerasConPuntajes
      .sort((a, b) => b.puntaje - a.puntaje)
      .slice(0, 5);
    return top5Carreras;
  }

  cargarTabla() {
    this.http
      .get(`${GlobalConstants.admin.alumnosSinCarreras}`, {
        headers: {
          authorization: `Bearer ${this.tokenObtenido}`,
        },
      })
      .subscribe((alumnos: any) => {
        this.alumnos = alumnos;
        this.dtTrigger.next();
        this.procesando = false;
      });
  }

  reset() {
    this.procesando = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();

      this.cargarTabla();
    });
  }

  async listarCarreras() {
    this.cargandoUI = true;
    const arrCarrerasAlumnos = [];
    const alumnosDataFinal = [];
    this.alumnos.map((alu) => {
      const soloPoder = alu.poder.split(' - ')[0];
      const soloCasco = alu.casco.split(' - ')[0];
      const soloCalzado = alu.calzado.split(' - ')[0];
      const soloPechera = alu.vestimenta.split(' - ')[0];
      const soloHerramientas = alu.herramientas.split(' - ')[0];
      const soloDones = alu.don.split(' - ')[0];
      //const ODS = alu.ODS

      const _habilidades = alu.habilidades.split('.,');
      const soloHabilidadA = _habilidades[0].split(' - ')[0];
      const soloHabilidadB = _habilidades[1].split(' - ')[0];

      const codPoder = GlobalConstants.PODERES.filter(
        (pod) => pod.titulo.toUpperCase() === soloPoder.toUpperCase()
      )[0].codigo;
      const codCasco = GlobalConstants.CASCOS.filter(
        (casc) => casc.titulo.toUpperCase() === soloCasco.toUpperCase()
      )[0].codigo;
      const codCalzado = GlobalConstants.CALZADO.filter(
        (calz) => calz.titulo.toUpperCase() === soloCalzado.toUpperCase()
      )[0].codigo;
      const codPechera = GlobalConstants.PECHERAS.filter(
        (pech) => pech.titulo.toUpperCase() === soloPechera.toUpperCase()
      )[0].codigo;

      const codHerramientas = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHerramientas.toUpperCase()
      )[0].codigo;
      const codDones = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloDones.toUpperCase()
      )[0].codigo;
      const codHabilidadA = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHabilidadA.toUpperCase()
      )[0].codigo;
      const codHabilidadB = GlobalConstants.MOCHILA.filter(
        (moch) => moch.titulo.toUpperCase() === soloHabilidadB.toUpperCase()
      )[0].codigo;

      alumnosDataFinal.push({
        id: alu.id,
        codCalzado,
        codPechera,
        codHerramientas,
        codDones,
        codHabilidadA,
        codHabilidadB,
        carrerasDOC: [],
        carreras: [],
      });
      arrCarrerasAlumnos.push(this.obtenerCarreras(codPoder, codCasco));
    });

    //Obtenemos las carreras que coincidan
    Promise.all(arrCarrerasAlumnos)
      .then((resCarrerasAlumnos) => {
        resCarrerasAlumnos.map((resCA, idx) => {
          if (resCA.docs.length > 0) {
            resCA.docs.map((res) => {
              alumnosDataFinal[idx].carrerasDOC.push(res.data());
            });
          }
        });

        const alumnosActualizados = alumnosDataFinal.map((dataFinal) => {
          const habilidades = [
            {
              codigo: dataFinal.codHerramientas,
            },
            {
              codigo: dataFinal.codDones,
            },
            {
              codigo: dataFinal.codHabilidadA,
            },
            {
              codigo: dataFinal.codHabilidadB,
            },
          ];
          dataFinal.carreras = this.filtrarCarrerasYOrdenarlas(
            habilidades,
            dataFinal.codCalzado,
            dataFinal.pecheraCodigo,
            dataFinal.carrerasDOC
          ).map((carr) => carr.nombre);
          return dataFinal;
        });

        //Enviamos los datos actualziados
        const _arrUPDCarrerasAlumnos = [];
        alumnosActualizados.map((dataUpd) => {
          if (dataUpd.carreras.length > 0) {
            _arrUPDCarrerasAlumnos.push(
              this.http.put(
                GlobalConstants.admin.editarAlumnosSinCarrera,
                {
                  alumnoID: dataUpd.id,
                  carreras: {
                    carreras: dataUpd.carreras,
                  },
                },
                {
                  headers: {
                    authorization: `Bearer ${this.tokenObtenido}`,
                  },
                }
              )
            );
          }
        });

        if (_arrUPDCarrerasAlumnos.length === 0) {
          this.alertService.modalAlert(
            'Oops',
            `No hay carreras para asignar a esta lista de alumnos`
          );
          this.cargandoUI = false;
          return;
        }

        forkJoin(_arrUPDCarrerasAlumnos).subscribe(
          (resNuevasCarreras) => {
            this.cargandoUI = false;
            this.alertService
              .modalInfo(
                '',
                `La información se actualizó correctamente. ${_arrUPDCarrerasAlumnos.length} registro(s) cambiado(s)`
              )
              .then((res) => {
                this.reset();
              });
          },
          (err) => {
            this.cargandoUI = false;
            this.alertService.modalAlert(
              'Oops',
              `Sucedio un error al intentar actualizar la información. ${err}`
            );
          }
        );
      })
      .catch((err) => {
        this.alertService.modalAlert(
          'Oops',
          `Sucedio un error al intentar actualizar la información. ${err}`
        );
        this.cargandoUI = false;
      });
  }

  obtenerCarreras(poderCodigo, cascoCodigo) {
    return this.etapaService.cargarCarreras(poderCodigo, cascoCodigo);
  }

  filtrarCarrerasYOrdenarlas(
    habilidades,
    calzadoCodigo,
    pecheraCodigo,
    carrerasFiltradas
  ) {
    const carrerasData = [];
    const _habilidades = [...habilidades];
    carrerasFiltradas.map((carrera: any) => {
      //Iteramos cada resultado de carreras
      let contHabilidades = 0; //Guardamos la cantidad de coincidencia sobre cada registro
      Object.keys(carrera.habilidades).map((habilidad) => {
        //Iteramos solo el objeto habilidades quedandonos con su prop como valor
        _habilidades.map((habElegidas) => {
          if (habElegidas.codigo === habilidad) {
            contHabilidades++;
          }
        });
      });

      if (contHabilidades >= 2) {
        carrerasData.push({
          ...carrera,
          contHabCoinciden: contHabilidades,
        });
      }
    });
    carrerasData.sort((a, b) => b.contHabCoinciden - a.contHabCoinciden);

    let res_4 = [],
      res_3 = [],
      res_2 = [];
    let hab_4 = carrerasData.filter((res) => res.contHabCoinciden === 4);
    let hab_3 = carrerasData.filter((res) => res.contHabCoinciden === 3);
    let hab_2 = carrerasData.filter((res) => res.contHabCoinciden === 2);

    if (hab_4.length === 1) {
      res_4.push(...hab_4);
    } else {
      res_4.push(
        ...res_4,
        ...this.iterarCalzadoTrajes(hab_4, calzadoCodigo, pecheraCodigo)
      );
    }

    if (hab_3.length === 1) {
      res_3.push(...hab_3);
    } else {
      res_3.push(
        ...res_3,
        ...this.iterarCalzadoTrajes(hab_3, calzadoCodigo, pecheraCodigo)
      );
    }

    if (hab_2.length === 1) {
      res_2.push(...hab_2);
    } else {
      res_2.push(
        ...res_2,
        ...this.iterarCalzadoTrajes(hab_2, calzadoCodigo, pecheraCodigo)
      );
    }

    let resultadoFinal = [
      ...this.ordernarSegunCriterio(res_4),
      ...this.ordernarSegunCriterio(res_3),
      ...this.ordernarSegunCriterio(res_2),
    ];
    return resultadoFinal.length > 4
      ? resultadoFinal.slice(0, 4)
      : resultadoFinal;
  }

  iterarCalzadoTrajes(datos, calzadoCodigo, pecheraCodigo) {
    let res = [];
    datos.map((carrera: any) => {
      let contCalzados = 0,
        contTrajes = 0;
      Object.keys(carrera.calzados).map((calzado) => {
        if (calzadoCodigo === calzado) {
          contCalzados++;
        }
      });

      Object.keys(carrera.trajes).map((traje) => {
        if (pecheraCodigo === traje) {
          contTrajes++;
        }
      });

      res.push({
        ...carrera,
        contCalzadosCoinciden: contCalzados,
        contTrajesCoinciden: contTrajes,
      });
    });
    return res.sort((a, b) => {
      return (
        b.contCalzadosCoinciden - a.contCalzadosCoinciden ||
        b.contTrajesCoinciden - a.contTrajesCoinciden
      );
    });
  }
  ordernarSegunCriterio(datos) {
    let resCriterio = [];
    if (datos.length > 4) {
      let random = this.getRandom(datos, 4);
      let res_order = random.sort(this.compare);

      resCriterio.push(...res_order);
    } else if (datos.length > 1 && datos.length <= 4) {
      resCriterio.push(...datos);
    } else if (datos.length === 1) {
      resCriterio.push(...datos);
    }
    return resCriterio;
  }
  getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  compare(a, b) {
    if (a.nombre < b.nombre) {
      return -1;
    }
    if (a.nombre > b.nombre) {
      return 1;
    }
    return 0;
  }
}
