import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-mensaje',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() mostrarModal: boolean = false;
  @Input() titulo: string = '';
  @Input() detalle: string = '';
  @Input() mostrarIconoCierre: boolean = true;
  @Input() mostrarIconoDetalle: boolean = true;
  @Input() mostrarBoton: boolean = false;
  @Input() textoBoton: string = 'Aceptar';
  @Output() clickBoton = new EventEmitter<boolean>();
  @Output() cerrarModal = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  cerrarModalToulouse() {
    this.mostrarModal = false;
    this.cerrarModal.emit(false);
  }

  clickBotonModal() {
    this.clickBoton.emit(true);
  }
}
