import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  modalInfo(titulo: string = 'Espera...', mensaje: string) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  }
  modalError(titulo: string = 'Espera...', mensaje: string) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  }
  modalAlert(titulo: string = 'Espera...', mensaje: string) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  }

  modalAlertPregunta(titulo, mensaje) {
    return Swal.fire({
      title: (titulo) ? titulo : 'Espera...',
      text: mensaje,
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sí',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, cancelar.'
    });
  }

  modalSuccess(titulo, mensaje) {
    return Swal.fire({
      title: (titulo) ? titulo : 'Correcto',
      text: mensaje,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    });
  }

}
