import { createReducer, on } from '@ngrx/store';
import { isLoading, stopLoading } from './../actions/ui.action';


/*
Creamos una interfaz para específicar a través de ella
que propiedades tendrá mi State
*/
export interface State {
    isLoading: boolean;
}

/*
Creamos un estado inicial para mi reducer el cual estará basado en las propiedades
declaradas en la interfaz
*/
export const initialState: State = {
    isLoading: null
}

//creamos una const que almacene cada una de las acciones del reducer
/*
el on es parte de ngrx y tiene como parametros la ACCION y props donde estas props
son el STATE y los parametros que puede necesitar la acción validada.
El STATE es una devolución del on el cual genera una copia del state actual o initialState de este reducer OJO de este no comparte otros reducer
asi que al imprimirlo para testear solo traera las propiedades que se declaren en el initialState
*/
const _uiReducer = createReducer(initialState,
    on(isLoading, (state) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(stopLoading, (state) => {
        return {
            ...state,
            isLoading: false
        };
    })
);

//haremos que solo sea accesible una función exportada a este reducer
//esta funcion recibirá como parametros el state y action
//estos valores serán seteados desde el dispatch de manera indirecta
//esta funcion no se utiliza de manera directa se configura en el reducer de nuestra aplicación o global
export function uiReducer(state, action) {
    return _uiReducer(state, action);
}