<div class="calzate">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 text-right otrosDevices div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>
      <div
        class="avatar-container col-12 col-md-12 div-contenido d-flex flex-wrap px-3"
      >
        <div
          class="left-title-container align-items-center col-12 col-md-4 d-flex justify-content-center my-2 flex-width-100"
        >
          <h1 class="title-text-white text-center">Cálzate</h1>
        </div>

        <div class="col-12 m-0 row div-detalle-responsive">
          <div>
            <h4>{{ calzadoElegido ? calzadoElegido.titulo : "" }}</h4>
          </div>
        </div>
        <div class="descripcion-responsive d-none col-12 text-white mt-2 mb-3">
          <p>{{ calzadoElegido?.descripcion }}</p>
        </div>

        <div class="col-md-8 col-12 flex-width-100">
          <div class="row justify-content-center align-items-center">
            <div class="col-6 col-md-5 d-flex opciones row flex-width-100">
              <div
                *ngFor="let calzado of calzados; let i = index"
                class="align-items-center col-6 col-md-6 d-flex justify-content-center py-2"
              >
                <div
                  class="div-ropa-eleccion"
                  [class.elegido]="calzado.codigo === calzadoElegido?.codigo"
                  (click)="elegirCalzado(i, calzado)"
                >
                  <img
                    src="assets/images/calzados/{{ calzado.foto }}"
                    class="calzado-{{ i }}"
                  />
                  <label>{{ calzado.titulo }}</label>
                </div>
              </div>
            </div>
            <div
              class="align-items-center col-6 col-md-3 d-flex personaje-container justify-content-center"
            >
              <div class="position-relative avatar">
                <img class="personaje" [src]="personaje" />
                <img
                  *ngIf="cinturon"
                  class="cinturon"
                  src="assets/images/poderes/{{ cinturon }}"
                />
                <img
                  *ngIf="tipoCuerpoElegido"
                  class="tipoCuerpo det-tipo {{
                    tipoCuerpoElegido?.codigo
                  }}-puesto"
                  src="assets/images/tipos/{{ tipoCuerpoElegido?.foto }}"
                />
                <img
                  *ngIf="pecheraElegida"
                  class="pechera {{ pecheraElegida.codigo }}-puesto"
                  src="assets/images/pecheras/{{ pecheraElegida?.foto }}"
                />
                <img
                  *ngIf="calzadoElegido"
                  class="calzado {{ calzadoElegido.codigo }}-puesto"
                  src="assets/images/calzados/{{ calzadoElegido?.foto }}"
                  #avatarElegido
                />
              </div>
            </div>
            <div
              class="col-12 col-md-4 d-flex detalle-opcion flex-column justify-content-end"
            >
              <div class="nombre otrosDevices">
                {{ calzadoElegido?.titulo }}
              </div>
              <div class="detalle otrosDevices">
                <img
                  *ngIf="calzadoElegido"
                  src="assets/images/calzados/{{ calzadoElegido?.foto }}"
                />
                <label>{{ calzadoElegido?.descripcion }}</label>
              </div>
              <button
                [disabled]="isLoading"
                type="button"
                class="btn-primary-toulouse mt-4 w-100 d-flex justify-content-center"
                (click)="siguiente()"
              >
                <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
                ><span>{{ isLoading ? "Espere..." : "Siguiente" }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="50"
      [totalBars]="5"
    ></app-stage-footer>
  </div>
</div>
<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
