import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalConstants } from '../../global/GlobalConstants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;

  constructor(private router: Router, private alerts: AlertsService, private auth: AuthService) { }

  ngOnInit(): void {
  }

  cerrarSesion() {
    //Llamo a lmetodo cerrar sesión que redirige al login
    this.auth.cerrarSesion().then(res => {
      this.router.navigate(['/login']);
    }).catch(err => {
      this.alerts.modalError(null, 'Sucedio un error:' + err);
    });
  }
}
