<div class="admin_form admin">
    <div class="circulo-lineas"></div>
    <div class="circulo"></div>

    <div class="container-fluid full-vh animate__animated animate__faster animate__zoomIn">
        <div class="row justify-content-between div-superior position-relative">
            <img src="assets/images/logos/{{ logo }}" class="logo" />
        </div>

        <div class="row mx-0 align-items-center">
            <div class="col-12 text-center">
                <h1 class="title-text-white text-center">Menú Administrador</h1>
                <button class="my-2 btn btn-outline  text-white opciones-menu" (click)="cerrarSesion()">
                    Cerrar Sesión
                </button>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-6 col-12 m-auto">
                <ul class="tls-alto">
                    <li>
                        <a [routerLink]="['/admin/descargas']" class="row menu-item">
                            <div class="col-lg-3 col-2 text-center">
                                <i class="fas fa-cloud-download-alt"></i>
                            </div>
                            <div class="col-lg-9 col-10 font-weight-bold">
                                Módulo de Descargas
                            </div>
                        </a>
                    </li>
<!--                     <li>
                        <a [routerLink]="['/admin/sin-carrera']" class="row menu-item">
                            <div class="col-lg-3 col-2 text-center">
                                <i class="fas fa-graduation-cap"></i>
                            </div>
                            <div class="col-lg-9 col-10 font-weight-bold">
                                Alumnos sin carrera
                            </div>
                        </a>
                    </li> -->
                    <li>
                        <a [routerLink]="['/admin/carreras']" class="row menu-item">
                            <div class="col-lg-3 col-2 text-center">
                                <i class="fas fa-graduation-cap"></i>
                            </div>
                            <div class="col-lg-9 col-10 font-weight-bold">
                                Maestro de Carreras
                            </div>
                        </a>
                    </li>
<!--                     <li>
                        <a [routerLink]="['/admin/descargas']" class="row menu-item">
                            <div class="col-lg-3 col-2 text-center">
                                <i class="fas fa-cloud-download-alt"></i>
                            </div>
                            <div class="col-lg-9 col-10 font-weight-bold">
                                Matriz de Carreras
                            </div>
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</div>