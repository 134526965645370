<div class="modal" [ngClass]="{' d-block show': mostrarModal}" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrarModalToulouse()">
                    <img src="../../../assets/images/iconos/icono-x.svg"/>
                </button>
            </div>
            <div class="modal-body">
                <div class="superior">
                    <label>Cambiar Contraseña</label>
                </div>
                <div class="inferior">
                    <p class="text-center">
                      Por favor ingrese su correo electrónico en la casilla inferior y presione en solicitar cambio.
                    </p>
                    <div class="my-3">
                        <input type="text" class="form-control-retro" [(ngModel)]="correo" placeholder="micorreo@example.com"/>
                        <label class="mt-2" *ngIf="validarCampo">Debe ingresar su correo</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer m-auto">
                <button class="btn btn-outline-danger" type="button" (click)="cambiarPasswordToulouse()">Solicitar Cambio</button>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{' modal-backdrop show': mostrarModal}"></div>
