import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuth().pipe(
      map((authState) => {
        if (authState) {//si esta autenticado que se muestre el componente
          return true;
        } else {
          //sino que retorne al login
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}
