import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stage-footer',
  templateUrl: './stage-footer.component.html',
  styleUrls: ['./stage-footer.component.scss'],
})
export class StageFooterComponent implements OnInit {
  @Input() percentage = 0;
  @Input() totalBars = 0;
  @Output()
  public goBack = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}
  public handleGoBack(event: MouseEvent) {
    this.goBack.emit(event);
  }
}
