import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IMensajesPopup } from 'src/app/interfaces/IMensajesPopup';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setHeroe, setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';
@Component({
  selector: 'app-poder',
  templateUrl: './poder.component.html',
  styleUrls: ['./poder.component.scss'],
})
export class PoderComponent implements OnInit {
  //Variables globales
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  poderes: IAvatar[] = GlobalConstants.PODERES;

  //Variables de apoyo
  mensajesPopup: IMensajesPopup;
  entrenadora: IMensajesEntrenadora;
  mostrarModal: boolean = false;
  tituloModal: string = '';
  detalleModal: string = '';
  cinturon: string = '';
  personaje: string; //personaje (H o M)
  poderElegido: IAvatar; //Poder elegido previamente

  //variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  isLoading: boolean;

  //temps
  refTemp: any = [];

  constructor(
    private router: Router,
    private etapaService: EtapaService,
    private usuario: UserService,
    private store: Store<AppState>,
    private alert: AlertsService
  ) {
    this.mensajesPopup = this.etapaService.cargarMensajesPopup(this.router.url);
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe((res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;

        if (this.heroeInfo) {
          this.poderElegido =
            res.heroe.poder.length > 0 ? res.heroe.poder[0] : null;
          const genero = this.userInfo.genero.toLocaleLowerCase();
          if (this.heroeInfo.poder.length === 0) {
            this.personaje = this.etapaService.obtenerPersonaje(
              'personajes',
              `personaje_${genero}`
            );
          } else {
            this.personaje = this.etapaService.obtenerPersonaje(
              'poderes',
              this.heroeInfo.poder[0][genero]
            );
            this.cinturon = this.heroeInfo.poder[0].auxFoto;
          }
        }
      });
    this.store
      .select('ui')
      .pipe(take(3))
      .subscribe((res) => {
        this.isLoading = res.isLoading;
      });

    //la primera vez el modal aparecerá de manera directa
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeDirecto.titulo;
    this.detalleModal = this.mensajesPopup.mensajeDirecto.mensaje;
  }

  mostrarAyuda() {
    //Aparece cada vez que se presiona en ayuda
    this.mostrarModal = true;
    this.tituloModal = this.mensajesPopup.mensajeAyuda.titulo;
    this.detalleModal = this.mensajesPopup.mensajeAyuda.mensaje;
  }

  cerrarModalPadre(valorEmitido) {
    //Vinculado al output del modal
    this.mostrarModal = valorEmitido;
  }

  iconClick(poder: HTMLElement, pos: string, poderElegido: IAvatar) {
    this.poderElegido = poderElegido;
    this.cinturon = poderElegido.auxFoto;
    this.personaje = this.etapaService.obtenerPersonaje(
      'poderes',
      poderElegido[this.userInfo.genero.toLocaleLowerCase()]
    );
    this.effectConDom(poder, pos);

    //agregamos como referencia el que se hizo click
    this.refTemp.push(poder);
  }

  async siguiente() {
    if (!this.poderElegido) {
      //Si es null o vacio
      this.alert.modalInfo(null, 'Debe elegir un poder para avanzar');
    } else {
      this.store.dispatch(isLoading());

      //Después actualizamos el poder elegido
      const dataHeroe = {
        poder: [this.poderElegido],
      };
      await this.etapaService.actualizarTest(
        this.userInfo.uuid,
        this.userInfo.flagTrackingTest.uuidTest,
        dataHeroe
      );

      //Primero actualizamos el último path visitado
      const dataUser: IUser = {
        flagTrackingTest: {
          ...this.userInfo.flagTrackingTest, //copiamos lo que tenga actualmente el flagTrackingTest
          etapasPath: this.router.url,
          lastPath: this.router.url, //Actualizamos el último path visitado que será esta página
        },
        fechFinTest: '',
        misCarreras: [],
        miSuperHeroe: {
          CDO: '',
          calzado: '',
          casco: '',
          don: '',
          habilidades: '',
          herramientas: '',
          nick: '',
          poder:
            this.poderElegido.titulo + ' - ' + this.poderElegido.descripcion,
          proposito: '',
          tipoCuerpo: '',
          vestimenta: '',
        },
      };

      this.usuario
        .updUsuarioFireStore(this.userInfo.uuid, dataUser)
        .then((res) => {
          this.store.dispatch(stopLoading());
          this.store.dispatch(
            setUser({
              info: {
                ...this.userInfo,
                ...dataUser,
              },
            })
          );
          this.store.dispatch(
            setHeroe({
              heroe: {
                ...this.heroeInfo,
                ...dataHeroe,
              },
            })
          );
          this.router.navigate(['/alumno/etapa']);
        })
        .catch((err) => {
          this.store.dispatch(stopLoading());
          this.alert.modalError(null, 'Sucedio un error:' + err);
        });
    }
  }

  //Efecto de abrir y cerrar
  effectConDom(poder: HTMLElement, pos: string) {
    const itemDetalle = poder.querySelector('.power-item-container__detalle');
    const contenido = poder.querySelector('.contenido');
    contenido.classList.remove('d-none');
    itemDetalle.classList.remove('hide-effect');
    contenido.classList.add('animate__fadeIn', 'animate__slower');

    if (pos === 'IZQ') {
      this.validarCierreCondicionado(poder);

      if (itemDetalle.className.indexOf('show-left') > -1) {
        contenido.classList.add('d-none');
        itemDetalle.classList.remove('show-left');
        itemDetalle.classList.add('hide-effect');
        return;
      }
      itemDetalle.classList.add('show-left');
    } else {
      this.validarCierreCondicionado(poder);

      if (itemDetalle.className.indexOf('show-right') > -1) {
        contenido.classList.add('d-none');
        itemDetalle.classList.remove('show-right');
        itemDetalle.classList.add('hide-effect');
        return;
      }
      itemDetalle.classList.add('show-right');
    }
  }

  validarCierreCondicionado(poder: HTMLElement) {
    if (this.refTemp.length > 0 && poder !== this.refTemp[0]) {
      const refContent = this.refTemp[0].querySelector('.contenido');
      const refItem = this.refTemp[0].querySelector(
        '.power-item-container__detalle'
      );

      refContent.classList.add('d-none');

      if (refContent.classList.value.indexOf('power-left-content') > -1) {
        refItem.classList.remove('show-left');
      } else {
        refItem.classList.remove('show-right');
      }
      refItem.classList.add('hide-effect');
      this.refTemp = [];
    }
  }

  atras() {
    this.router.navigate(['/alumno/mi-cuerpo']);
  }
}
