import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import html2canvas from 'html2canvas';
// RxJS v6+
import { interval } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { EventType, FlowStage, FlowState } from 'src/app/interfaces/Emblue';
import { IAvatar } from 'src/app/interfaces/IAvatar';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IDetalleSuperHeroe, IUser } from 'src/app/interfaces/IUser';
import { EmblueService } from 'src/app/services/emblue.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

//en el tsconfig agregar en compilerOptions : "allowSyntheticDefaultImports": true

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.scss'],
})
export class ResultadoComponent implements OnInit, AfterViewInit {
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  entrenadora: IMensajesEntrenadora;
  mensajeResultado: string = '';
  mensajeResultadoDesc: string = '';
  carrerasObtenidas = [];
  todasLasCarrerasObtenidas = [];
  carrerasToulouse = [];
  miProposito: string;
  misHabilidadesText: string;
  miNick: string;
  personaje: string; //Personaje elegido
  cinturon: string; //cinturon del personaje basado en el poder
  tipoCuerpoElegido: IAvatar; //Tipo de cuerpo o gema
  poderElegido: IAvatar;
  pecheraElegida: IAvatar; //pecheraElegida
  calzadoElegido: IAvatar; //calzado elegido
  cascoElegido: IAvatar; //casco elegido
  misDones: IAvatar[];
  misHabilidades: IAvatar[];
  misHerramientas: IAvatar[];
  @ViewChild('resultadosContenido') resultadosContenido: ElementRef;
  @ViewChild('progress') progress: ElementRef;
  @ViewChild('imgGenerada') imgGenerada: ElementRef;
  bgPoder: string = '';
  mostrarAnuncio: boolean = false;
  tituloModal: string = '¡Tenemos un lugar para ti!';
  detalleModal: string = ``;
  presionoSalir = false;
  interval30Segundos;
  iconoCierre = true;
  mostrarBoton = false;
  existenCarreras: boolean = false;
  //variables del store
  userInfo: IUser;
  heroeInfo: IDetalleSuperHeroe;
  contenidoCargado: boolean = false;

  constructor(
    private etapaService: EtapaService,
    private router: Router,
    private store: Store<AppState>,
    private emblue: EmblueService,
    private usuario: UserService
  ) {
    this.entrenadora = this.etapaService.cargarMensajesEntrenadora(
      this.router.url
    );
  }

  ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(3))
      .subscribe(async (res) => {
        this.userInfo = res.info;
        this.heroeInfo = res.heroe;
        if (this.userInfo) {
          this.carrerasObtenidas = this.userInfo.misCarreras;
          this.carrerasToulouse = this.userInfo.misCarrerasToulouse;
          this.todasLasCarrerasObtenidas =
            this.userInfo.todasLasCarrerasObtenidas;

          if (this.userInfo.misCarrerasToulouse.length > 0) {
            this.mostrarAnuncio = true;
          }
        }
        if (this.heroeInfo) {
          const genero = this.userInfo?.genero.toLocaleLowerCase();
          this.personaje = this.etapaService.obtenerPersonaje(
            'poderes',
            this.heroeInfo?.poder[0][genero]
          );
          this.cinturon = this.heroeInfo?.poder[0].auxFoto;
          this.poderElegido = res.heroe.poder[0];
          this.tipoCuerpoElegido = res.heroe?.tipoCuerpo[0];
          this.miNick = res.heroe?.nick;
          this.pecheraElegida = res.heroe?.vestimenta[0];
          this.calzadoElegido = res.heroe?.calzado[0];
          this.cascoElegido = res.heroe?.casco[0];
          this.misDones = res.heroe?.dones;
          this.misHabilidades = res.heroe?.habilidades;
          this.misHerramientas = res.heroe?.herramientas;
          this.miProposito = res.heroe?.proposito;

          let soloHabilidades = this.misHabilidades.map((res) => res.titulo);
          this.misHabilidadesText = soloHabilidades.join(', ');

          this.contenidoCargado = true;
          //Seteamos el background dinamicamente
          this.bgPoder = `url('../assets/images/fondos/fondo-${this.heroeInfo?.poder[0].codigo}.png')`;

          if (this.carrerasObtenidas.length > 3) {
            this.existenCarreras = true;

            this.mensajeResultado = `Su propósito en la vida es ${
              this.miProposito
            }<br/><br/> <div>Las carreras más adecuadas para que <span class="nick">${
              this.miNick
            }</span> despliegue todo su potencial y logre cumplir su súper misión según sus intereses y habilidades son: <span class="carreras">${this.carrerasObtenidas.join(
              ', '
            )}</span>.
        </div>`;
          } else if (
            this.carrerasObtenidas.length > 1 &&
            this.carrerasObtenidas.length <= 3
          ) {
            this.existenCarreras = true;

            this.mensajeResultado = `Su propósito en la vida es ${
              this.miProposito
            }<br/><br/> <div>Las carreras más adecuadas para que <span class="nick">${
              this.miNick
            }</span> despliegue todo su potencial y logre cumplir su súper misión según sus intereses y habilidades son: <span  class="carreras">${this.carrerasObtenidas.join(
              ', '
            )}</span>.</div>`;
          } else if (this.carrerasObtenidas.length === 1) {
            this.existenCarreras = true;

            this.mensajeResultado = `Su propósito en la vida es ${
              this.miProposito
            }<br/><br/> <div>La carrera más adecuada para que <span class="nick">${
              this.miNick
            }</span> despliegue todo su potencial y logre cumplir su súper misión según sus intereses y habilidades es: <span  class="carreras">${this.carrerasObtenidas.join(
              ''
            )}</span>.</div>`;
          } else {
            this.existenCarreras = false;
            this.mensajeResultado = `¡Hola! Nuestra central de superhéroes está procesado tus resultados y te contactará para entregártelos a la brevedad.`;
          }

          this.mensajeResultadoDesc = `Su propósito en la vida es ${
            this.miProposito
          }<br/><br/>
        <div class="subtitle">Para lograrlo está equipado con:</div>
          <ul>
            <li><span>Gema de poder:</span> ${this.poderElegido.titulo}</li>
            <li><span>${this.tipoCuerpoElegido.titulo}:</span> ${
            this.tipoCuerpoElegido.descripcion
          }</li>
            <li><span>${this.pecheraElegida.titulo}:</span> ${
            this.pecheraElegida.descripcion
          }</li>
            <li><span>${this.calzadoElegido.titulo}:</span> ${
            this.calzadoElegido.descripcion
          }</li>
            <li><span>${this.cascoElegido.titulo}:</span> ${
            this.cascoElegido.descripcion
          }</li>
          </ul>
          <br/>

          <div class="subtitle">Sus habilidades clave son:</div>
          <ul class="lista-item">
            <li>
               <div>
                  <div class="item-elegido"><img src="assets/images/mochila/${
                    this.misDones[0].foto.split('.svg')[0]
                  }.png"/></div>
                  <div class="item-detalle"><span>${
                    this.misDones[0].titulo
                  }: </span> ${this.misDones[0].descripcion}</div>
               </div>
            </li>
            <li>
               <div>
                  <div class="item-elegido"><img src="assets/images/mochila/${
                    this.misHabilidades[0].foto.split('.svg')[0]
                  }.png"/></div>
                  <div class="item-detalle"><span>${
                    this.misHabilidades[0].titulo
                  }: </span> ${this.misHabilidades[0].descripcion}</div>
               </div>
            </li>
            <li>
               <div>
                  <div class="item-elegido"><img src="assets/images/mochila/${
                    this.misHabilidades[1].foto.split('.svg')[0]
                  }.png"/></div>
                  <div class="item-detalle"><span>${
                    this.misHabilidades[1].titulo
                  }: </span> ${this.misHabilidades[1].descripcion}</div>
               </div>
            </li>
            <li>
               <div>
                  <div class="item-elegido"><img src="assets/images/mochila/${
                    this.misHerramientas[0].foto.split('.svg')[0]
                  }.png"/></div>
                  <div class="item-detalle"><span>${
                    this.misHerramientas[0].titulo
                  }: </span> ${this.misHerramientas[0].descripcion}</div>
               </div>
            </li>
          </ul>
          <br/>
          <span>
            Las carreras que le pueden ayudar a cumplir su propósito son:
          </span><br/>
          <div>
            <span class="carreras">${this.carrerasObtenidas.join(', ')}</span>.
          </div>
        `;
          //agregar x
          //Detalle del modal

          let enlacesFormateados = this.carrerasToulouse.map((carr) => {
            const carreraFiltrada = this.todasLasCarrerasObtenidas?.find(
              ({ nombre }) => nombre === carr
            );
            const linkCarrera = carreraFiltrada?.linkAsociado || '#';
            return `<a target="_blank" href="${linkCarrera}" class="botones-resultados">${carr}</a>`;
          });

          this.detalleModal = `
          <p>Elige tu carrera y empieza a construir</p>
          <p>tu propósito de vida con nosotros.</p>
          <p>Estas misiones están disponibles:</p><br/>
          <div class="contenedor-botones-resultados">
            ${enlacesFormateados.join('<br/>')}
          </div>
          <div class="whatsapp-container">
            <div>Si prefieres una atención personalizada</div>
            <a class="btn btn-whatsapp" target="_blank" href="${this.generarEnlaceDeWhatsapp()}">
              <span>Escríbenos aquí</span>
              <img src="./../../../assets/images/iconos/whatsapp-icon.png" height="25"/>
            </a>
          </div>
        `;
        }

        const isTheFirstTime = !this.userInfo.hizoTest;
        if (isTheFirstTime) {
          const dataUser: IUser = {
            hizoTest: true,
          };

          this.usuario
            .updUsuarioFireStore(this.userInfo.uuid, dataUser)
            .then((res) => {
              this.store.dispatch(
                setUser({ info: { ...this.userInfo, ...dataUser } })
              );
            });
          await this.emblue.registerEvent(EventType.test, {
            Estado: FlowState.testCompleted,
            Etapa: FlowStage.finish,
            Email: this.userInfo.correo,
          });
        }
      });
  }

  ngAfterViewInit(): void {}

  generarEnlaceDeWhatsapp() {
    return `https://api.whatsapp.com/send?phone=51975833060&text=Hola, he completado el test de Modo SuperHéroe y quiero más información sobre las carreras. Mi codigo de atención personalizada es TLS221XP043.`;
  }

  descargar() {
    const _imgGenerada: HTMLElement = this.imgGenerada.nativeElement;
    const resContenido: HTMLElement = this.resultadosContenido.nativeElement;
    const progress: HTMLElement = this.progress.nativeElement;
    let heightAux = 700;

    if (window.screen.width <= 640) {
      heightAux = 640; // height: 640, bueno para celular
    }

    progress.classList.remove('d-none');
    progress.classList.remove('animate__fadeOut');
    progress.classList.add('animate__fadeIn');
    setTimeout(() => {
      resContenido.classList.add('ocultar-div');
    }, 400);

    setTimeout(() => {
      const currentPosition = _imgGenerada.scrollTop;
      /*  _imgGenerada.style.height = 'auto'; */
      html2canvas(_imgGenerada, {
        width: 1200,
        //height: window.outerHeight + window.innerHeight,
        /*  windowHeight: window.outerHeight + window.innerHeight, */
        useCORS: true,
        imageTimeout: 0,
        /*         backgroundColor: '#0662b0',
                allowTaint: true //you can add this property */
      }).then((canvas) => {
        const e = document.createElement('a');
        (e.href = canvas
          .toDataURL('image/jpeg')
          .replace('image/jpeg', 'image/octet-stream')),
          (e.download = 'misResultados-Toulouse.jpg'),
          e.click();
      });
      /* _imgGenerada.style.height = 'auto'; */
      _imgGenerada.scrollTop = currentPosition;

      resContenido.classList.remove('ocultar-div');
    }, 3000);

    setTimeout(() => {
      progress.classList.remove('animate__fadeIn');
      progress.classList.add('animate__fadeOut');
      progress.classList.add('d-none');
    }, 3500);
  }

  salir() {
    if (this.mostrarAnuncio || this.carrerasToulouse.length === 0) {
      this.router.navigate(['/alumno/home']);
      return;
    }
    this.iconoCierre = true;
    this.mostrarBoton = false;
    this.presionoSalir = true;
    this.mostrarAnuncio = true;
  }

  clickBotonModalToulouse(event) {
    this.router.navigate(['/alumno/home']);
  }
}
