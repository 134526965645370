<div class="etapas">
    <div class="circulo-lineas"></div>
    <div class="container-fluid full-vh">
        <div class="row justify-content-between div-superior position-relative z-index-front">
            <div (click)="atras()" class="btn-back-yellow-only pointer">
                <img src="assets/images/iconos/flecha-atras.png"/>
                <span>Atrás</span>
            </div>
            <img src="assets/images/logos/{{logo}}" class="logo" />
        </div>

        <div class="align-items-center div-inferior row">
            <div class="etapa-personaje-bg">
                <img src="assets/images/etapa/personaje-etapa-1.svg"/>
            </div>
            <div class="col-md-6 col-sm-6 col-12 text-center animate__animated  animate__fadeInLeft div-etapa">
                <h1 class="title-text-white animate__animated  animate__fadeIn">{{tituloEtapa}}</h1><br/>
                <p class="detalle animate__animated  animate__fadeIn" [innerHTML]="detalleEtapa">

                </p>
                <button type="button" [disabled]="isLoading" class="border-light btn-primary-toulouse text-white py-3" (click)="crearTest()">
                     <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i>{{isLoading ? 'Espere...' : '¡Iniciar!'}}
                </button>
            </div>
        </div>
    </div>
</div>