import { ActionReducerMap } from '@ngrx/store';
import * as token from './reducers/auth.reducer';
import * as ui from './reducers/ui.reducer';
import * as auth from './reducers/user.reducer';

export interface AppState {
    ui: ui.State;
    user: auth.State;
    token: token.State;
}

export const appReducers: ActionReducerMap<AppState> = {
    ui: ui.uiReducer,
    user: auth.authReducer,
    token: token.tokenReducer
};
