<div class="admin_form admin carreras">
    <div class="circulo-lineas"></div>
    <div class="circulo"></div>

    <div class="container-fluid full-vh animate__animated animate__faster animate__zoomIn">
        <div class="row justify-content-between div-superior position-relative">
            <a [routerLink]="['/admin']" class="btn-back-yellow-only">
                <img src="assets/images/iconos/flecha-atras.png" />
                <span>Atrás</span>
            </a>
            <img src="assets/images/logos/{{ logo }}" class="logo" />
        </div>

        <div class="row mx-0 align-items-center">
            <div class="col-12">
                <h1 class="title-text-white text-center">Maestro de carreras</h1>
            </div>
            <div class="col-md-10 col-12 mt-4 mb-md-4 mb-0 form-inline">
                <div class="row w-100 pl-3">
                    <div class="form-group bmd-form-group mr-2">
                        <label for="TextBuscar" class="bmd-label-static text-white">Buscar:</label>
                        <input autoComplete="off" type="text" class="form-control" id="TextBuscar" [(ngModel)]="buscarValue" (keyup.enter)="buscarCarreras()" />
                    </div>
                    <div class="form-group bmd-form-group mr-2">
                        <button type="submit" class="btn btn-primary btn-raised" (click)="buscarCarreras()" data-toggle="tooltip" data-placement="top" title="Buscar">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12 form-inline mt-md-4 mb-0 mt-0">
                <div class="form-group bmd-form-group w-100 pt-0">
                    <button class="btn btn-raised btn-tls-add text-white w-100" data-toggle="modal" data-target="#agregarCarrera" (click)="nuevaCarreraPopUp()">
                        <i class="fas fa-plus-circle mr-2"></i> Nueva carrera
                    </button>
                </div>
            </div>
            <div class="col-12 mb-5">
                <div class="descargas-tabla">
                    <div class="text-center" *ngIf="procesando"><i class="fas fa-spinner fa-spin mr-1"></i> Procesando...
                    </div>
                    <table [hidden]="procesando" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" id="datax">
                        <thead>
                            <tr>
                                <th>Acciones</th>
                                <th>N°</th>
                                <th>Código</th>
                                <th id="nombreCol">Nombre</th>
                                <th>Carrera Activa</th>
                                <th>¿Es de Toulouse?</th>
                                <th>¿Tiene matriz Asociada?</th>
                                <th>¿Link Asociado?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let carrera of carreras;let i = index">
                                <td class="d-flex">
                                    <div class="tls-lupa pointer w-25 mr-2" data-toggle="modal" data-target="#agregarCarrera" (click)="obtenerCarreraPopUp(carrera)"  title="Editar carrera">
                                        <i class="fas fa-edit"></i>
                                    </div>
                                    <div class="tls-lupa pointer w-25 mr-2" data-toggle="modal" (click)="eliminarCarrera(carrera)"  title="Eliminar Carreras y Matriz Asociada">
                                        <i class="fas fa-trash"></i>
                                    </div>
                                    <div class="tls-lupa pointer w-25" data-toggle="modal" data-target="#agregarMatriz" (click)="obtenerCarreraPopUp(carrera)"  title="Asociar Matriz de Carreras">
                                        <i class="fas fa-code-branch"></i>
                                    </div>
                                </td>
                                <td>{{ i+1 }}</td>
                                <td>{{ carrera.codigo }}</td>
                                <td>{{ carrera.nombre }}</td>
                                <td>{{ carrera.activo?'Sí':'No' }}</td>
                                <td>{{ carrera.carrToulouse?'Sí':'No' }}</td>
                                <td>{{ carrera.matrizAsociada?'Sí':'No' }}</td>
                                <td>{{ carrera.linkAsociado?'Sí':'No' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade"  id="agregarCarrera" tabindex="-1" role="dialog" aria-labelledby="agregarCarrera" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="agregarCarrera">{{nuevaCarrera?'Nueva':'Editar'}} carrera</h3>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <form class="col-12" [formGroup]="carreraForm" (ngSubmit)="agregarCarrera()">
                            <div class="mi-form-opacity">
                                <div class="form-group bmd-form-group">
                                    <label for="textCodigo">Código:</label>
                                    <input autoComplete="off" type="text" class="form-control" id="textCodigo" formControlName="codigo" />
                                    <small class="text-warning" *ngIf="codigoNoValido && nuevaCarrera">El código
                                        es obligatorio.</small>
                                </div>
                                <div class="form-group bmd-form-group">
                                    <label for="textNombre">Nombre:</label>
                                    <input autoComplete="off" type="text" class="form-control" id="textNombre" formControlName="nombre" />

                                    <small class="text-warning" *ngIf="nombreNoValido">El nombre es obligatorio.</small>
                                </div>


<!--                                 <div class="form-group">
                                    <label class="label-format">¿Es una carrera de Toulouse?</label>
                                    <div class="col-12 d-flex flex-row px-0">
                                        <select class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelEsCarreraToulouse">
                                            <option  value=null>Seleccionar</option>
                                            <option *ngFor="let casco of cascos" [ngValue]="casco">{{casco.titulo}}</option>
                                        </select>
                                        <button (click)="agregarElemento('CASCO')" class="btn btn-outline-primary">Agregar</button>
                                    </div>
                                </div> -->

                                <div class="bmd-form-group">
                                    <div class="switch">
                                        <div class="form-check mb-2 d-flex align-items-center">
                                            <input id="carrToulouseCheck" class="form-check-input mt-0" type="checkbox" formControlName="carrToulouse">
                                            <label class="form-check-label pl-0" for="carrToulouseCheck">
                                                ¿Es una carrera de Toulouse?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group bmd-form-group">
                                    <label for="linkAsociado">Link asociado:</label>
                                    <input autoComplete="off" type="text" class="form-control" id="linkAsociado" formControlName="linkAsociado" />
                                </div>

                                <div class="bmd-form-group">
                                    <div class="switch">

                                        <div class="form-check mb-2 d-flex align-items-center">
                                            <input id="activoChkEdit" class="form-check-input mt-0" type="checkbox" formControlName="activo" checked>
                                            <label class="form-check-label pl-0" for="activoChkEdit">
                                                Carrera Activa
                                            </label>
                                        </div>
                                        <br>
                                        <small class="text-warning" *ngIf="activoNoValido">Marque la casilla si quiere
                                            indicar
                                            que
                                            la carrera esté habilitada.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" [disabled]="guardando" >Cancelar</button>
                                <button type="submit" class="btn btn-primary" [disabled]="guardando">
                                    <i class="fas fa-spinner fa-spin mr-1" *ngIf="guardando"></i>
                                    {{nuevaCarrera?'Guardar':'Actualizar'}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>


      <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="agregarMatriz" tabindex="-1" role="dialog" aria-labelledby="agregarMatriz"  aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Asociar Matriz de Carreras</h5>
                </div>
                <div class="modal-body">
                    <label style="font-size: 1.3rem;">Carrera: {{this.carreraForm.get('nombre').value}}</label>
                    <hr/>
                    <div >
                        <div class="form-group">
                            <label class="label-format">Escoge 2 Poderes:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelpoderElegido">
                                    <option value=null>Seleccionar</option>
                                    <option *ngFor="let poder of poderes" [ngValue]="poder" >{{poder.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('PODER')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let poder of poderesElegido; let i = index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{poder.codigo}}</td>
                                <td>{{poder.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('PODER',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <hr/>

<!--                         <div class="form-group">
                            <label class="label-format">Escoge 2 ODS:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select  class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelCDOElegido">
                                    <option  value=null>Seleccionar</option>
                                    <option *ngFor="let CDO of CDOs" [ngValue]="CDO" >{{CDO.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('CDO')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let CDO of CDOElegidos; let i = index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{CDO.codigo}}</td>
                                <td>{{CDO.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('CDO',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table> -->

                        <div class="form-group">
                            <label class="label-format">Escoge 2 Calzados:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select  class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelCalzadoElegido">
                                    <option  value=null>Seleccionar</option>
                                    <option *ngFor="let calzado of calzados" [ngValue]="calzado" >{{calzado.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('CALZADO')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let calzado of CalzadosElegidos; let i = index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{calzado.codigo}}</td>
                                <td>{{calzado.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('CALZADO',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <hr/>

                        <div class="form-group">
                            <label class="label-format">Escoge 3 Trajes:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select  class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelTrajeElegido">
                                    <option  value=null>Seleccionar</option>
                                    <option *ngFor="let traje of trajes" [ngValue]="traje" >{{traje.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('TRAJE')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let traje of TrajesElegidos; let i = index">
                                <th scope="row">{{i+1}}</th>
                                <td>traje-{{traje.codigo.split('-')[1]}}</td>
                                <td>{{traje.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('TRAJE',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <hr/>

                        <div class="form-group">
                            <label class="label-format">Escoge 7 hábilidades:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelhabilidadElegida">
                                    <option   value=null>Seleccionar</option>
                                    <option *ngFor="let hab of habilidades" [ngValue]="hab" >{{hab.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('HABILIDAD')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let hab of habilidadesElegidos; let i = index">
                                <th scope="row">{{i+1}}</th>
                                <td>{{hab.codigo}}</td>
                                <td>{{hab.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('HABILIDAD',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <hr/>

                        <div class="form-group">
                            <label class="label-format">Escoge 3 cascos:</label>
                            <div class="col-12 d-flex flex-row px-0">
                                <select class="form-control control-reset-b4 mr-5 " [(ngModel)]="ngModelcascoElegido">
                                    <option  value=null>Seleccionar</option>
                                    <option *ngFor="let casco of cascos" [ngValue]="casco">{{casco.titulo}}</option>
                                </select>
                                <button (click)="agregarElemento('CASCO')" class="btn btn-outline-primary">Agregar</button>
                            </div>
                        </div>
                        <table class="table bg-light">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let casco of cascosElegidos; let i = index">
                                <th scope="row">{{i+1}}</th>

                                <td>{{casco.codigo}}</td>
                                <td>{{casco.titulo}}</td>
                                <td>
                                    <button type="button" (click)="removerElemento('CASCO',i)" class="mt-0"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" [disabled]="guardando" >Cancelar</button>
                    <button type="submit" class="btn btn-primary" (click)="registrarMatriz()" [disabled]="guardando">
                        <i class="fas fa-spinner fa-spin mr-1" *ngIf="guardando"></i>Guardar Cambios
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>