<div class="problemas">
  <div class="circulo-lineas"></div>
  <div class="container-fluid full-vh px-0">
    <app-stage-header
      (goBack)="atras()"
      (openHelper)="mostrarAyuda()"
      [entrenadora]="entrenadora"
      [tituloEtapa]="tituloEtapa"
    ></app-stage-header>

    <div class="row mx-0 align-items-end div-inferior position-relative">
      <app-boton-ayuda
        (abrirAyuda)="mostrarAyuda()"
        class="col-12 text-right otrosDevices div-boton-ayuda mt-md-2"
      ></app-boton-ayuda>

      <div
        class="avatar-container col-12 col-md-12 d-flex div-contenido flex-wrap px-3"
      >
        <div class="col-md-8 flex-width-100 px-0 pt-4">
          <div class="pl-lg-4">
            <h1 class="title-text-white">{{ maximoElementosHTML }}</h1>
          </div>
          <div class="row container-ods">
            <div
              *ngFor="let problema of problemas"
              class="d-flex ods-item"
              (click)="elementElegido(refProblema, problema)"
              #refProblema
            >
              <!-- <div>{{problema.titulo}}</div> -->
              <div [innerHTML]="problema.titulo"></div>
            </div>
          </div>
        </div>
        <div class="col-md-3 d-flex personaje-container row flex-width-100">
          <div class="position-relative avatar">
            <img *ngIf="personaje" class="personaje" [src]="personaje" />
            <img
              *ngIf="cinturon"
              class="cinturon"
              src="assets/images/poderes/{{ cinturon }}"
            />
            <img
              *ngIf="tipoCuerpoElegido"
              class="tipoCuerpo det-tipo {{ tipoCuerpoElegido?.codigo }}-puesto"
              src="assets/images/tipos/{{ tipoCuerpoElegido?.foto }}"
            />
            <img
              *ngIf="pecheraElegida"
              class="pechera {{ pecheraElegida.codigo }}-puesto"
              src="assets/images/pecheras/{{ pecheraElegida?.foto }}"
            />
            <img
              *ngIf="calzadoElegido"
              class="calzado {{ calzadoElegido.codigo }}-puesto"
              src="assets/images/calzados/{{ calzadoElegido?.foto }}"
            />
            <img
              *ngIf="cascoElegido"
              class="miCasco {{ cascoElegido.codigo }}-puesto"
              src="assets/images/cascos/{{ cascoElegido?.foto }}"
            />
          </div>
          <div class="col-12 text-center">
            <button
              [disabled]="isLoading"
              class="btn-primary-toulouse"
              (click)="siguiente()"
            >
              <i class="fas fa-spinner fa-spin mr-1" *ngIf="isLoading"></i
              >{{ isLoading ? "Espere..." : "Siguiente" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-stage-footer
      (goBack)="atras()"
      [percentage]="80"
      [totalBars]="8"
    ></app-stage-footer>
  </div>
</div>

<app-modal-mensaje
  (cerrarModal)="cerrarModalPadre($event)"
  [mostrarModal]="mostrarModal"
  [titulo]="tituloModal"
  [detalle]="detalleModal"
></app-modal-mensaje>
