<div class="auth_form registro">
  <div class="circulo-lineas"></div>
  <div class="circulo"></div>

  <div
    class="container-fluid full-vh animate__animated animate__faster animate__zoomIn"
  >
    <div class="row justify-content-between div-superior position-relative">
      <a [routerLink]="['/login']" class="btn-back-yellow-only">
        <img src="assets/images/iconos/flecha-atras.png" />
        <span>Atrás</span>
      </a>
      <img src="assets/images/logos/{{ logo }}" class="logo" />
    </div>

    <div class="row align-items-center div-inferior">
      <div class="col-md-4 col-12 mb-md-3">
        <h1 class="title-text-white text-center">Registro</h1>
      </div>
      <div class="col-md-8 col-12">
        <form
          [formGroup]="registroForm"
          [ngClass]="{ class: true }"
          (ngSubmit)="registrarse()"
          class="col-md-9"
        >
          <div class="mi-form-opacity">
            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('correo').value != ''"
              #formCorreo
            >
              <!-- manually specified -->
              <label for="correo" class="bmd-label-floating">Correo:</label>
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="correo"
                formControlName="correo"
                (focus)="addActivo(formCorreo)"
                (blur)="remActivo(formCorreo)"
              />
              <!-- <small *ngIf="correoNoValido">El correo es inválido.</small> -->
              <control-messages
                [control]="registroForm.get('correo')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('celular').value != ''"
              #formCelular
            >
              <!-- manually specified -->
              <label for="celular" class="bmd-label-floating">Celular:</label>
              <input
                maxlength="9"
                OnlyNumber="true"
                autoComplete="off"
                type="text"
                class="form-control"
                id="celular"
                formControlName="celular"
                (focus)="addActivo(formCelular)"
                (blur)="remActivo(formCelular)"
              />
              <control-messages [control]="registroForm.get('celular')">
              </control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('nombres').value != ''"
              #formNombres
            >
              <!-- manually specified -->
              <label for="nombres" class="bmd-label-floating">Nombres:</label>
              <input
                [disabled]="true"
                autoComplete="off"
                type="text"
                class="form-control"
                id="nombres"
                formControlName="nombres"
                (focus)="addActivo(formNombres)"
                (blur)="remActivo(formNombres)"
              />
              <!-- <small *ngIf="nombresNoValido">Nombres es obligatorio</small> -->
              <control-messages
                [control]="registroForm.get('nombres')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('apellidoPaterno').value != ''
              "
              #formapellidoPaterno
            >
              <!-- manually specified -->
              <label for="apellidoPaterno" class="bmd-label-floating"
                >Apellido Paterno:</label
              >
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="apellidoPaterno"
                formControlName="apellidoPaterno"
                (focus)="addActivo(formapellidoPaterno)"
                (blur)="remActivo(formapellidoPaterno)"
              />
              <!--  <small *ngIf="apellidoPaternoNoValido">Apellido Paterno es obligatorio</small> -->
              <control-messages
                [control]="registroForm.get('apellidoPaterno')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('apellidoMaterno').value != ''
              "
              #formapellidoMaterno
            >
              <!-- manually specified -->
              <label for="apellidoMaterno" class="bmd-label-floating"
                >Apellido Materno:</label
              >
              <input
                autoComplete="off"
                type="text"
                class="form-control"
                id="apellidoMaterno"
                formControlName="apellidoMaterno"
                (focus)="addActivo(formapellidoMaterno)"
                (blur)="remActivo(formapellidoMaterno)"
              />
              <!-- <small *ngIf="apellidoMaternoNoValido">Apellido Materno es obligatorio</small> -->
              <control-messages
                [control]="registroForm.get('apellidoMaterno')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="this.registroForm.get('clave').value != ''"
              #formClave
            >
              <!-- manually specified -->
              <label for="clave" class="bmd-label-floating">Contraseña:</label>
              <input
                autoComplete="off"
                type="password"
                class="form-control"
                id="clave"
                formControlName="clave"
              />
              <!-- <small *ngIf="claveNoValida">Este campo es obligatorio.</small> -->
              <control-messages
                [control]="registroForm.get('clave')"
              ></control-messages>
            </div>

            <div
              class="form-group bmd-form-group"
              [class.is-focused]="
                this.registroForm.get('confirmarClave').value != ''
              "
              #confirmarClave
            >
              <!-- manually specified -->
              <label for="confirmarClave" class="bmd-label-floating"
                >Confirmar Contraseña:</label
              >
              <input
                autoComplete="off"
                type="password"
                class="form-control"
                id="confirmarClave"
                formControlName="confirmarClave"
              />
              <small *ngIf="claveConfirmNoValida"
                >La clave ingresada no coincide o no es válida.</small
              >
            </div>
          </div>

          <div class="text-center">
            <button
              [disabled]="isLoading"
              type="submit"
              class="border-light btn btn-outline-secondary text-white px-lg-5 py-lg-3"
            >
              <div *ngIf="!isLoading">Registrarse</div>
              <div *ngIf="isLoading">
                <i class="fas fa-spinner fa-spin"></i> Por favor Espere...
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!--TERMINA FORM REGISTRO HTML-->
  </div>
</div>
