import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { EmblueData, EventType } from '../interfaces/Emblue';

@Injectable({
  providedIn: 'root',
})
export class EmblueService {
  emblueApi: string = 'https://track.embluemail.com/contacts/event';
  constructor(private readonly http: HttpClient) {}

  async sendData(payload: any) {
    const headers = {
      headers: new HttpHeaders({
        authorization: `Basic ${environment.emblue}`,
        'Content-Type': 'application/json',
      }),
    };

    try {
      const response = await this.http
        .post(this.emblueApi, payload, headers)
        .toPromise();
      return response;
    } catch (error) {}
  }

  async registerEvent(type: EventType, data: EmblueData) {
    const { Email, ...attributes } = data;
    const eventName = type === EventType.register ? 'msh_registro' : 'msh_test';
    const payload = {
      eventName,
      ...(Email && { email: Email }),
      attributes,
    };

    return await this.sendData(payload);
  }
}
