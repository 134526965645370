import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appNgDropFilesAux]'
})
export class NgDropFilesAuxDirective {

  @Output() mouseSobreAux: EventEmitter<boolean> = new EventEmitter();

  constructor() { }
  @HostListener('dragover', ['$event'])
  public onDragEnter(event: any) {
    this.mouseSobreAux.emit(true);
    event.preventDefault();
    event.stopPropagation();
  }
}
