import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginRegistroGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuth().pipe(
      switchMap(async (authState) => {
        if (authState) {//si esta logeado o authstate retornará false lo que significa que si esta logeado y no deberia mostrarse el login
          const token = await authState.getIdTokenResult();
          if (token.claims['cargo'] === 'ADMINISTRADOR') {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['/alumno/home']);
          }
          return false;
        } else { //si no lo esta que devuelva true lo que hará que se vea el componente login
          return true;
        }
      })
    );
  }

}
