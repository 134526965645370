import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { DataTablesModule } from 'angular-datatables';
import { environment } from 'src/environments/environment.prod';
import { CarrerasMatrizComponent } from './admin/carreras-matriz/carreras-matriz.component';
import { CarrerasComponent } from './admin/carreras/carreras.component';
import { DescargasComponent } from './admin/descargas/descargas.component';
import { MenuComponent } from './admin/menu/menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgDropFilesAuxDirective } from './directives/ng-drop-files-aux.directive';
import { NgDropFilesDirective } from './directives/ng-drop-files.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { BarraProgresoEtapasComponent } from './shared/barra-progreso-etapas/barra-progreso-etapas.component';
import { BotonAyudaComponent } from './shared/boton-ayuda/boton-ayuda.component';
import { ControlMessagesComponent } from './shared/control-messages/control-messages.component';
import { EntrenadoraComponent } from './shared/entrenadora/entrenadora.component';
import { ModalPasswordComponent } from './shared/modal-password/modal-password.component';
import { ModalComponent } from './shared/modal/modal.component';
import { appReducers } from './store/app.store';
import { CalzateComponent } from './usuario/calzate/calzate.component';
import { CascoComponent } from './usuario/casco/casco.component';
import { HomeComponent } from './usuario/home/home.component';
import { InfoEtapasComponent } from './usuario/info-etapas/info-etapas.component';
import { LoginComponent } from './usuario/login/login.component';
import { MiCuerpoComponent } from './usuario/mi-cuerpo/mi-cuerpo.component';
import { MisDatosComponent } from './usuario/mis-datos/mis-datos.component';
import { MochilaComponent } from './usuario/mochila/mochila.component';
import { NombreHeroeComponent } from './usuario/nombre-heroe/nombre-heroe.component';
import { PoderComponent } from './usuario/poder/poder.component';
import { ProblemasComponent } from './usuario/problemas/problemas.component';
import { PropositoComponent } from './usuario/proposito/proposito.component';
import { RegistroComponent } from './usuario/registro/registro.component';
import { ResultadoComponent } from './usuario/resultado/resultado.component';
import { TipoCuerpoComponent } from './usuario/tipo-cuerpo/tipo-cuerpo.component';
import { VisteteComponent } from './usuario/vistete/vistete.component';
import { AlumnosSinCarreraComponent } from './admin/alumnos-sin-carrera/alumnos-sin-carrera.component';
import { StageHeaderComponent } from './shared/stage-header/stage-header.component';
import { StageFooterComponent } from './shared/stage-footer/stage-footer.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistroComponent,
    MenuComponent,
    CarrerasComponent,
    DescargasComponent,
    CarrerasMatrizComponent,
    BarraProgresoEtapasComponent,
    BotonAyudaComponent,
    ControlMessagesComponent,
    EntrenadoraComponent,
    ModalComponent,
    ModalPasswordComponent,
    NgDropFilesAuxDirective,
    CalzateComponent,
    CascoComponent,
    HomeComponent,
    InfoEtapasComponent,
    MiCuerpoComponent,
    MisDatosComponent,
    MochilaComponent,
    NombreHeroeComponent,
    PoderComponent,
    ProblemasComponent,
    PropositoComponent,
    ResultadoComponent,
    TipoCuerpoComponent,
    VisteteComponent,
    NgDropFilesDirective,
    OnlyNumbersDirective,
    AlumnosSinCarreraComponent,
    StageHeaderComponent,
    StageFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    StoreModule.forRoot(appReducers),
    /*StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),*/
  ],
  providers: [ScreenTrackingService, UserTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}
