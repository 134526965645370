<div class="footer-flow-container row justify-content-between px-4 mx-0">
  <div (click)="handleGoBack($event)" class="btn-back-yellow pointer">
    <img src="assets/images/iconos/flecha-atras.png" />
    <span>Atrás</span>
  </div>
  <app-barra-progreso-etapas
    [percentage]="percentage"
    [totalBars]="totalBars"
    class="d-flex align-items-center progress-parent"
  >
  </app-barra-progreso-etapas>
</div>
