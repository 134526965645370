import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuth().pipe(
      switchMap(async (authState) => {
        const token = await authState.getIdTokenResult();
        //Si el cargo es  administrador entonces mostrará el componente
        if (token.claims['cargo'] === 'ADMINISTRADOR') {
          this.router.navigate(['/admin']);
        } else {
          return true;
        }
      })
    );
  }
}
