import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/global/GlobalConstants';
import { EventType, FlowState } from 'src/app/interfaces/Emblue';
import { AnalyticParams, IAuth } from 'src/app/interfaces/IAuth';
import { AlertsService } from 'src/app/services/alerts.service';
import { EmblueService } from 'src/app/services/emblue.service';
import { ValidationService } from 'src/app/services/validation.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { Storage, StorageKey } from 'src/app/utils/storage';
import { AuthService } from '../../services/auth.service';
import { AppState } from './../../store/app.store';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
  logo = GlobalConstants.LOGO_TOULOUSE_AMARILLO_BLANCO;
  isLoading: boolean;
  registroForm: FormGroup;
  obser: Observable<any>;
  lastEmail: string;
  lastCellphone: string;
  constructor(
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private alerts: AlertsService,
    private store: Store<AppState>,
    private emblue: EmblueService
  ) {
    this.registroForm = formBuilder.group(
      {
        nombres: ['', [Validators.required, Validators.minLength(2)]],
        apellidoPaterno: ['', [Validators.required, Validators.minLength(2)]],
        apellidoMaterno: ['', [Validators.required, Validators.minLength(2)]],
        celular: [
          '',
          {
            validators: [Validators.required, Validators.minLength(9)],
            updateOn: 'blur',
          },
        ],
        correo: [
          '',
          {
            validators: [Validators.required, ValidationService.emailValidator],
            updateOn: 'blur',
          },
        ],
        clave: ['', [Validators.required, Validators.minLength(6)]],
        confirmarClave: ['', Validators.required],
      },
      {
        validators: this.passwordsIguales('clave', 'confirmarClave'),
      }
    );
  }

  ngOnInit(): void {
    this.store.select('ui').subscribe((res) => {
      this.isLoading = res.isLoading;
    });

    this.registroForm.valueChanges.subscribe(async () => {
      const email = this.registroForm.controls['correo'];
      const celular = this.registroForm.controls['celular'];

      if (email.valid && email.value !== this.lastEmail) {
        this.store.dispatch(isLoading());
        this.lastEmail = email.value;
        await this.emblue.registerEvent(EventType.register, {
          Email: email.value,
          Estado: FlowState.register,
          ...(celular.valid && { Celular: celular.value }),
        });
        this.store.dispatch(stopLoading());
      }
      if (celular.valid && celular.value !== this.lastCellphone) {
        this.lastCellphone = celular.value;
        this.store.dispatch(isLoading());
        await this.emblue.registerEvent(EventType.register, {
          Celular: celular.value,
          Estado: FlowState.register,
          ...(email.valid && { Email: email.value }),
        });
        this.store.dispatch(stopLoading());
      }
    });
  }

  registrarse() {
    if (!this.registroForm.valid) {
      this.alerts.modalAlert(
        'Campos Incompletos',
        'Debe completar todos los campos para poder continuar'
      );
      return;
    }

    const analyticsData = Storage.load<AnalyticParams>(
      StorageKey.analyticsParams
    );
    Storage.remove(StorageKey.analyticsParams);

    const registroAuth: IAuth = {
      ...this.registroForm.value,
      ...(analyticsData && { analyticsParams: { ...analyticsData } }),
    };
    this.store.dispatch(isLoading());
    this.registroForm.disable();
    this.auth
      .crearUsuario(registroAuth)
      .then(async (res) => {
        const form = this.registroForm.value;
        await this.emblue.registerEvent(EventType.register, {
          Email: form.correo, //email.value,
          Etapa: '',
          Nombre: form.nombres,
          Apellido_Paterno: form.apellidoPaterno,
          Apellido_Materno: form.apellidoMaterno,
          Celular: form.celular,
          Estado: FlowState.registerCompleted,
        });
        this.store.dispatch(stopLoading());
        this.alerts
          .modalInfo('Registro Correcto', 'Presione en aceptar para continuar.')
          .then((result) => {
            if (result.value) {
              this.router.navigate(['alumno/home']);
            }
          });
      })
      .catch((err) => {
        let mensajeError = '';
        if (err.code === 'auth/email-already-in-use') {
          mensajeError =
            'El correo ingresado ya se encuentra en uso, intente con otro';
        }
        this.store.dispatch(stopLoading());
        this.registroForm.enable();
        this.alerts.modalError(null, `${mensajeError}`);
      });
  }

  //Validaciones y metodos de acceso en el DOM
  passwordsIguales(pass1Name: string, pass2Name: string) {
    return (formGroup: FormGroup) => {
      const pass1Control = formGroup.controls[pass1Name];
      const pass2Control = formGroup.controls[pass2Name];
      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEsIgual: true });
      }
    };
  }
  addActivo(wrap: HTMLElement) {
    wrap.classList.add('is-focused');
  }
  remActivo(wrap: HTMLElement) {
    if (this.registroForm.get(wrap.children[1].id).value) {
      return;
    }
    wrap.classList.remove('is-focused');
  }

  get claveConfirmNoValida() {
    return (
      this.registroForm.get('confirmarClave').invalid &&
      this.registroForm.get('confirmarClave').touched
    );
  }
}
