<div class="admin_form admin">
    <div class="circulo-lineas"></div>
    <div class="circulo"></div>

    <div class="container-fluid full-vh animate__animated animate__faster animate__zoomIn">
        <div class="row justify-content-between div-superior position-relative">
            <a [routerLink]="['/admin']" class="btn-back-yellow-only">
                <img src="assets/images/iconos/flecha-atras.png" />
                <span>Atrás</span>
            </a>
            <img src="assets/images/logos/{{ logo }}" class="logo" />
        </div>
        <div class="row mx-0 align-items-center">
            <div class="col-12">
                <h1 class="title-text-white text-center">Alumnos sin carreras</h1>
            </div>
            <div class="col-md-2 col-12 text-right mb-md-0 mb-4">
                <div class="row">
                    <div class="col-12 form-group bmd-form-group">
                        <button type="button" class="btn btn-success btn-raised w-100" (click)="listarCarreras()" [disabled]="cargandoUI">
                            <i class="fas fa-spinner fa-spin mr-1" *ngIf="cargandoUI"></i>Reasignar Carreras
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-5">
                <div class="descargas-tabla">
                    <div class="text-center" *ngIf="procesando"><i class="fas fa-spinner fa-spin mr-1"></i> Procesando...
                    </div>
                    <table [hidden]="procesando" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                        <thead>
                            <tr>
                                <!-- <th>Ver</th> -->
                                <th>N°</th>
                                <th>Nombres</th>
                                <th>Apellido paterno</th>
                                <th>Apellido materno</th>
                                <th>Correo</th>
                                <th>Colegio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let alumno of alumnos;let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ alumno.nombres }}</td>
                                <td>{{ alumno.apellidoPaterno }}</td>
                                <td>{{ alumno.apellidoMaterno }}</td>
                                <td>{{ alumno.correo }}</td>
                                <td>{{ alumno.colegio }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>