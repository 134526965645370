export interface EmblueData {
  Email?: string;
  Estado?: FlowState | string;
  Etapa?: FlowStage | string;
  Nombre?: string;
  Apellido_Paterno?: string;
  Apellido_Materno?: string;
  Celular?: string;
}

export enum FlowState {
  register = 'REGISTRO',
  registerCompleted = 'REGISTRO_COMPLETO',
  test = 'TEST',
  testCompleted = 'TEST_COMPLETO',
}

export enum FlowStage {
  init = 'INICIO',
  lookingForHero = 'DESCUBRE_SUPERHEROE',
  knowMe = 'CONOCETE_MEJOR',
  inspectTalent = 'ESCANEANDO_TALENTO',
  mision = 'MISION_PROPOSITO',
  finish = 'RESULTADO',
}

export enum EventType {
  register = 'msh_registro',
  test = 'msh_test',
}
