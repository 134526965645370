import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GENEROS, GlobalConstants } from 'src/app/global/GlobalConstants';
import { IMensajesEntrenadora } from 'src/app/interfaces/IMensajesEntrenadora';
import { IUser } from 'src/app/interfaces/IUser';
import { AlertsService } from 'src/app/services/alerts.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { UserService } from 'src/app/services/user.service';
import { isLoading, stopLoading } from 'src/app/store/actions/ui.action';
import { setUser } from 'src/app/store/actions/user.action';
import { AppState } from 'src/app/store/app.store';

@Component({
  selector: 'app-mi-cuerpo',
  templateUrl: './mi-cuerpo.component.html',
  styleUrls: ['./mi-cuerpo.component.scss']
})
export class MiCuerpoComponent implements OnInit {
  //Constantes globales
  tituloEtapa = GlobalConstants.TITULO_ETAPAS;
  iconoAyuda = GlobalConstants.ICONO_AYUDA;
  generos = GENEROS;

  //variables del store
  isLoading: boolean;
  userInfo: IUser;

  //Variables de apoyo
  generoElegido: string = '';
  entrenadora: IMensajesEntrenadora;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private usuario: UserService,
    private alerts: AlertsService,
    private etapa: EtapaService
  ) { }

  ngOnInit(): void {
    this.store.select('user').pipe(take(2)).subscribe(res => {
      this.userInfo = res.info;

      //Obtenemos el genero seteado
      this.generoElegido = this.userInfo?.genero;
    });

    this.store.select('ui').pipe(take(3)).subscribe(res => {
      this.isLoading = res.isLoading;
    });

    //Seteamos los valores de la entrenadora
    this.entrenadora = this.etapa.cargarMensajesEntrenadora(this.router.url);
  }

  elegirPersonaje(genero) {
    this.generoElegido = genero;
  }

  siguiente() {
    if (this.generoElegido.length === 0) {
      this.alerts.modalInfo(null, 'Para continuar primero debes elegir una opción :)');
    } else {
      this.store.dispatch(isLoading());

      //validamos si el valor que esta en nustro store es diferente al que hemos elegido
      //Entonces solo pasará a la siguiente pantalla para no actualizar en vano
      if (this.userInfo.genero === this.generoElegido) {
        this.store.dispatch(stopLoading());
        this.router.navigate(['/alumno/poder']);
      } else {

        const data: IUser = {
          genero: this.generoElegido,
          flagTrackingTest: {
            ...this.userInfo.flagTrackingTest,
            lastPath: this.router.url
          },
          fechFinTest: '',
          misCarreras: [],
          miSuperHeroe: {
            CDO: '',
            calzado: '',
            casco: '',
            don: '',
            habilidades: '',
            herramientas: '',
            nick: '',
            poder: '',
            proposito: '',
            tipoCuerpo: '',
            vestimenta: '',
          }
        };

        this.usuario.updUsuarioFireStore(this.userInfo.uuid, data).then(res => {
          this.store.dispatch(stopLoading());
          this.store.dispatch(setUser({
            info: {
              ...this.userInfo,
              ...data
            }
          }));
          this.router.navigate(['/alumno/poder']);
        }).catch(err => {
          this.store.dispatch(stopLoading());
          this.alerts.modalInfo(null, `Sucedio un error: ${err}`);
        });
      }
    }
  }

  atras() {
    const etapaInfo = this.etapa.obtenerCodigoEtapa(this.router.url);
    this.router.navigate(['/alumno/etapa', etapaInfo.codigo]);
  }



}
